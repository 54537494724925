.Actions {
  padding: 0 3.565rem;
  padding-top: 3.1rem;
}

.Actions-section {
  padding-bottom: 4.65rem;
}

.Actions-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.Actions input {
  font-size: 1.5rem;
  padding: 0;
  margin-left: 0.8rem;
}

.Actions .PositionsList .Exchange-list-action {
  display: none;
}

.Actions .PositionsList .App-button-option {
  display: none;
}
.Actions .all-trades.Exchange-list{
  padding: auto 3rem;
}

.Actions .App-card-option {
  background: #D9D9D9;
  color: #222124;
}
.Actions table.Exchange-list tr:hover{
  background: #454545;
  box-shadow: none;
}

.Actions .token-table {
  margin-left: 0;
  width: 100%;
}
.Actions .prev-next-container{
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}
.Actions .Exchange-list-item td,
.Actions .Exchange-list-header th{
  text-align: end;
}
.Actions .Exchange-list-item td:nth-child(1),
.Actions .Exchange-list-header th:nth-child(1){
  text-align: start;
}

.Actions .token-table th:nth-child(5){
  width: 25rem;
}
.Actions .token-table th:nth-child(3),
.Actions .token-table th:nth-child(4){
  width: 20rem;
}

.Actions .PositionsList .Exchange-list-item .position-list-collateral,
.Actions .PositionsList .Exchange-list-item .Exchange-list-info-label,
.Actions .PositionsList .Exchange-list-item .pnl{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.Actions .PositionsList .Exchange-list-item td{
  text-align: end;
}
.Actions .PositionsList .edit-icon{
  display: none;
}

.Exchange-list.App-box{
  background: #222124;
  border: 1px solid #272A2F;
}

@media (max-width: 1300px) {
  .Actions .Exchange-list.all-trades {
    display: block;
    overflow-x: auto;
  }
}

.Actions .Exchange-list th{
  text-align: left;
}

.Actions .PositionsList .Exchange-list .Exchange-list-item td{
  text-align: left;

}

.Actions .PositionsList .Exchange-list-info-label.pnl-row{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Actions .Exchange-list .clickable.title{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.Actions .Exchange-list.Orders tbody td{
  text-align: left;
}
.Actions .token-table th:last-child,
.Actions  table.Exchange-list.Orders tbody tr td:last-child{
  text-align: left;

}
.Actions .token-table th:nth-child(2){
  min-width: 15rem;
}
.Actions .token-table th:first-child{
  min-width: 35rem;
}
.Actions .Exchange-list.token-table th:nth-child(3),
.Actions .Exchange-list.token-table td:nth-child(3){
  max-width: 20rem;
  width: 20rem;
}

.Actions .Actions-section .Actions-title.summary-title {
  margin-bottom: 3rem;
}
.Actions .summary-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
}

.Actions .summary-row .summary-label {
  width: 16rem;
}

.Actions.liquidity .summary-row .summary-label {
  width: 18rem;
}

.Actions .summary-row .summary-label .Tooltip-handle,
.Actions .summary-row .summary-value .Tooltip-handle {
  justify-content: start;
}
