.Dashboard {
  padding-top: 4.65rem;
}

.Dashboard-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  margin-top: 0.8rem;
}

.Dashboard-token-cards {
  margin-top: 0.8rem;
}

.token-table-wrapper {
  border: 1px solid #1e2136;
  border-radius: 0.4rem;
  background: #17182c;
  padding: 0 1.5rem;
  text-align: left;
}

.token-table-wrapper .App-card-title {
  justify-content: unset;
}

.token-table-wrapper .App-card-title img {
  margin-top: 0.3rem;
  display: inline-flex;
  margin-left: 0.8rem;
}

.App-card-title-icon {
  display: flex;
  margin-left: 1.2rem;
}

.App-card-title-icon img + img {
  margin-left: 0.6rem;
}

.stats-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.stats-wrapper .App-card {
  width: 100%;
  display: flex;
}

.stats-block {
  width: 100%;
}

.stats-piechart {
  margin: -1rem;
  margin-left: 1.5rem;
}

.stats-block--flp .stats-label {
  grid-row-gap: 0;
}

.stats-label {
  font-size: var(--font-sm);
  line-height: 1.6rem;
  border-radius: 0.4rem;
  padding: 1.05rem;
  letter-spacing: 0.4px;
  text-align: left;
  z-index: 1;
  background: linear-gradient(90deg, #0b0b0f 0%, rgba(10, 13, 28, 1) 100%);
  cursor: pointer;
}

.stats-label-color {
  width: 0.4rem;
  height: 100%;
  margin-right: 1.5rem;
}

.Available-network-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.8rem;
}

.Available-network-group .Tooltip {
  display: flex;
}

.Available-network-group .Available-network {
  display: flex;
}

.Available-network-group .Available-network img {
  filter: grayscale(100%);
}

.Available-network-group .Tooltip-handle.active .Available-network img {
  filter: unset;
}

.Available-network-group .Available-network img:hover {
  filter: unset;
}

.token-table .token-symbol-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 4.65rem;
}

.Dashboard-token-cards .token-table td:nth-child(3),
.Dashboard-token-cards .token-table th:nth-child(3) {
  width: 20rem;
}
.mobile-token-card {
  display: flex;
  align-items: center;
}

.token-symbol-text {
  line-height: 1;
}

.mobile-token-card > img {
  margin-right: 0.8rem;
}

@media (max-width: 1024px) {
  .stats-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }

  .stats-wrapper .App-card {
    max-width: unset !important;
    margin: 0;
  }
}

@media (max-width: 1100px) {
  .token-table-wrapper {
    display: none;
  }
}

@media (max-width: 900px) {
  .Dashboard-cards {
    grid-template-columns: 1fr;
  }

  .Dashboard-token-cards .token-grid {
    padding-top: 0;
  }

  .Dashboard.page-layout .Tab-title-section.tokens{
    padding-top: 6rem;
  }
}

@media (max-width: 660px) {
  .stats-wrapper .App-card {
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .stats-piechart {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
}

.Dashboard  .section-title-block .Page-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.Dashboard  .section-title-block  .total-containers {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    flex-basis: 100%;
    /* align-items: b; */
    /* padding-left: 5%; */
}

.Dashboard  .section-title-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-basis: 100%;

}
.Dashboard .section-title-content .Page-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0;


}
.Dashboard .section-title-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.Dashboard .section-title-block .total {
  display: flex;
  /* margin-left: 3rem; */
  border: 1px solid #272A2F;
  background: rgba(33, 34, 35, 0.7843137255);
  border-radius: 0.75rem;
  font-weight: normal;
  flex-basis: 48%;
  justify-content: center;
  margin-top: 2rem;

}

.Dashboard .totaldiv:nth-child(2){
  justify-content: flex-end;
}
.Dashboard .totaldiv {
  /* margin-left: 1rem; */
  /* margin-right: 1rem; */
  display: flex;
  align-items: center;
  padding: 0.25rem;
  flex-basis: 45%;
}

.Dashboard  .stats-container {
  display: flex;
  justify-content: space-evenly;
}

.Dashboard .big-stats-container {
  width: 38%;
  height: 18rem;
  border: 1px solid #272A2F;
  background: #272A2Fc2;
  border-radius: 0.75rem;
  padding: 1.25rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Dashboard .small-stats-container{
  width: 62%;
  height: 18rem;
  display: flex;
  justify-content: space-between;
}

.Dashboard .small-stats-container .small-stat{
  border: 1px solid #272A2F;
  background: #222124;
  border-radius: 0.75rem;
  width: 33%;
  margin-left: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* .Dashboard .tokens-section .tokens-container{
  display: flex;
  justify-content: space-between;
}
.Dashboard .tokens-section .tokens-container .stats{
  width: 50%;
  height: 30rem;
  border: 1px solid #272A2F;
  background: rgba(33, 34, 35, 0.7843137255);
  border-radius: 0.75rem;
  padding: 1.5rem;

}
.Dashboard .tokens-section .tokens-container .stats:nth-child(1){
  margin-right: 2rem;
} */

.Dashboard .App-card-title-mark{
  display: flex;
}
.Dashboard .App-card-title-mark div{
  margin-right: 0.15rem;
}


.App-card-title-mark .App-card-title-mark-subtitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: #a9a9b0;
}

.Dashboard .flp-assets-cardcontainer {
  display: flex;
  gap: 1.3%;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.Dashboard .flp-assets-cardcontainer .App-card {
  width: 24%;
  margin-bottom: 1.5rem;
}

.Dashboard .stats-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #272A2F;
  background: #222124;
  border-radius: 0.75rem;
  align-items: center;
  padding: 0rem;
}
.Dashboard .stats-wrapper .App-card{
  background: none;
  border-radius: 0;
  border: none;
}

.Dashboard .stats-wrapper .App-card-divider{
  height: 1px;
  background: #272A2F;
  width: 98%;
}
.Dashboard .stats-wrapper .stats-block{
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 1.86rem;
  /* width: 95%; */

}

.Dashboard .stats-wrapper .App-card-row{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.Dashboard .stats-wrapper .stats-block .Tooltip.nowrap .Tooltip-popup {
  right: auto;
}
.Dashboard .small-stats-aum,
.Dashboard .small-stats-aum .each-aum-container {
  display: flex;
  justify-content: space-between;
}

.Dashboard .small-stats-aum .each-aum-container div:nth-child(1) {
  margin-right: 1.5rem;
}

.span-flex {
  display: flex;
  flex-direction: column;
}

.Dashboard .big-stats-container .overview{
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem ;
  line-height: 168.52%;
  /* or 20px */

  letter-spacing: 0.5em;

  color: #686868;
  margin: 0;
}
.Dashboard .big-stats-container .aum{
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 0.5rem 0;

}
.Dashboard .big-stats-container .aum-amount {
  /* font-style: normal; */
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.5rem;
  margin: 0;
  margin-top: 1rem;
  font-family: sans-serif;
}
.Dashboard .big-stats-container .small-stats-aum{

  /* margin-bottom: 1.5rem; */
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  /* line-height: 24px; */

}

 .Dashboard .each-aum-container .small-stat h4{
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
 }
.Dashboard  .small-stat h4{
  margin: 0 0 1rem;

  font-weight: 300;
  /* font-size: 2rem; */
  line-height: 24px;
}
.Dashboard .big-stats-container .small-stat .amount{
  margin: 0 0 1rem;
  font-style: normal;
  font-weight: 100;
  font-size: 1.75rem;
  line-height: 2rem;
  font-family: sans-serif;
}


.Dashboard .small-stats-container .small-stat .amount .Tooltip-handle{
  margin: 0 0 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2rem;
  justify-content: flex-start;
}
.Dashboard .small-stats-container .stats-positions{
  display: flex;
  justify-content: flex-start;
  height: 50%;
}
/* .Dashboard .small-stats-container .stats-positions div:nth-child(2),
.Dashboard .volume-fees-stats-container .small-stat div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: 50%;
} */

.Dashboard .small-stats-container .small-stat:nth-child(1){
  padding: 1.5rem;
  padding-bottom: 1rem;
}
.Dashboard .small-stats-container .small-stat:nth-child(1) .amount .Tooltip-handle{
  font-size: 1.75rem;
}
.Dashboard .stat-icon-background{
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 100%;
  background-color: #444444;
}
.Dashboard .stats-positions .stat-icon-background{
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background-color: #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .Dashboard .stats-positions div:nth-child(2){
  width: 70%;
} */

.Dashboard .stat-icon-background{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5%;
}

.volume-fees-stats-container{
  display: flex;
  justify-content: space-between;
  flex-basis: 66%;
}

.volume-fees-stats-container .small-stat{
  flex-basis: 50%;
}
.Dashboard .volume-fees-stats-container  .small-stat:nth-child(1){
  padding: 2rem;
}
@media (max-width: 1100px) {

  .Dashboard .volume-fees-stats-container  .small-stat:nth-child(1){
    padding: 0;
  }
  /* .Dashboard .small-stats-container .small-stat:nth-child(3){
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      flex-wrap: wrap;

  }
  .Dashboard .small-stats-container .small-stat:nth-child(2){
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      flex-wrap: wrap;
  }

  .Dashboard .small-stats-container .small-stat:nth-child(2)  h4,
  .Dashboard .small-stats-container .small-stat:nth-child(2)  div:nth-child(1){
    flex-basis: 50%;
  }

  .Dashboard .small-stats-container .small-stat:nth-child(2) div:nth-child(2){
    flex-basis: 50%;

  } */
  .Dashboard .small-stats-container .stats-positions  .position-stat-container{
     align-items: flex-start;
     width: 100%;
  }
  .Dashboard .small-stats-container .small-stat:nth-child(1){
    flex-basis: 100%;
    width: 100%;
    display: flex;
  }
  .Dashboard .small-stats-container .volume-fees-stats-container{
     flex-basis: 100%;
     display: flex;
     width: 100%;
  }
  .Dashboard .volume-fees-stats-container {
    flex-basis: 50%;
    background: #222124;
    border-radius: 0.75rem;

    display: flex;
    flex-direction: column;
    padding: 2rem;

  }
  .Dashboard .volume-fees-stats-container .small-stat {
    border: 0;
    background: none;
    border-radius:0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0rem 0rem;
    margin-left: 0;
  }

  .Dashboard .volume-fees-stats-container .stat-icon-background{
    margin-right: 1.5rem;
  }
  /* .Dashboard .volume-fees-stats-container .small-stat:nth-child(1){
    padding: 2rem 0rem;
  }
  .Dashboard .volume-fees-stats-container .small-stat:nth-child(2) {
    flex-wrap: wr;
  }*/
  .Dashboard .volume-fees-stats-container .small-stat:nth-child(2) div:nth-child(2) h4{
    flex-wrap: wrap;
  }

  .Dashboard .volume-fees-stats-container .small-stat  .amount{
    /* flex-basis: 100%; */
    justify-content: flex-end;
    /* margin-top: 1rem; */
    /* margin-left: 1rem; */
  }

  .Dashboard .volume-fees-stats-container  .small-stat .amount .Tooltip-handle {
     font-size: 2rem;
  }

  .Dashboard .small-stats-container .stats-positions .stat-icon-background{
    min-width: 45px;
    height: 45px;
    margin-right: 2rem;
  }

  .Dashboard .section-title-block .total-containers{
    flex-basis: 55%;
  }
  .Dashboard .section-title-content .Page-title {
    flex-basis: 45%;

  }

  .Dashboard .small-stats-container .volume-fees-stats-container.small-stat .stats-positions:nth-child(1) .stat-icon-background{
    min-width: 45px;
  }
  .Dashboard .small-stats-container .volume-fees-stats-container.small-stat .stats-positions:nth-child(2) .stat-icon-background{
    min-width: 45px;
  }

  .Dashboard.page-layout .small-stats-container  .small-stat{
    flex-wrap: wrap;
  }


}

@media (max-width: 900px) {
  .Dashboard .section-title-content{
    display: flex;
    flex-direction: column;
  }
  .Dashboard .App-card-title-mark-info {
    margin-bottom: 2rem;
  }

  .Dashboard .section-title-block .total{
    margin-top: 1rem;
    padding: 0.75rem;
  }
  .Dashboard .stats-container{
    display: flex;
    flex-wrap: wrap;
  }
  .Dashboard .big-stats-container{
    flex-basis: 100%;
    margin-bottom: 2rem;
  }
  .Dashboard .small-stats-container{
    flex-basis: 100%;
    margin-bottom: 2rem;
    flex-direction: column;
  }

  .Dashboard .small-stats-container .small-stat:nth-child(1){
    margin-left: 0;
  }

  .Dashboard .flp-assets-cardcontainer{
    display: flex;
    flex-direction: column;
  }
  .Dashboard .flp-assets-cardcontainer .App-card{
    width: 100%;
  }

  .Dashboard .stats-wrapper .stats-block{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
    border: 1px solid #272A2F;
    background: #222124;
    padding-bottom: 0;
    margin-bottom: 1rem;
    border-radius: 0.75rem;
  }

  .Dashboard .stats-wrapper .stats-block .App-card-title{
    flex-basis: 100%;
  }

  .Dashboard .stats-wrapper .stats-block .App-card-row{
    flex-basis: 50%;
    margin-bottom: 2rem;
  }
  .Dashboard .stats-wrapper .stats-block .App-card-row .label{
    margin-bottom: 0.5rem;
  }

  .Dashboard .stats-wrapper .stats-block .App-card-row:nth-child(2n ){

    align-items:  flex-start;
  }
  .Dashboard .stats-wrapper .stats-block .App-card-row:nth-child(2n + 1){
    display: flex;
    align-items:  flex-end;
  }

  .Dashboard .stats-wrapper .App-card-divider{
    display: none;
  }

  .Dashboard .stats-wrapper{
    background: none;
    border: none;
    padding: 0;
  }
  .Dashboard .volume-fees-stats-container .small-stat{
    flex-wrap: nowrap;
  }

  .Dashboard.page-layout .small-stats-container  .small-stat .stats-positions{
    flex-basis: 49%;
  }

  .Dashboard.page-layout .small-stats-container .volume-fees-stats-container.small-stat .stats-positions.volume .stats-positions{
    flex-basis: 0%;
  }
  .Dashboard.page-layout .small-stats-container .volume-fees-stats-container.small-stat .stats-positions.volume .stats-positions:nth-child(2){
    flex-basis: 0%;
    flex-basis: 180rem;
  }
  .Dashboard.page-layout .small-stats-container .volume-fees-stats-container.small-stat .stats-positions .secondary-stat{
    flex-basis: 180rem;
  }

  .Dashboard .volume-fees-stats-container{
    margin-top: 2rem;
  }

  .Dashboard .small-stats-container .volume-fees-stats-container{
    margin-left: 0;
  }

  .Dashboard .small-stats-container .volume-fees-stats-container{
    flex-direction: row;
  }

  .Dashboard .small-stats-container .small-stat:nth-child(1){
    flex-direction: row
  }
}

@media (max-width : 480px) {
  .Dashboard .big-stats-container .aum-amount {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .Dashboard.page-layout.default-container .stats-container .small-stats-container .small-stat .stats-positions {
    flex-basis: 0%;
  }

  .Dashboard .big-stats-container .aum {
    font-size: 1.75rem;
  }

  .Dashboard .section-title-block .total-containers {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
  }
  .Dashboard .section-title-block .total {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  .Dashboard .totaldiv {
    flex-basis: 0%;
  }

  .Dashboard .label.totaldiv {
    flex-basis: 40%;
  }

  .Dashboard .small-stats-container .small-stat:nth-child(1) .amount .Tooltip-handle,
  .Dashboard .volume-fees-stats-container .small-stat .amount .Tooltip-handle {
    font-size: 1.5rem;
    display: flex;
  }

  .Dashboard .volume-fees-stats-container {
    margin-left: 1rem;
    justify-content:flex-start;
  }

  .Dashboard .volume-fees-stats-container .small-stat {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
  }

  .Dashboard .volume-fees-stats-container .small-stat:nth-child(1) .stat-icon-background {
    width: 30px;
    height: 34px;
  }
  /* .Dashboard .volume-fees-stats-container .small-stat:nth-child(2) {
    padding-top: 5px;
  } */

  .Dashboard .stats-positions .stat-icon-background,
  .Dashboard  .stat-icon-background {
    width: 25px;
    /* height: 33px; */
    flex-basis: 25%;

  }
  /* .Dashboard .small-stats-container .small-stat:nth-child(1) .stat-icon-background,
  .Dashboard .volume-fees-stats-container .small-stat:nth-child(1) .stat-icon-background {
    flex-basis: 20%;
  } */

  .Dashboard .small-stats-container .volume-fees-stats-container {
    flex-basis: 50%;
    margin-left: 0rem;
  }

  .Dashboard .small-stats-container .small-stat:nth-child(1) {
    /* flex-basis: 50%; */
    margin-right: 0.5rem;
  }
  .Dashboard .small-stats-container .small-stat {
    justify-content: flex-start;
  }

  .Dashboard .volume-fees-stats-container .small-stat:nth-child(1) {
    padding: 0;
  }

  .Dashboard .volume-fees-stats-container .small-stat .amount {
    justify-content: flex-start;
  }

  /* .Dashboard .small-stats-container .stats-positions:nth-child(2) {
    margin-top: 20%;
  } */

  .Dashboard .stats-container .small-stats-container {
    flex-direction: column;
  }
  .Dashboard .stats-container .small-stats-container .small-stat {
    width: 100%;
    padding: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .Dashboard.page-layout .small-stats-container .small-stat {
    flex-direction: column;
  }

  .Dashboard.page-layout .small-stats-container .volume-fees-stats-container.small-stat .stats-positions:nth-child(2) .stat-icon-background,
  .Dashboard.page-layout .small-stats-container .volume-fees-stats-container.small-stat .stats-positions:nth-child(1) .stat-icon-background,
  .Dashboard.page-layout .small-stats-container .stats-positions .stat-icon-background {
    min-width: 40px;
    height: 40px;
    flex-basis: 0%;
  }

  .Dashboard .small-stats-container .small-stat .stats-positions {
    margin-bottom: 0.25rem;
  }
  .Dashboard .small-stats-container .small-stat .stats-positions h4 {
    margin-bottom: 0.5rem;
  }
   .stats-container .small-stats-container .small-stat .stats-positions {
    flex-basis: 40%;
  }

  .Dashboard.page-layout .Tab-title-section.tokens {
    padding-top: 15rem;
  }
}

@media (max-width : 376px) {
  .Dashboard .small-stats-container .stats-positions:nth-child(2) {
    margin-top: 0%;
  }

  .Dashboard.page-layout .Tab-title-section.tokens {
    padding-top: 15rem;
  }

  /* .Dashboard .small-stats-container .small-stat:nth-child(1) .stat-icon-background,
  .Dashboard .small-stats-container .small-stat:nth-child(2) .stat-icon-background{
    display: none;
  } */
  /* .Dashboard .volume-fees-stats-container .stat-icon-background {
    display: none;

  }

  .Dashboard .volume-fees-stats-container .small-stat:nth-child(1) .stat-icon-background{
    display: none;

  } */

  /* .Dashboard .small-stats-container .small-stat .stats-positions:nth-child(2) div:nth-child(2){

    margin-top: 2.5rem;
  }
     */

  .Dashboard .big-stats-container .aum {
    font-size: 1.5rem;
  }

  .Dashboard .stats-container .big-stats-container .aum-amount {
    font-size: 2.5rem;
  }

  .Dashboard.page-layout .small-stats-container .volume-fees-stats-container {
    margin-left: 0;
  }

  /* .Dashboard.page-layout .secondary-stat {

  } */
}

.Tab-title-section .Page-title img,
.section-title-content .Page-title img {
  margin-left: 1rem;
}

.Dashboard .Footer-wrapper {
  margin-top: 6rem;
}

.Dashboard .stats-wrapper .stats-block .App-card-title {
  width: 75px;
  margin-bottom: 0;
}
.Dashboard .stats-wrapper .stats-block .App-card-row {
  width: 150px;
}
.Dashboard .stats-wrapper .stats-block .App-card-row .label {
  margin-bottom: 0.75rem;
}

@media (min-width:1100px) {
  .volume-fees-stats-container .secondary-stat {
  /* .volume-fees-stats-container .stat-icon-background{ */
    height: 50%;
  }

  .volume-fees-stats-container .stat-icon-background {
    margin-bottom: auto;
    margin-top: 0.5rem;
   }
}

.Dashboard .Page-description a {
  display: flex;
  align-items: center;
}
.Dashboard .Page-description {
  display: flex;
  justify-content: flex-start;
}
.Dashboard .Page-description .external-link {
  margin-left: 0.25rem;
  margin-bottom: 0.15rem;
}

.Dashboard .section-title-content .Page-description {
  margin-top: 0.5rem;
}
