.launchcard-main-btn-container {
  background-color: #363636;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  width: 60%;
  margin: 1rem auto;
  border-radius: 1.5rem;
  padding: 0.5rem;
}
.launchcard-main-btn-container .launchcard-main-btn-title {
  margin: 1rem 0.5rem 0rem 0.5rem;
}
.launchcard-main-btn-container .launchcard-main-btn-timer {
  margin: 0.7rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.launchcard-main-btn-container .launchcard-main-btn-timer p {
  margin: 0 0.3rem;
}
