.SwapBox-collateral-tooltip-text {
  font-weight: normal;
}

.Exchange-swap-box .Exchange-swap-max {
  position: absolute;
  right: 1.25rem;
  top: 0;
  padding: 0.165rem 0.6rem;
  height: 1.75rem;
  color: #fff;
  border-radius: 1rem;
  z-index: 1;
  cursor: pointer;
  font-size: 1rem;
  position: unset;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  background: none;

  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.Exchange-swap-box .Exchange-swap-max.half,
.Exchange-swap-box .Exchange-swap-max.max{
  background: #D9D9D9;
  color: #363636;
  font-weight: bold;
}

.balance-tag{
  opacity: 0.7;
  font-size: 1.25rem;
  padding-left: 0.5rem;
}

.Exchange-swap-section.receive-section {
  margin-top: 4rem;
}

.Exchange-swap-section-top .align-right.clickable{
  display: flex;
  align-items: center;
}

.Exchange .App-card.Exchange-Info-Card .align-right{
  align-items: center;
}
.Exchange .App-card.Exchange-Info-Card .align-right .transition-arrow{
  margin-top: 0.15rem;
  margin-right: 0.15rem;
}

.Exchange.page-layout .Exchange-swap-box-inner  .Exchange-info-row{
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1.15rem;
}
.Exchange .Exchange-swap-box-inner .App-card.Exchange-Info-Card{
  display: grid;
  /* font-size: 1.4rem; */
  grid-template-columns: auto auto;
  /* margin-bottom: 0.465rem; */
}
.Exchange.page-layout .Exchange-right .App-card.Exchange-Info-Card  .Exchange-info-row{
  margin-bottom: 0.1rem;
}
.Exchange .Exchange-swap-box-inner .Exchange-Info-Card .align-right .Tooltip-handle,
.Exchange .Exchange-swap-box-inner .Exchange-Info-Card .align-right,
.Exchange .Exchange-swap-box-inner .Exchange-Info-Card .align-right .inline-block{
  font-family: sans-serif;
}
.Exchange .Exchange-swap-box-inner .App-card.Exchange-Info-Card .align-right{
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.Exchange.page-layout .Exchange-swap-box-inner .App-card {
  background: #363636;
  border: 1.38404px solid #444444;
  border-radius: 13.8404px;
}

.Exchange.page-layout .align-right.clickable.MarkPrice{
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  font-family: sans-serif;
}

.Exchange-swap-section.price.mark-price{
  margin-top: 2.257rem;
}
