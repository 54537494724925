.ValidationRow {
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 1.5rem;
}

.ValidationRow-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ValidationRow-icon {
  margin-right: 1.5rem;
}

.BeginAccountTransfer .ValidationRow {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.BeginAccountTransfer-validations {
  margin-bottom: 2.325rem;
}

.BeginAccountTransfer .Modal .App-cta {
  display: block;
  text-align: center;
  font-size: 1.5rem;
}


.BeginAccountTransfer .Page-title{
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

@media (min-width: 700px) {
  .BeginAccountTransfer .Page-description{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  
}

@media (min-width: 800px)  {
  .BeginAccountTransfer .pending-transfer.Page-description{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.BeginAccountTransfer .Page-description p{
  margin: 0.25rem;
}
.BeginAccountTransfer .pending-transfer,
.BeginAccountTransfer .text-input{
  background: #363636;
  border: 1px solid #474747;
  border-radius: 10px;
}
.BeginAccountTransfer .Page-content{
  display: flex;
  justify-content: center;
}

.BeginAccountTransfer .Page-content .input-form{
  padding: 2rem;
  padding-bottom: 0rem;
  border: 1px solid #474747;
  border-radius: 10px;
  margin-top: 2rem;
}

.BeginAccountTransfer .pending-transfer.Page-description{
  padding: 1rem;
  margin-top: 2rem;
  max-width: 80rem;
  width: 100%;
}

@media (max-width:500px) {
  .BeginAccountTransfer .pending-transfer{
    font-size: 1.25rem;
  }
}

.BeginAccountTransfer .Page-title-section{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BeginAccountTransfer .text-input{
  min-width: 25rem;
}