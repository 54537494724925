.TokenSelector .Modal .Modal-content {
  width: 31rem;
  margin: 0;
  max-height: 100vh;
}

.TokenSelector-token-name {
  margin-right: 1.5rem;
}

.TokenSelector.disabled {
  pointer-events: none;
}

.TokenSelector.disabled .TokenSelector-caret {
  display: none;
}

.TokenSelector-box {
  /* display: block; */
  cursor: pointer;
  border-radius: 0.6rem;
 text-align: right;
  /*  white-space: nowrap; */

  display: flex;
  align-items: center;
}

.TokenSelector-box:hover {
  color: #adacac;
}

.Token-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Token-info .Token-symbol {
  margin-left: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Token-balance {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.Token-info .token-logo {
  display: block !important;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 2px;
  margin-left: 0 !important;
}

.TokenSelector-caret {
  vertical-align: middle;
  display: inline-block;
  margin-left: 0.465rem;
  font-size: 2.325rem;
  margin-top: -0.465rem;
}

.TokenSelector-token-row {
  cursor: pointer;
  border: 1px solid #ffffff29;
  position: relative;
  background: transparent;
  border-radius: var(--border-radius-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  margin-bottom: 0.8rem;
}

.TokenSelector-tooltip.Tooltip {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.TokenSelector-tooltip-backing {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}


.TokenSelector-token-row.disabled {
  cursor: not-allowed;
}

.TokenSelector-token-row.disabled .Token-info {
  opacity: 0.3;
}

.TokenSelector-token-row.disabled .Token-balance {
  opacity: 0.3;
}

.TokenSelector-token-row.TokenSelector-token-input-row {
  padding: 0;
}

.TokenSelector-token-row input {
  padding: 0.8rem;
  width: 100%;
}

.TokenSelector-token-row .Token-text {
  font-size: 1.25rem;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0px;
}
.TokenSelector-token-row:hover {
  background: #3E3C41;
}

.TokenSelector.right .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}
.text-accent {
  color: #e2e2e3;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0;
  opacity: 0.7;
}

.TokenSelector.right .Modal-content {
  position: absolute;
  width: 35.65rem;
  top: 0;
  bottom: 0;
  overflow: auto;
  border: none;
}

@media (max-width: 700px) {
  .TokenSelector.right .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 3.1rem;
    top: 0;
    right: 3.1rem;
    bottom: 0;
  }
  .Token-info .token-logo {
    width: 3rem;
    height: 3rem;
    margin-right: 0.8rem;
  }
}

.TokenSelector-box  .tokenImage{
  margin-right: 0.5rem;
}

.TokenSelector-tokens{
  position: absolute;
  margin-top: 1px;
  /* top: 100%; */
  /* margin-right: 2.5rem; */
  right: 2.5rem;
  z-index: 100;
  min-width: 240px;
  opacity: 1;
  transform: scaleY(1);
  transition: all 0.2s linear 0s;
  transform-origin: center top;
  border-radius: 0px;
  box-shadow: rgb(0 0 0 / 26%) 0px 10px 15px 0px;
  background-color: rgb(41, 41, 44);
  border: 1px solid transparent;
  padding: 1rem;
}
 

.Exchange-swap-section .TokenSelector-tokens{
  min-width: 40px;
}