.leaderboard_container {
  display: flex;
  justify-content: center;
  margin-top: 3.75rem;
}

.leaderboard_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leaderboard_title h2 {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 39px;
}

.leaderboard_box {
  width: 1124px;
  height: 813px;
  border-radius: 10px;
  border: 2px solid rgba(76, 76, 76, 0.3);
  background: #222124;
  overflow: auto;  
}



.invite_leaderboard {
  width: 221px;
  height: 40px;

  border-radius: 10px;
  background: rgba(158, 110, 230, 0.2);
  backdrop-filter: blur(6.2778096199035645px);
  margin-right: 36px;
}

.invite_leaderboard h4 {
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.leaderboard_data {
  display: grid;
  grid-template-columns: 9% 26% 21% 16% 14% 14%;
  padding: 1rem 0.8rem;
  background-color: #222124;
  color: #acacac;
  border-top-right-radius: 0.9rem;
  border-top-left-radius: 0.9rem;
  font-size: 1.3rem;
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 400;
  margin-left: 35px;
}

.leaderboard-traders {
  margin-left: 32px;
}
.leaderboard-tradingvolume {
  margin-left: 30px;
}
.leaderboard-rtl {
  margin-left: 6px;
}

.leaderboard-points {
  margin-left: 97px;
}

.data_inside_box:hover {
  background: #2d2b31;
}

.rank_num_data {
  margin-left: 10px;
  color: #ddd;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.previoscamp_heading {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previoscamp_heading h3 {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 46px;
  margin-bottom: 20px;
  margin-left: -806px;
}

.previos_camp_box1 {
  width: 380px;
  height: 197px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid rgba(76, 76, 76, 0.4);
  background: #0b0b0b;

  margin: 20px;
}

.previos_camp_box1 span h3 {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 30px;
  margin-top: 23px;
}

.previos_camp_box1 span p {
  color: #b4b4b4;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 30px;
}

.previos_camp_box1 button {
  margin-left: 30px;
  border-radius: 10px;
  width: 140px;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: #35123e;
}

.gradient-text {
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Play";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.previos_camp_box1 text {
  text-align: center;
  font-family: "Play";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
}

.previous_camp_box {
  display: flex;
  margin-left: -312px;
}

.data_inside_box {
  align-items: center;
  color: #fff;
  display: grid;
  font-size: 1.7rem;
  grid-template-columns: 9% 26% 21% 16% 14% 14%;
  padding: 0.5rem 2rem;
  text-align: left;
  border-radius: 10px;
  background: #29272c;
  width: 1046px;
  height: 60px;
  margin-bottom: 10px;
  margin-left: 39px;
}

.data_in_box_aligh {
  display: contents;
}

.referal_leaderboard_volume {
  margin-left: 23px;
  color: #ddd;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.img_constant_referal {
  display: flex;
  gap: 15px;
  margin-left: 28px;
}

.pagination_new {
  display: flex;
  justify-content: center;
  margin-top: 29px;
}

.pagination_borders {
  display: flex;
  gap: 7px;
}

.pagination_content {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  opacity: 0.5;
  background: #3a393d;
}

.pagination_content:hover {
  background: #4d4c53;
}

.leaderboard_trading_volume {
  margin-left: 39px;
  color: #ddd;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.address_data_short {
  margin-top: 5px;
  color: #ddd;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.points_data {
  margin-left: 119px;
  color: #ddd;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.invite_leaderboard_mobile {
  display: none;
}
.leaderboard_border_mobile {
  display: none;
}
.leaderboard_Rank_mobile {
  display: none;
}
.leaderboard_adress_mobile {
  display: none;
}
.leaderboard-tradingvolume_mobile {
  display: none;
}
.leaderboard-rtl_mobile {
  display: none;
}
.leaderboard-points_mobile {
  display: none;
}

.mobile_leaderboard_aligh {
  display: none;
}
.price_data_mobile_all {
  display: none;
}

.mobile_data_all {
  display: none;
}

/* laoding new  */







/*for loading animation*/
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  transform: translate(0px, 346px);
}

.loading-dots span {
  display: inline-block;
  margin: 0 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #8d8d8d;
  animation: blink 1.4s infinite both;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.7s;
}

@media (min-width: 768px) and (max-width: 1140px) {
  .previoscamp_heading h3 {
    margin-left: -57px;
  }
  .previous_camp_box {
    margin-left: -57px;
    flex-direction: column;
  }
  .leaderboard_box {
    overflow: auto;
  }
  .leaderboard_data {
    grid-template-columns: 13% 24% 23% 17% 14% 14%;
  }
}

@media (max-width: 767px) {
  .previoscamp_heading h3 {
    margin-left: 0px;
  }
  .previous_camp_box {
    margin-left: 0px;
    flex-direction: column;
  }
  .leaderboard_box {
    width: 312px;
    height: 1083px;
  }
  .previos_camp_box1 {
    width: 312px;
    height: 216px;
  }
  .leaderboard_title h2 {
    font-size: 20px;
    margin-left: 20px;
  }
  .leaderboard_data {
    display: none;
  }
  .invite_leaderboard h4 {
    display: none;
  }
  .invite_leaderboard {
    width: 122px;
    height: 30px;
    transform: translate(22px, 0px);
  }
  .invite_leaderboard_mobile {
    background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Play;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 18px;
    display: flex;
    margin-top: 8px;
  }
  .leaderboard_border_mobile {
    display: flex;
    width: 284px;
    height: 1px;
    background: #363636;
    margin-top: 9px;
  }
  .leaderboard_Rank_mobile {
    display: flex;
    transform: translate(7px, 4px);
  }
  .leaderboard_adress_mobile {
    display: flex;
    transform: translate(-7px, 4px);
  }

  .mobile_leaderboard_aligh {
    display: flex;
    justify-content: space-between;
    color: var(--Cards, #fff);
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .leaderboard-tradingvolume_mobile_heading {
    display: flex;
    margin-top: 12px;
    margin-left: 10px;
    color: #B4B4B4;
font-family: Play;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
    
  }
  .leaderboard-rtl_mobile_heading {
    display: flex;
    margin-top: 12px;
    margin-left: 10px;
    color: #B4B4B4;
font-family: Play;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .leaderboard-points_mobile_heading {
    display: flex;
    margin-top: 12px;
    margin-left: 10px;
    color: #B4B4B4;
font-family: Play;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .data_inside_box {
    display: none;
  }
  .pagination_new {
    display: none;
  }

  .leader_board_mobile_aligh_flex {
    display: flex;
  }
  .price_data_mobile_all {
    margin-left: 21px;
  }

  .leaderboard-tradingvolume_mobile_data {
    margin-top: 12px;
    margin-left: 0px;
    color: #DDD;
text-align: left;
font-family: Play;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  .price_data_mobile_all {
    display: initial;
  }

  .mobile_data_all {
    display: flex;
    margin-left: 11px;
    margin-right: 13px;
    border-radius: 10px;
    background: #29272c;
    width: 286px;
    height: 131px;
    flex-shrink: 0;
    margin-bottom: 15px;
  }
  .data_mobile_check_border {
    display: flex;
  }

.leaderboard-rtl_mobile_data {
  margin-top: 12px;
    margin-left: 0px; 
    color: #DDD;
font-family: Play;
font-size: 14px;
text-align: left;
font-style: normal;
font-weight: 700;
line-height: normal;
}
  .leaderboard-points_mobile_data {
    margin-top: 12px;
    margin-left: 0px;  
    color: #DDD;
    text-align: left;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .mobile_rank_address {
    display: flex;
    margin-left: 2px;
    margin-right: 13px;
    border-radius: 10px;
    background: #29272c;
    justify-content: space-between;
  }
  .mobile_rank_margin {
    margin-left: 10px;
    margin-top: 10px;
    color: var(--Cards, #fff);
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .mobile_address_margin {
    margin-right: -7px;
    margin-top: 10px;
    color: var(--Cards, #fff);
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .mobile_border_line {
    width: 284px;
    height: 1px;
    background: #363636;
    margin-top: 10px;
  }
  .traderlive_data_mobile {
    margin-left: 22px;
    
  }

  .mobile_address_margin img {
    transform: translate(-4px, 4px);
  }


}
