.ValidationRow {
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 1.5rem;
}

.ValidationRow-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ValidationRow-icon {
  margin-right: 1.5rem;
}

.Faucet .ValidationRow {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.Faucet-validations {
  margin-bottom: 2.325rem;
}

.Faucet .Page-title-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 3rem;
  padding-left: 3rem;
}

.Faucet .Page-content {
  padding-right: 3rem;
  padding-left: 3rem;
}

.Faucet .Page-title {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.Faucet .Page-description {
  max-width: 38.75rem;
  font-size: 1.44rem;
}

.Faucet .Page-content {
  display: flex;
  justify-content: center;
}

.Faucet .Page-content .input-form {
  position: relative;
  margin-top: 2rem;
  width: 100%;
}

.Faucet .token-select-label {
  margin-bottom: 1.5rem;
}

.Faucet .TokenSelector {
  margin-bottom: 2rem;
}

.Faucet .TokenSelector .TokenSelector-box {
  height: 4.5rem;
  padding-left: 1rem;
  background: #363636;
  border: 1px solid #474747;
  border-radius: 1rem;
}

.Faucet .TokenSelector .TokenSelector-tokens {
  right: auto;
  border-radius: 1rem;
}
