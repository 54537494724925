.info-table-row {
  display: grid;
  grid-template-columns: 12% 35% 22% 30%;
  text-align: center;
  width: 33vw;
  padding: 0.5rem 0.1rem;
}
.expanded-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.info-table-title {
  background-color: rgba(136, 136, 136, 0.2);
  color: rgba(255, 255, 255, 1);
  border-radius: 1rem;
  margin: 1rem;
  margin-bottom: 2rem;
}
.expanded-info-table {
  border: 1.5px solid #4c4c4c;
  border-radius: 1rem;
}
.border-bottom {
  border-bottom: 1.5px solid #4c4c4c;
  width: 100%;
}
.total-row {
  width: 100%;
}
.total-row-value {
  font-weight: bolder;
  font-size: 1.8rem;
}
.bold-text {
  font-weight: 800;
}
.expanded-wrapper {
  margin: 2rem;
}

.expanded-info-text {
  margin-left: 1rem;
  border: 1.5px solid #4c4c4c;
  border-radius: 1rem;
  padding: 1rem;
}
.expanded-info-text h3 {
  margin: 0.5rem 0;
}
.imp-dates {
  margin: 0 0.7rem;
}
.action-cnt {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem;
  background-color: #3c3c3c;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.action-btn {
  color: black;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 0.5rem 2rem;
  background-color: #e8e8e8;
  border: none;
  border-radius: 1rem;
}
.expanded-footer {
  border: 1.5px solid #4c4c4c;
  margin: 1.5rem 0;
  border-radius: 1rem;
  padding: 1rem;
}

@media (max-width: 1000px) {
  .expanded-container {
    flex-direction: column;
  }
  .info-table-title {
    width: unset;
  }
  .expanded-info-text {
    margin-left: unset;
    margin: 1rem 0;
  }
  .total-row-value {
    font-size: 1.6rem;
  }
}
