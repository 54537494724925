.TradeHistory.Book .TradeHistory-row{
  font-size: 1rem;
  margin: 0;
  padding: 0.375rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.264);
}
.TradeHistory.Book {
  background-color: none;
  border-left: 0;
}

.green {
  color: #0ecc83;
}
.red {
  color: rgb(255, 100, 83);
}
.blue {
  color: rgb(100, 154, 221);
}

.eachAction {
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem 0 1rem;
}

.eachAction p {
  text-align: right;
  margin: 0.35rem 0;
  font-family: sans-serif;
}
.eachAction p:nth-child(1) {
  padding-right: 2.5%;
  width: 35%;
  text-align: left;
}
.eachAction p:nth-child(2){
  text-align: center;
  width: 30%;
}
.eachAction p:nth-child(3) {
  text-align: right;
  width: 30%;
  padding-left: 2.5%;
}

.eachAction-header {
  background: #222124;
  font-size: 1rem;
}
.eachAction-header .eachAction {
  display: flex;
  justify-content: flex-start;
}
.eachAction-header .eachAction p {
  margin-bottom: 0.75rem;
  margin-top: 1rem;
  text-align: center;
}
.eachAction-header .eachAction p:nth-child(1) {
  text-align: left;
}
.eachAction-header .eachAction p:nth-child(3) {
  text-align: right;
}
