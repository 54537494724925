.menu-items:focus-visible {
  border: 1px solid #262638;
}
.App-cta.address-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  background: #222124;
}

.user-address {
  margin-left: 1rem;
  margin-right: 1rem;
}

/* .App-header-user-address:hover {
  background: #2221247a;
} */
/* .App-header-user-address:active {
  background: var(--dark-blue-active);
} */

.menu-items {
  position: absolute;
  right: 0;
  top: 4.3rem;
  min-width: 15.5rem;
  width: 100%;
  transform-origin: top right;
  border-radius: 0.4rem;
  background: #222124;
  border: 1px solid #272A2F;
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  /* padding: 1rem; */
  background: rgba(61, 61, 61);
border: 1px solid #393939;
/* backdrop-filter: blur(10px); */
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-size: var(--font-base);
  color: #E2E2E3;
  padding-bottom: 1.5rem;
  font-size: var(--font-sm);
  padding: 0.85rem 1.25rem;
  border-radius: 0.4rem;
}
.menu-item:hover {
  background: #e2e2e322;
  border-radius: 0.4rem;
  opacity: 1;
  color: #eee;
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .user-address {
    margin-left: 0;
  }
  .App-cta.address-btn {
    display: flex;
    justify-content: space-between;
  }
  .user-avatar {
    display: none;
  }
}
