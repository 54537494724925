.RewardHistory-row {
  font-size: 1.4rem;
  padding: 1.5rem;
  margin-bottom: 0.8rem;
}

.RewardHistory-time {
  font-size: 1.25rem;
  margin-bottom: 0.155rem;
}

.RewardHistory-row.App-box {
  background : #222124;
  border: 0;
  border-radius: 0;
}
