.saga-flp-card-wrapper {
  background-color: #222124;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-start;
  margin: 1.4rem 1rem;
  padding-bottom: 1rem;
}
.saga-flp-card-wrapper.active {
  background-color: #FFFFFF;
  color: #0D0D0D;
  background-repeat: no-repeat;
}

.saga-flp-card-wrapper p {
  font-size: 1.4rem;
}
.saga-flp-card-wrapper h1 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.saga-flp-card-wrapper hr {
 width: 100%;
 border-color: #525252;
}
.saga-flp-rows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
  margin: 0rem;
}
.saga-flp-rows p {
  margin: 0.4rem 0;
}

.saga-flp-p {
  margin: 1.2rem 0
}

@media (max-width: 900px) {
  .saga-flp-card-wrapper {
    margin: 1.5rem 0;
  }
  .saga-flp-rows p {
    font-size: 1.3rem;
  }
}
