.text_heading_box-all {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 335px;
}
.GetFxdxBuy-panel {
  background-image: url('./assets/backgroundget.svg'); 
  background-size: cover;  
  background-position: center;  
  display: flex; 
  flex-direction: column; 
  align-items: center;  
  justify-content: center;  
  text-align: center; 
}


.buttonmexce:hover {
  background: linear-gradient(91deg, rgba(250, 117, 248, 0.20) -7.61%, rgba(158, 110, 230, 0.20) 116.05%);
}

.buttonmexce {
  width: 310px;
  height: 45px;
  border-radius: 10px;
  border: 1px solid var(--Linear-3, #fa75f8);
  backdrop-filter: blur(6.404902458190918px);
  margin-top: 5.125rem;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.buttonmexce-text {
  display: flex;
  width: 177px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
}

.buttonmexce-text h6 {
  color: var(--Cards, #fff);
  text-align: center;
  font-family: Play;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 64px;
}

.buttonmexce-img {
  margin-left: -78px;
  margin-top: 14px;
}

.main-text h1 {
  color: var(--Cards, #fff);
  text-align: center;
  font-family: Play;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main-text {
  margin-top: -48px;
}

.text-undermain {
  margin-top: -68px;
}

.text-undermain h3 {
  color: var(--Cards, #fff);
  text-align: center;
  font-family: Play;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-undermain span {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.stakehere-but {
  width: 204px;
  height: 40px;
  border-radius: 10px;
  background: linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%);
  cursor: pointer;
}
.stakehere-but h3 {
  color: #000;
  text-align: center;
  font-family: Play;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 8px;
}

.bento-to-buy {
  margin-top: 57px;
  display: flex;
  gap: 25px;
}
.bento-to-buy-two {
  margin-top: 25px;
  display: flex;
  gap: 25px;
}

.bento-box {
  width: 300px;
  height: 60px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  cursor: pointer;
}

.bento-box h3 {
  color: var(--Cards, #fff);
  text-align: center;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 19px;
  margin-left: 20px;
}

.bento-box img {
  width: 12px;
  height: 17px;
  margin-left: 87px;
  margin-top: 21px;
}

.bento-box span {
  color: #838383;
  text-align: right;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 39px;
  margin-top: 22px;
}

.bento-box2 {
  width: 300px;
  height: 60px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
}

.bento-box2 span {
  color: #838383;
  text-align: right;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 80px;
  margin-top: 22px;
}
.bento-box2 h3 {
  color: var(--Cards, #fff);
  text-align: center;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 19px;
  margin-left: 20px;
}

.bento-box2 img {
  width: 12px;
  height: 17px;
  margin-left: 87px;
  margin-top: 21px;
}

.bento-box:hover {
  background: rgba(255, 255, 255, 0.20);
}
.bento-box2:hover {
  background: rgba(255, 255, 255, 0.20);
}

@media (max-width: 768px) {
  .buttonmexce {
    width: 184px;
    height: 32.036px;
  }
  .buttonmexce-text h6 {
    font-size: 12px;
    margin-top: 34px;
    margin-left: -69px;
  }
  .buttonmexce-img {
    margin-left: -105px;
    margin-top: 8px;
  }
  .buttonmexce-img img {
    width: 8.214px;
    height: 12.321px;
  }
  .main-text h1 {
    width: 273px;
    font-size: 48px;
    margin-top: 60px;
  }
  .text-undermain h3 {
    font-size: 16px;
    margin-top: 50px;
    width: 22vh;
  }
  .text-undermain span {
    font-size: 16px;
  }
  .stakehere-but {
    margin-top: 28px;
    width: 140px;
    height: 36px;
  }
  .stakehere-but h3 {
    font-size: 16px;
  }

  .bento-to-buy {
    flex-direction: column;
  }
  .bento-to-buy-two {
    flex-direction: column;
  }
}
