.hide-btn {
  display: none;
}

.launch-container .flowchart {
  margin: 10rem 0rem;
  text-align: center;
}
.launch-container .flowchart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 3rem;
}

.launch-container .flowchart img {
  width: 100%;
  padding: 0rem 3rem;
}
.launch-container .flowchart .flowchart-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.launch-container .flowchart .flowchart-actions button {
  margin: 1.5rem 0;
  background-color: #4e4d50;
  color: #acacac;
  padding: 1.2% 2%;
  font-size: 90%;
  font-weight: 700;
  border-radius: 1rem;
  min-width: 20%;
  height: 8rem;
}
.highlight-text {
  color: #ffffff !important;
}
.launchcard-mbl {
  display: none;
}
.flowchart .flowchart-actions .flowchart-actions-btn {
  cursor: default;
}
.launchcard-desktop {
  display: flex;
  flex-direction: column-reverse;
}
@media (max-width: 1000px) {
  .launch-container .flowchart {
    display: none;
  }
  .launch-container .launchcard-desktop {
    display: none;
  }
  .launchcard-mbl {
    display: contents;
  }
  .launchcard-mbl .swipper {
    margin: 3rem auto 0 auto;
    width: 100%;
  }
  .launchcard-mbl .launchcard-imageindex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .launchcard-mbl .launchcard-imageindex p {
    margin: 1rem;
    color: #4e4d50;
    font-size: 2.5rem;
    cursor: pointer;
  }
}
