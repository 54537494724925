.referral-hero {
  display: flex;
  justify-content: left;
  margin-top: 159px;
  margin-left: 17px;
  z-index: 1;
}

.data_disclaimer {
  display: flex;
  flex-direction: column;
}

.disclaimer {
  font-family: Play;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-left: -627px;
  margin-top: 10px;
}

.disclaimer_link {
  font-family: Play;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-left: -884px;
  margin-top: 14px;
}

.referal_button_gen {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  margin-top: 16px;
}

.for_referal_button_referalcodegen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 523px;
  gap: 274px;
}

.submit-button-referal {
  color: #000;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.referal_button_gen input {
  font-size: 1rem;
  color: white;
  outline: none;
  background: none;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
  height: 40px;
  border-radius: 5px;
  background: #363636;
}

.referal_button_gen button {
  width: 250px;
  height: 39px;
  border-radius: 5px;
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
}

.referal_button_gen-input::placeholder {
  color: #9f9f9f;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.referral-hero img {
  position: absolute;
}

.referral-hero h3 {
  font-family: Play;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 159px;
  width: 689px;
  margin-top: 87px;
}
.textinbox {
  display: flex;
  flex-direction: column;
}

.scrollandrules {
  display: flex;
  justify-content: center;
}

.scrollimage {
  margin-left: -356px;
}

.textreferalrules h5 {
  color: #949494;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-left: 31px;
  margin-top: 0px;
}

.textinbox p {
  color: #d4d4d4;
  font-family: Play;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 159px;
  margin-top: -33px;
}

.textinbox p span {
  color: #fff;
  font-family: Play;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.treasureimg img {
  opacity: 1.7;
  mix-blend-mode: lighten;
  margin-top: 45px;
  margin-left: -246px;
  width: 838px;
  height: 463.375px;
}

.treasureimg {
  z-index: 0;
  border-radius: 20px;
}
.referalall {
  display: flex;
  justify-content: center;
  margin-bottom: 41px;
}

.inviteandearn {
  display: flex;
  justify-content: center;
  margin-left: 150px;
  flex-direction: column;
}
.inviteandearn h3 {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.inviteandearn p {
  color: #929292;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -17px;
}

.invitedataall {
  display: flex;
  justify-content: center;
  margin-left: -60rem;
}
.tabledata-invite_bonus {
  transform: translate(406px, 0px);
}
.tabledata-invite_actions {
  transform: translate(72px, 0px);
}

.invitereferaltable {
  width: 1140px;
  height: 755px;
  border-radius: 10px;
  border: 2px solid rgba(76, 76, 76, 0.3);
  background: #1d1d1f;
}

.invitetablebox {
  display: flex;
  justify-content: center;
}
.tabledataheader {
  display: flex;
  justify-content: space-around;
  margin-top: 32px;
  margin-left: -340px;
  margin-right: -287px;
}

.tabledatabox {
  width: 1046px;
  height: 60px;

  border-radius: 10px;
  background: #242326;
  margin-left: 47px;
  margin-top: 15px;
}
.tabledatabox:hover {
  background: #2d2b31;
}

.imageandinvitetxt {
  display: flex;
  /* justify-content: space-between; */
}
.imageinbox_logo img {
  width: 28px;
  height: 28px;
  margin-left: 37px;
  margin-top: 16px;
}
.twitter_invite {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  margin-left: 9px;
}

.bonus_table {
  width: 60px;
  height: 31px;

  border-radius: 4px;
  background: rgba(158, 110, 230, 0.2);

  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-left: -128px;
}

.bonus_table h4 {
  margin-top: -5px;
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translate(-2px, 10px);
}

.datainisdetable_aligh {
  display: flex;
  justify-content: space-between;
  margin-right: -686px;
}

.VectorRedi img {
  width: 15px;
  height: 15px;
  stroke-width: 2px;
  stroke: var(--Cards, #fff);
}

.imgvectorarrow {
  margin-top: 22px;

  transform: translate(-711px, -3px);
}

.bonus_table_insta {
  width: 60px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(158, 110, 230, 0.2);
  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-left: -132px;
}
.bonus_table_insta h4 {
  margin-top: -5px;
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translate(-2px, 10px);
}

.bonus_table_img {
  width: 60px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(158, 110, 230, 0.2);
  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-left: -178px;
}

.bonus_table_img h4 {
  margin-top: -5px;
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translate(-2px, 10px);
}

.bonus_table_telegram {
  width: 60px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(158, 110, 230, 0.2);

  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-left: -152px;
}

.bonus_table_telegram h4 {
  margin-top: -5px;
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translate(-2px, 10px);
}

.bonus_table_whatsapp {
  width: 60px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(158, 110, 230, 0.2);

  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-left: -159px;
}

.bonus_table_whatsapp h4 {
  margin-top: -5px;
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translate(-2px, 10px);
}

.bonus_table_discord {
  width: 60px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(158, 110, 230, 0.2);
  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-left: -114px;
}

.bonus_table_discord h4 {
  margin-top: -5px;
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translate(-2px, 10px);
}

.bonus_table_facebook {
  width: 60px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  background: rgba(158, 110, 230, 0.2);

  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-left: -155px;
}

.bonus_table_facebook h4 {
  margin-top: -5px;
  background: var(--Linear-3, linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translate(-2px, 10px);
}

.referafriend h3 {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: -832px;
  display: flex;
  justify-content: center;
  margin-top: 84px;
}

.circle span {
  display: flex;
  justify-content: space-evenly;
  margin-top: 49px;
  margin-left: -490px;
}

.sendinvtiation img {
  width: 846px;
  margin-left: 527px;
  margin-top: 36px;
}

.circle {
  display: flex;
  justify-content: center;
}
.sendinvut h3 {
  color: #fff;
  text-align: center;
  font-family: Play;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sendinvut p {
  color: var(--Top-Nav-Bar, #929292);
  text-align: center;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.singupandconnect h3 {
  color: #fff;
  text-align: center;
  font-family: Play;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.singupandconnect p {
  color: var(--Top-Nav-Bar, #929292);
  text-align: center;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.earnreward h3 {
  color: #fff;
  text-align: center;
  font-family: Play;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.earnreward p {
  color: var(--Top-Nav-Bar, #929292);
  text-align: center;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.containtdata_aliealigh {
  display: flex;
  justify-content: center;
  margin-left: 103px;
  margin-bottom: 118px;
}

.contantdatareffre {
  display: flex;
  justify-content: space-around;
  gap: 151px;
}

.circle_mobile_invite {
  display: none;
}
.textfor_mobile {
  display: none;
}
.invite_text_all {
  display: none;
}
.referalall_mobile {
  display: none;
}

.imgvectorarrow img:hover {
  transform: scale(1.05);
}

.copy-referral-image {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.copy-referral-image:hover {
  transform: scale(1.05);
}

.custom-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-83px, 49px);
  padding: 5px 10px;
  background: black;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
}

.copy-referral-image:hover + .custom-tooltip {
  visibility: visible;
}

.link_data_referal {
  text-decoration: none;
  color: #ffffff;
  font-family: Play;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-left: 153px;
  margin-top: 10px;
}

.link_data_referal img {
  margin-left: 10px;
}

.border_line_for_referral {
  width: 1046px;
  height: 2px;
  background: #2a2a2a;
  margin-left: 48px;
  margin-top: 50px;
}

@media (min-width: 768px) and (max-width: 1158px) {
  .referafriend h3 {
    margin-left: 0px;
  }
  .sendinvtiation img {
    width: 739px;
  }
  .contantdatareffre {
    display: flex;
    justify-content: space-around;
    gap: 104px;
    font-size: 10px;
    margin-left: -40px;
  }
}

@media (max-width: 767px) {
  .referafriend h3 {
    margin-left: 0px;
    margin-bottom: -7px;
  }
  .sendinvtiation img {
    display: none;
  }
  .contantdatareffre {
    display: none;
  }
  .circle_mobile_invite {
    display: initial;
  }
  .circle_mobile_invite img {
    width: 79px;
    display: flex;
    justify-content: center;
    margin-left: 272px;
    transform: translate(107px, -3px);
  }
  .textfor_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 17px;
    margin-left: 14px;
  }
  .sendinvut h3 {
    display: flex;
    justify-content: center;
    margin-left: -120px;
    font-size: 16px;
  }
  .sendinvut p {
    display: contents;
    font-size: 14px;
  }
  .sendinvut {
    transform: translate(125px, -24px);
  }
  .singupandconnect h3 {
    display: flex;
    justify-content: center;
    margin-left: -53px;
    font-size: 16px;
  }
  .singupandconnect p {
    display: contents;
    font-size: 14px;
  }
  .singupandconnect {
    transform: translate(125px, -24px);
  }
  .earnreward h3 {
    display: flex;
    justify-content: center;
    margin-left: -136px;
    font-size: 16px;
  }
  .earnreward p {
    display: contents;
    font-size: 14px;
  }
  .earnreward {
    transform: translate(125px, -24px);
  }
  .inviteandearn {
    margin-left: 628px;
  }
  .inviteandearn h3 {
    font-size: 20px;
  }
  .inviteandearn p {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .invitereferaltable {
    width: 312px;
    height: 652px;
  }

  .border_line_for_referral {
    width: 279px;
    margin-left: 14px;
    margin-top: 35px;
  }
  .link_data_referal {
    margin-left: 901px;
    margin-top: -6px;
    text-decoration-line: underline;
  }
  .link_data_referal img {
    transform: translate(-24px, -6px);
  }

  .tabledataheader {
    display: none;
  }
  .tabledatabox {
    width: 279px;
    height: 40px;
    margin-left: 10px;
  }

  .bonus_table_insta {
    transform: translate(201px, -10px);
  }
  .bonus_table_telegram {
    transform: translate(201px, -10px);
  }
  .bonus_table_whatsapp {
    transform: translate(201px, -10px);
  }
  .bonus_table_discord {
    transform: translate(201px, -10px);
  }
  .bonus_table_facebook {
    transform: translate(201px, -10px);
  }
  .imageinbox_logo img {
    width: 20px;
    height: 20px;
    margin-left: 9px;
    margin-top: 10px;
  }
  .twitter_invite {
    display: none;
  }
  .imgvectorarrow {
    margin-top: 11px;
    transform: translate(136px, 0px);
  }
  .ShareonWhatsApp {
    transform: translate(-7px, 0px);
  }
  .invite_text_all {
    display: initial;
    color: var(--Cards, #fff);
    font-family: Play;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
    margin-left: 10px;
  }
  .referalall {
    display: none;
  }
  .referalall_mobile {
    display: initial;
  }
  .referral-hero_mobile img {
    display: flex;
    justify-content: center;
    width: 100%;
    opacity: 0.7;
    mix-blend-mode: lighten;
    margin-left: 8px;
    margin-top: -13px;
  }
  .textinbox_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;
    transform: translate(0px, -48px);
  }
  .textinbox_mobile h3 {
    font-family: Play;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--Posters, linear-gradient(96deg, #fed404 -2.14%, #ff9a02 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .textinbox_mobile p {
    color: #d4d4d4;
    font-family: Play;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -19px;
  }
  .textinbox_mobile span {
    color: #fff;
    font-family: Play;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .scrollandrules_mobile {
    display: flex;
  }
  .textreferalrules_mobile {
    margin-top: -19px;
    margin-left: 10px;
    color: #bcbcbc;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  .datainisdetable_aligh {
    margin-right: 142px;
  }
  .bonus_table {
    width: 60px;
    height: 24px;
    transform: translate(201px, -5px);
  }
  .bonus_table h4 {
    transform: translate(-2px, 14px);
    margin-top: -11px;
  }
  .bonus_table_insta {
    width: 60px;
    height: 24px;
    transform: translate(201px, -5px);
  }
  .bonus_table_insta h4 {
    transform: translate(-2px, 14px);
    margin-top: -11px;
  }
  .bonus_table_telegram {
    width: 60px;
    height: 24px;
    transform: translate(201px, -5px);
  }
  .bonus_table_telegram h4 {
    transform: translate(-2px, 14px);
    margin-top: -11px;
  }
  .bonus_table_whatsapp {
    width: 60px;
    height: 24px;
    transform: translate(201px, -5px);
  }
  .bonus_table_whatsapp h4 {
    transform: translate(-2px, 14px);
    margin-top: -11px;
  }
  .bonus_table_discord {
    width: 60px;
    height: 24px;
    transform: translate(201px, -5px);
  }
  .bonus_table_discord h4 {
    transform: translate(-2px, 14px);
    margin-top: -11px;
  }
  .bonus_table_facebook {
    width: 60px;
    height: 24px;
    transform: translate(201px, -5px);
  }
  .bonus_table_facebook h4 {
    transform: translate(-2px, 14px);
    margin-top: -11px;
  }
  .bonus_table_img {
    width: 60px;
    height: 24px;
    transform: translate(235px, -5px);
  }
  .bonus_table_img h4 {
    transform: translate(-2px, 14px);
    margin-top: -11px;
  }

  .disclaimer {
    margin-left: 22px;
    color: #c1c1c1;
    font-family: Play;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 276px;
  }
  .for_referal_button_referalcodegen {
    flex-direction: column;
    margin-left: 0px;
    gap: 0px;
  }
  .referal_button_gen {
    transform: translate(29px, -6px);
  }
}
