.fee-withdrawal-token-selector .Modal .Modal-content {
  width: 38rem;
}

.fee-withdrawal-token-selector.warning .TokenSelector-box {
  color: rgb(247, 147, 26);
}

/* .fee-withdrawal-token-selector .TokenSelector-box {
  display: inline-flex;
} */

.PositionSelector-selected-receive-token {
  white-space: normal;
  display: inline-flex;
  flex: 1;
}

.fee-withdrawal-token-selector .TokenSelector-box .TokenSelector-caret {
  margin-left: 0;
  margin-right: -2px;
}

@media (max-width: 700px) {
  .fee-withdrawal-token-selector .Modal .Modal-content {
    width: 90vw;
  }
}
