.coin-info-row-wrapper {
  margin: 2rem;
  margin-bottom: 4rem;
}
.coin-info-row-wrapper .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
}
.coin-info-row-wrapper .title > * {
  margin: 0 0.25rem;
}
.coin-info-row-wrapper .coin-info-row-body-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.coin-info-row-wrapper .coin-info-row-body-row .label {
  margin: 2rem 0.5rem;
  text-align: start;
}
.coin-info-row-wrapper .coin-info-row-body-row .value {
  margin: 2rem 0.5rem;
  text-align: start;
}

@media screen and (max-width: 768px) {
  .coin-info-row-wrapper .coin-info-row-body-row {
    display: contents;
  }
}