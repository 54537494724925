.saga-flp-wrapper {
  min-height: calc(100vh - 62px);
  font-size: var(--font-base);
}
.saga-flp-wrapper .bouncing-loader {
  min-height: 70vh;
}
.saga-flp-header-text {
  margin-top: 3rem;
  /* padding-left: 2rem; */
}
.saga-flp-header-text h1 {
  font-size: var(--font-xl);
  margin-bottom: 1rem;
}
.saga-flp-header-text p {
  margin-top: 0rem;
  color: #b7b7bd;
  font-size: var(--font-base);
}
.vertical-line {
  border-left: 0.1rem solid white;
  height: 2rem;
  margin: 0.5rem;
}
.saga-flp-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
}

.saga-flp-header-label {
  margin-top: 5rem;
  width: max-content;
  height: 5rem;
  color: #e2e2e3;
  margin-right: 0.8rem;
}
.saga-flp-header-label .saga-flp-header-label-title {
  font-size: 1.5rem;
  margin: 0.1rem;
  margin-bottom: 0.7rem;
}
.saga-flp-header-label .saga-flp-header-label-title.small {
  display: none;
}

.saga-section-title,
.saga-how-it-works {
  padding-left: 1rem;
}

.saga-how-it-works {
  padding-bottom: 6rem;
}

.saga-active-tier-info-cnt h1 {
  font-size: 2.5rem;
}
.saga-active-tier-info-cnt p {
  font-size: 1.4rem;
}
.saga-flp-header-label-cnt {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3e3c41;
  height: 3.5rem;
  border-radius: 1rem;
  padding: 0.3rem;
  flex-wrap: nowrap;
}
.saga-flp-header-label-cnt * {
  background-color: #3e3c41;
  margin: 0.2rem;
  padding: 0;
}
.saga-flp-header-label-cnt p {
  max-height: 2.8rem;
  font-size: 1.3rem;
  font-weight: 600;
}
.saga-flp-circle-chart-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.saga-flp-card-container {
  /* width: 40vw; */
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
}
.saga-card-container {
  width: 57%;
}
.saga-flp-cnt {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.saga-active-tier-info {
  background-color: #ffffff;
  border-radius: 0.5rem;
  color: #0d0d0d;
  /* width: 60vw; */
  /* max-width: 47.95rem; */
}
.saga-flp-cnt-right {
  margin-top: 1rem;
  margin-left: 4rem;
  flex: 1;
  width: 55%;
}
.saga-active-tier-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: 60%;
}
.saga-active-tier-info-cnt {
  padding: 1.5rem 0.5rem;
}
.saga-active-tier-info-cnt * {
  padding: 0.5rem;
  margin: 0;
}
.saga-flp-available-bg {
  width: 1rem;
  height: 1rem;
  background-color: #d0d0d0;
  margin: 0 0.5rem;
}
.saga-flp-filled-bg {
  width: 1rem;
  height: 1rem;
  background-color: #0d0d0d;
  margin: 0 0.5rem;
}
.saga-flp-circle-chart {
  width: 7rem;
  height: 7rem;
  margin-left: 3rem;
}
.saga-flp-circle-chart-text-box {
  display: flex;
  align-items: center;
  margin: 0.5rem;
}
.saga-flp-circle-chart-text {
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding-right: 1rem;
}
.timeup {
  opacity: 0.7;
}
.saga-explanation {
  background: #222124;
  border: 1.38404px solid #444444;
  border-radius: 1.5rem;
  margin-top: 2rem;
  padding: 1rem 1.5rem;
}
.saga-explanation ul li {
  padding: 0rem;
}

@media (max-width: 900px) {
  .saga-flp-header-text {
    width: 100%;
  }

  .saga-flp-cnt-right {
    margin-left: 0rem;
    margin-top: 1rem;
    width: 100%;
  }
  .saga-flp-header-label-cnt {
    padding: 0.5rem;
  }
  .saga-active-tier-info {
    font-size: 1rem;
    max-width: 100%;
  }
  .saga-flp-header {
    display: flex;
    flex-direction: column;
  }
  .saga-flp-header-label {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 92vw;
    padding: 0.2rem 1rem;
    margin-top: 5rem;
  }
  .saga-flp-header-label .saga-flp-header-label-title.large {
    display: none;
  }
  .saga-flp-header-label .saga-flp-header-label-title.small {
    display: block;
  }

  .saga-flp-card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .saga-flp-cnt {
    flex-direction: column-reverse;
  }
  .saga-active-tier-info {
    width: 100%;
  }
  .saga-flp-swapbox {
    margin-top: 1rem;
  }
  .saga-flp-circle-chart-text {
    display: none;
  }
  .saga-flp-circle-chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
  }
  .saga-flp-header {
    padding: 0rem;
  }
  .saga-section-title,
  .saga-how-it-works {
    padding-left: 0;
  }
  .saga-card-container {
    width: 100%;
  }
}

@media (max-width: 1500px) and (min-width: 900px) {
  .saga-active-tier-info {
    width: 100%;
  }
  .saga-flp-cnt {
    justify-content: space-between;
  }
  .saga-active-tier-info-cnt h1 {
    font-size: 1.5rem;
  }
  .saga-flp-circle-chart-wrapper {
    padding: 2rem;
    justify-content: space-between;
  }
  .saga-flp-circle-chart {
    width: 5rem;
    height: 5rem;
    margin-left: 1.5rem;
  }
  .saga-flp-circle-chart-text {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .saga-flp-circle-chart-text-box {
    margin: 0;
    padding: 0;
    height: 2rem;
  }
}
