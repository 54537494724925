.Bridge {
  /* background: #101124; */
  justify-content: space-between;
}

.Bridge-container {
  width: 100%;
}

/* .Bridge-container .section-title-content {
  display: flex;
  flex-direction: row;
  align-items: center;
} */

.Bridge-container .section-title-content .Tab {
  margin-left: 2rem;
}

.Bridge-container .section-title-content .Tab .Tab-option {
  width: 7.8rem;
}

#widget__iframe {
  width: 100%;
  min-width: 410px;
}

.MuiButtonBase-root.MuiMenuItem-root {
  font-size: 1.6rem;
}

.MuiButtonBase-root.MuiButton-root {
  font-size: 1.6rem;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 1.1rem;
}

.Bridge .MuiTypography-root {
  font-size: 1.4rem;
}

.Bridge .MuiScopedCssBaseline-root {
  font-size: 1.6rem;
}

.Bridge .MuiButtonBase-root.MuiButton-root {
  font-size: 1.4rem;
}

.Bridge .MuiListItemText-root .MuiListItemText-primary {
  font-size: 1.8rem;
}

.Bridge .MuiCardHeader-root .MuiCardHeader-title {
  font-size: 1.8rem;
}

.Bridge .MuiCardHeader-root .MuiCardHeader-subheader {
  font-size: 1.2rem;
}

.Bridge .MuiInputBase-root.MuiOutlinedInput-root {
  font-size: 1.6rem;
}

.Bridge .MuiInputBase-root.MuiInputBase-sizeSmall {
  font-size: 1.6rem;
}

.Bridge .Footer-wrapper {
  margin-top: 7rem;
}

.Bridge-container .card-container {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(5, 3fr);
  grid-template-columns: repeat(4, 3fr);
  grid-gap: 1rem 1.5rem;
  margin: 3rem 0;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 1024px) and (min-width: 767px) {
  .Bridge-container .card-container {
    display: grid;
    grid-template-rows: repeat(6, 3fr);
    grid-template-columns: repeat(3, 3fr);
    grid-gap: 1rem 1rem;
    justify-content: center;
    margin: 3rem auto;
  }
}

@media only screen and (max-width: 767px) {
  .Bridge-container .card-container {
    display: grid;
    grid-template-rows: repeat(18, 1fr);
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem 1rem;
    justify-content: center;
    margin: 3rem auto;
  }
}
@media (min-width: 1040px) {
  #widget__iframe {
    height: 700px;
  }
}

@media (max-width: 1039px) {
  #widget__iframe {
    height: 900px;
  }
}

@media (max-width: 829px) {
  #widget__iframe {
    height: 810px;
  }
}

@media (max-width: 450px) {
  #widget__iframe {
    margin-left: calc(50vw - 226px);
    margin-right: calc(50vw - 226px);
  }
}