
/* ReferralImageDownload.css */

.referral-image-download {
    font-family: 'Arial', sans-serif;
    color: #fff;
    text-align: center;
    background-color: #f0b90b; /* Or any other color you prefer */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 600px; /* Adjust as necessary */
    margin: auto; /* Center the div */
}

.referral-image-download h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.referral-image-download ol {
    text-align: left;
    list-style-position: inside;
    margin: 20px 0;
}

.referral-image-download .referral-code {
    font-size: 20px;
    background: #000; /* Black background for the code section */
    color: #fff;
    padding: 15px 30px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 20px;
}

.referral-image-download .brand-logo {
    font-size: 20px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    /* Add your brand's logo image and style here */
}
