.stake-history-new-heading h1 {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: -568px;
}

.stake-history-new-all {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.stake-history-new-table {
  border-radius: 10px;
  border: 2px solid rgba(76, 76, 76, 0.3);
  background: #1d1d1f;
  width: 1140px;
  height: 50px;
}

.table-one-stake {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 26px;
  margin-top: 14px;
}

.all-text-data {
  display: flex;
  gap: 57px;
}

.all-table-data-img {
  margin-left: -2px;
  margin-top: 17px;
}

.stake-history-click-old:hover {
  background: linear-gradient(91deg, rgba(250, 117, 248, 0.20) -7.61%, rgba(158, 110, 230, 0.20) 116.05%);
}

.stake-history-click-old {
  width: 220px;
  height: 45px;

  border-radius: 8px;
  border: 2px solid var(--Linear-3, #fa75f8);
  background: rgba(18, 18, 18, 0.8);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.stake-history-click-old h4 {
  color: var(--Cards, #fff);
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.stake-history-click-old-aligh {
  display: flex;
  justify-content: center;
  margin-top: -134px;
}

.table-one-stake-mobile-view {
  display: none;
}

@media (max-width: 1144px) {
  .stake-history-new-heading h1 {
    margin-left: 0px;
  }
  .stake-history-new-table {
    width: 328px;
  }
  .all-table-data-img img {
    width: 328px;
    height: 121px;
  }
  .table-one-stake {
    display: none;
  }
  .table-one-stake-mobile-view {
    display: flex;
    justify-content: center;
    gap: 47px;
    margin-left: 21px;
    margin-top: 12px;
    
  }
  .table-one-stake-mobile {
    color: var(--Cards, #FFF);
font-family: Play;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .stake-history-click-old {
    margin-top: 42px;
    width: 150px;
height: 30px;

  }

  .stake-history-click-old h4 {
    font-size: 14px;
    margin-top: 4px;
  }
}
