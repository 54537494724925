.FlpSwap-content {
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
  
  .Tab-title {
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 3.41rem;
    font-weight: 500;
  }
  
  .Tab-description {
    letter-spacing: 0px;
    color: #a9a9b0;
    margin-top: 0.8rem;
  }
  
  .Tab-description span {
    color: #f89191;
  }
  
  .FlpSwap-from-token .Modal {
    align-items: flex-start;
    justify-content: flex-end;
  }
  
  .FlpSwap-from-token .Modal .Modal-content {
    width: 41.85rem;
  }
  
  .FlpSwap-from-token .Modal-content {
    position: absolute;
    width: 38.75rem;
    max-height: 100vh;
    overflow: hidden;
    border: none;
    top: 0;
    bottom: 0;
  }
  .FlpSwap-from-token .Modal-content .Modal-body {
    padding-right: 0.5rem;
    max-height: calc(100vh - 7.75rem);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-overflow-scrolling: auto;
  }
  
  .FlpSwap-content .FlpSwap-stats-card,
  .FlpSwap-content .FlpSwap-box {
    width: 100%;
  }
  
  .FlpSwap-content .FlpSwap-stats-card {
    max-width: 71.2rem;
    margin-right: 0rem;
  }
  
  .FlpSwap-stats-card .App-card-row .label {
    color: #a9a9b0;
    opacity: 1;
  }
  
  .FlpSwap-content .FlpSwap-box {
    max-width: 47.95rem;
    border-radius: 0.4rem;
    padding: 1.5rem;
    padding-top: 0;
  }
  
  .FlpSwap-box .Exchange-swap-option-tabs {
    margin: 1.5rem 0;
  }
  
  .App-card-title-mark-icon {
    position: relative;
    margin-right: 0.8rem;
    display: flex;
  }
  
  .App-card-title-mark-icon .selected-network-symbol {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid #1e1f37;
    background-color: #1e1f37;
    border-radius: 50%;
  }
  
  .App-card-title-mark {
    display: flex;
  }
  
  .App-card-title-mark .App-card-title-mark-title {
    font-size: 1.6rem;
    line-height: 2.1rem;
    letter-spacing: 0px;
    color: #ffffff;
  }
  
  .App-card-title-mark .App-card-title-mark-subtitle {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
    color: #a9a9b0;
  }
  
  .FlpSwap-token-list {
    border: 1px solid #1e2136;
    border-radius: 4px;
    background: #16182e;
    padding: 1.5rem;
    padding-top: 0;
    text-align: left;
  }
  
  .FlpSwap-token-list-content {
    display: grid;
    padding: 1.5rem;
    padding-top: 0;
    text-align: left;
  }
  
  .FlpSwap-box .App-card-title {
    margin-bottom: 0.8rem;
  }
  
  .FlpSwap-box .Exchange-info-row {
    height: 1.5rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .FlpSwap-box .Exchange-info-row .Exchange-info-label {
    margin-right: 0.8rem;
    color: #a9a9b0;
    opacity: 1;
  }
  
  .FlpSwap-cta {
    margin: 0 auto;
    text-align: center;
    padding-top: 0;
  }
  
  .FlpSwap-cta .Exchange-swap-button {
    margin: 0 auto;
    justify-content: center;
    min-height: 4.8rem;
    font-size: 1.5rem;
  }
  
  .token-table {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    width: calc(100% + 3.1rem);
    border-spacing: 0;
    white-space: nowrap;
    font-size: 1.5rem;
  }
  
  .token-grid {
    display: none;
  }
  
  .token-table td,
  .token-table th {
    padding: 1.1625rem;
  }
  
  .token-table td:nth-child(2),
  .token-table th:nth-child(2) {
    width: 11rem;
  }
  
  .token-table td:nth-child(3),
  .token-table th:nth-child(3) {
    width: 32rem;
  }
  
  .token-table td:nth-child(4),
  .token-table th:nth-child(4) {
    width: 20rem;
  }
  
  .token-table td:nth-child(5),
  .token-table th:nth-child(5) {
    width: 13rem;
  }
  
  .token-table td {
    letter-spacing: 0.25px;
    color: #ffffffde;
  }
  
  .token-table th:first-child,
  .token-table td:first-child {
    padding-left: 1.5rem;
    width: 26.35rem;
  }
  
  .token-table th:last-child,
  .token-table td:last-child {
    padding-right: 1.5rem;
    text-align: right;
    width: 17rem;
  }
  
  .token-table th {
    font-weight: normal;
    letter-spacing: 0.25px;
    color: #a9a9b0;
    text-transform: uppercase;
  }
  
  .token-table .action-btn {
    width: 100%;
    justify-content: center;
  }
  .cap-reached {
    margin-left: 0.8rem;
  }
  
  .AppOrder-ball-container {
    position: relative;
    z-index: 2;
  }
  
  .AppOrder-ball-icon {
    text-align: center;
    display: block;
    font-size: 2.325rem;
    opacity: 0.7;
  }
  
  .AppOrder-ball {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 3.565rem;
    height: 3.565rem;
    left: 50%;
    margin-left: -1.7825rem;
    top: -2.2rem;
    border-radius: 3.1rem;
    user-select: none;
    background: linear-gradient(90deg, rgba(45,66,252,1) 0%, rgba(46,61,205,1) 100%);
    cursor: pointer;
    opacity: 0.85;
  }
  .AppOrder-ball:hover {
    opacity: 1;
  }
  
  @media (max-width: 900px) {
    .FlpSwap-content {
      flex-wrap: wrap;
    }
  
    .FlpSwap-content .FlpSwap-stats-card {
      margin: 0;
      margin-bottom: 3.1rem;
      max-width: 100%;
    }
  
    .FlpSwap-content .FlpSwap-box {
      margin: 0;
      max-width: 100%;
    }
  }
  
  @media (max-width: 1100px) {
    .FlpSwap-from-token .Modal {
      align-items: center;
      justify-content: center;
    }
    .FlpSwap-from-token .Modal .Modal-content {
      width: 41.85rem;
      position: absolute;
      max-height: 80vh;
      overflow: hidden;
      border: none;
      top: auto;
      bottom: auto;
    }
    .FlpSwap-from-token .Modal .Modal-content .Modal-body {
      overflow-y: auto;
      max-height: calc(80vh - 8.525rem);
      padding-right: 5px;
      margin: 1.5rem calc(1rem - 0.5rem) 1.5rem 1.5rem;
    }
  
    .FlpSwap-token-list {
      border: none;
      background-color: transparent;
      padding: 0;
    }
  
    .FlpSwap-token-list-content {
      padding: 0;
    }
  
    .token-table {
      display: none;
    }
  
    .token-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.5rem;
      margin-top: 0.8rem;
    }
  }
  @media (max-width: 510px) {
    .token-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      margin-top: 0.8rem;
    }
  }

.Exchange.page-layout  .token-table {
    display: table;
  }

.Exchange.page-layout   .token-table   td:last-child,
.Exchange.page-layout   .token-table  th:last-child{
    text-align: start;
}
.Exchange-left.trades .token-table td:nth-child(3),
.Exchange-left.trades .token-table th:nth-child(3){
  width: 10rem;
}
.Exchange.page-layout .App-card-title-info{
  align-items: center;
}
.Exchange.page-layout .App-card-info-subtitle{
  margin-top: 0.1rem;
  margin-left: 1rem;
}

.Exchange.page-layout .balance-explorer{
  text-decoration: none;
}
