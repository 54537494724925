.chart-token-selector {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.chart-token-selector--current {
  font-size: 2.15rem;
  font-weight: 700;
  margin-right: 0.5rem;
  color: white;
}

.chart-token-menu-items.menu-items {
  width: 15.8rem;
  top: 4.5rem;
  right: unset;
  left: 1rem;
}

.chart-token-menu-items .menu-item {
  font-size: 1.4rem;
  height: 3.4rem;
  color: #E2E2E3;
}

.chart-token-button .small.transparent.chart-token-selector{
  display: flex;
  align-items: center;
  width: 15rem;
}

.chart-token-button .instrument-container{
  display: flex;
  flex-direction: column;
  /* justify-content: fl */
  align-items: flex-start;
}

.chart-token-button .instrument-container span{
  color: #e2e2e3;
}

.instrument-tag{
  font-size: 1.15rem;
  font-weight: 200;
  margin-top: 0.5rem;
  opacity: 0.8;
  margin-left: 0.25rem;
}


@media (min-width : 1100px) {
  .ExchangeChart.tv .instrument-tag{
    padding-left: 3rem;
    margin-top: 0rem;
  }
}
@media (min-width : 480px) {
  .Exchange .ExchangeChart.tv .instrument-tag{
    padding-left: 3rem;
    margin-top: 0.25rem;
    font-size: 1.25rem;
  }
}
