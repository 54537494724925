.Orders-overview {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  max-width: 126.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.Orders-overview-stats {
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Orders-overview-table {
  border-collapse: collapse;
  margin-top: 3rem;
  background: #323232;
  /* border: 1px solid #272A2F; */
  /* border-radius: 10px; */
}
.Orders-overview-table thead{

  background: #323232;
  /* border-radius: 10px 10px 0px 0px; */
}
.Orders-overview-table tbody tr{
  background: #222124;
}
.Orders-overview th {
  opacity: 0.5;
  font-weight: normal;
  text-align: left;
  padding: 1.25rem 1.5rem 1.25rem 1rem;
}

.Orders-overview td {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.25rem 1.5rem 1.25rem 1rem;
}

.Orders-overview .near {
  color: orange;
}

.Orders-overview-action {
  background: none;
  border: none;
  text-decoration: underline;
  color: #fff;
}

.Orders-overview-action {
  background: none;
  border: none;
  text-decoration: underline;
  color: #fff;
}


.color-tags{
  display: flex;
}
.color-tags span{
  margin-right: 3rem;
  padding: 0.75rem  1.5rem;
}
.color-tags .positive{
  background: #91E49E;
  border-radius: 1rem;
  color: #007212;
}
.color-tags .orange {
  background: #E8BD71;
  border-radius: 1rem;
  color: #734900;
}
.color-tags .negative {
  background: #F56868;
  border-radius: 1rem;
  color: #741313;
}

.Orders-overview-stats span {
  display: flex;
  flex-direction: column;
  background: #222124;
  border-radius: 1rem;
  padding: 2rem;
  flex-basis: 24%;
  margin-bottom: 2rem;
}
.Orders-overview-stats span p {
  margin: 0.5rem 0;
}

@media (max-width:800px) {

  .color-tags,
  .Orders-overview-stats {
    flex-direction: column;
  }

  .Orders-overview table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}


