.Orders-toa .Modal-content {
  width: 36rem;
  font-size: 1.5rem;
}

.Orders-toa-accept-rules {
  margin-top: 0.31rem;
}

.Orders-toa-accept-rules .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: 1.4rem;
  margin-bottom: 0.465rem;
}

.Orders-toa-accept-rules .Checkbox-label {
  margin-right: 0.8rem;
}

.Orders-toa-accept-rules ._2-ZbH{
  margin-left: 0.5rem;
  margin-right: 2rem;
}
.Modal.Orders-toa .Modal-body{
  font-size: 1.35rem;
}
.Modal.Orders-toa ul{
  margin-bottom: 1rem;
}

.Orders-toa-accept-rules{
  line-height: 2rem;
}


.Orders-toa button:disabled, 
.Orders-toa button.App-cta:disabled{
  background: #363636;
  color: #E2E2E3;
}
