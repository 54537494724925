.Tooltip {
  position: relative;
}

.Tooltip-popup {
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: absolute;
  border-radius: 4px;
  padding: 1.05rem;
  letter-spacing: 0.4px;
  min-width: 25rem;
  text-align: left;
  background: linear-gradient(90deg, #0b0b0f 0%, rgba(10, 13, 28, 1) 100%);
  z-index: 1000;
  background: #282828;
  border: 1.38404px solid #444444;
  border-radius: 13.8404px;
}

.Tooltip.nowrap .Tooltip-popup {
  min-width: auto;
  white-space: nowrap;
}

.Tooltip.liquidation-tooltip .Tooltip-popup {
  width: 35rem;
  white-space: break-spaces;
}

.Tooltip-popup :first-child {
  margin-top: 0;
}

.Tooltip-popup :last-child {
  margin-bottom: 0;
}

.Tooltip-popup.left-bottom {
  left: 0;
  transform: translateY(0.8rem);
  top: 100%;
}

.Tooltip-popup.right-bottom {
  right: 0;
  transform: translateY(0.8rem);
  top: 100%;
}

.Tooltip-popup.right-top {
  right: 0;
  transform: translateY(-0.8rem);
  bottom: 100%;
}
.Tooltip-popup.right {
  left: 3rem;
  transform: translateY(50%);
  bottom: 100%;
}

.Tooltip-popup.left-top {
  left: 0;
  transform: translateY(-0.8rem);
  bottom: 100%;
}

.Tooltip-popup.center-bottom {
  left: 50%;
  transform: translateY(0.8rem) translateX(-50%);
  top: 100%;
}

.Tooltip-popup.center-top {
  left: 50%;
  transform: translateY(-0.8rem) translateX(-50%);
  bottom: 100%;
}

.Tooltip-handle {
  /* cursor: help; */
  position: relative;
  display: inline-flex;
  /* text-decoration: underline; */
  /* text-decoration-style: dashed; */
  /* text-decoration-thickness: 1px; */
  /* text-underline-offset: 2px; */
  /* text-decoration-color: rgba(255, 255, 255, 0.6); */
}

.Tooltip-handle.plain:after {
  border-bottom: none;
}

.Tooltip.no-underline .Tooltip-handle::after {
  content: unset;
}

.Tooltip-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 0.5rem 0;
}

.Tooltip-row {
  display: grid;
  margin: 0 0 0.5rem 0;
  grid-template-columns: 1fr auto;
}

.Tooltip-row > span.label {
  margin-right: 0.5rem;
  color: #8f8f8f;
}
.Tooltip-row > .Tooltip-row-value {
  color: #e2e2e3;
  text-align: right;
}

.Tooltip-row-values {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Tooltip-row-values li:not(:first-child) {
  padding: 0.25rem 0 0 0;
  text-align: right;
}

.Tooltip-number {
  color: white;
}

@media (max-width: 700px) {
  .Tooltip-row {
    display: block;
    margin-bottom: 1rem;
  }
  .Tooltip-row > span.label {
    display: block;
  }
  .Tooltip-row-values li {
    text-align: left;
  }
  .Tooltip-popup {
    min-width: 20rem;
  }
  .Tooltip-row > .Tooltip-row-value {
    text-align: left;
  }

  .ExchangeChart.tv .instrument-stats .Tooltip-popup.right-bottom{
    right: 0;
  }
}

.instrument-stats .Tooltip-popup.right-bottom{
  right: auto;
  top:0;
}

/* .Tooltip-container {
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
  vertical-align: middle;
} */
.info-circle {
  /* margin-left: 0.25rem;
  margin-top: 0.5rem; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin-left: 3px;
}
/* .Tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.Tooltip-handle{
  display: flex;
  justify-content: center;
  align-items: center;
}

.ExchangeChart-top .Tooltip-handle,
.aum .Tooltip-handle{
  justify-content: flex-start;
}

 .earn-page.page-layout  .Tooltip-popup.right-bottom-right{
  right: auto;
 }

.FlpSwap .FlpSwap-token-list .App-card-row {
  justify-content : space-between;
}

.Exchange-list .Exchange-list-item .Tooltip-handle,
.FlpSwap .FlpSwap-token-list .Tooltip-handle {
  justify-content : flex-start;
}

.FlpSwap .FlpSwap-token-list .Tooltip-handle.available{
  color: #757575;
}

.PositionEditor-info-box.close .Tooltip-popup.right-bottom-right{
  right: auto;
  transform: translateY(0.8rem);
  top: 100%;
}
