.vest-container {
  margin-top: 3rem;
  min-height: 100vh;
}
.vest-container .user-address {
  margin-left: 0;
  text-decoration: none;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.vest-card {
  margin-top: 4rem;
}
.vest-card .label {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}
.vest-card .vest-card-btn-phone {
  display: none;
}
.vest-container .vest-card-btn {
  margin: 1rem 0;
}
.vest-container .vest-card-btn button {
  background-color: #3e3c41;
  padding: 1rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
}
.vest-container .section-title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 1100px) {
  .vest-card {
    padding: 2rem 3.5%;
  }
  .vest-container .user-address {
    font-size: 1.15rem;
  }
  .vest-card .vest-card-btn-phone {
    display: flex;
    justify-content: flex-end;
  }
  .vest-card .vest-card-btn-phone button {
    background-color: #3e3c41;
    padding: 1rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1.5rem;
  }
  .vest-container .vest-card-btn {
    display: none;
  }
}
