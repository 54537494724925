.btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  background: transparent;
  padding: 0.75rem 1.25rem;
  color: white;
  font-size: var(--font-sm);
  border: 1px solid var(--dark-blue-border);
  border-radius: var(--border-radius-sm);
  cursor: pointer;

  .btn-label {
    margin-left: 1.1625rem;
    font-weight: normal;
    line-height: 2.325rem;
    letter-spacing: 0;
    font-size: 2rem;
  }

  .btn-image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-height: 2.3rem;
    max-width: 2.3rem;
  }
  &:hover {
    background: var(--dark-blue-hover);
  }
  &:active {
    background: var(--dark-blue-active);
  }
}

.btn.connect-wallet {
  background: #222124;
  border: 1px solid #272A2F;
  border-radius: 28px;
  padding:  0.5rem 1rem;
}
