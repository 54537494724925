.TradeHistory-row {
  font-size: 1.4rem;
  padding: 1.5rem;
  margin-bottom: 0.8rem;
}

.TradeHistory-row.App-box {
  background-color: #222124;
}
.TradeHistory-row.App-box.no-open-orders {
  border: 0;
  border-radius: 0;
}

.TradeHistory-time {
  font-size: 1.25rem;
  margin-bottom: 0.155rem;
}

.TradeHistory-item-link {
  display: inline-block;
}

.Exchange.page-layout .token-table.all-trades tbody tr td:nth-child(1),
.Exchange.page-layout .token-table.all-trades th:nth-child(1) {
  text-align : start;
}
.Exchange.page-layout .token-table.all-trades th:last-child,
.Exchange.page-layout .token-table.all-trades th {
  text-align : end;
}
.Exchange.page-layout .token-table.all-trades tbody tr td {
  text-align : end;
}
.Exchange.page-layout .token-table.all-trades tbody tr td:not(:nth-child(1)) .Tooltip-handle {
  justify-content: flex-end;
}
.Exchange-list .Exchange-list-item .Tooltip-handle

.Exchange.page-layout .TradeHistory .App-button-option.App-card-option {
  background: #D9D9D9;
  color: #222124;
}

.Exchange.page-layout .App-card-option.show-more {
  margin: 2rem 0 2rem 1.5rem;
}

.Exchange.page-layout .TradeHistory {
  /* border-right: 1px solid #444444; */
  min-height: 37rem;

  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
