.main-container {
  margin-bottom: 71px;
}


.image_main_box {
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.image_main_box img {
  position: absolute;
}

.contentinsidebox h4 {
  color: #fff;
  text-align: center;
  font-family: Play;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 8px;
  transform: translate(0px, -35px);
}

.contentinsidebox h3 {
  text-align: center;
  font-family: Play;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--Linear-3, linear-gradient(91deg, #FA75F8 -7.61%, #9E6EE6 116.05%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  backdrop-filter: blur(6.2778096199035645px);
  margin-top: -36px;
}
.line_border {
  width: 908px;
  height: 2px;
  background: rgba(85, 85, 85, 0.5);
  margin-top: -15px;
}

.eventdata {
  display: flex;
  flex-direction: column;
  margin-top: -26px;
  margin-left: -44px;
}

.eventdata h5 {
  color: #fff;
  font-family: Play;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.eventdata p {
  color: #b4b4b4;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -47px;
}

.live_timer_countdown {
  display: flex;
  width: 260px;
  height: 60px;
  padding: 7px 1px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #4f4e4e;
  background: #2d2d2d;
  margin-top: 53px;
  margin-right: -33px;
  flex-direction: column;
}

.evnet_data_timer {
  display: flex;
  justify-content: space-between;
}

.data_insidetime {
  
}

.data_insidetime span {
  color: #fff;
  font-family: Play;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.data_insidetime h6 {
  color: #fff;
  font-family: Play;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.live_countdown_data {
  transform: translate(0px, -21px);
  color: #fff;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.doc_and_days {
  color: #b4b4b4;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.reward_doc_txt a {
  color: #b4b4b4;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}
.reward_doc_txt {
  transform: translate(-55px, 52px);
}

.duration_30days_txt {
  transform: translate(51px, 55px);
}

.button_in_svg {
  margin-top: 46px;
  margin-left: 230px;
}

.svg_trade_now {
  color: #000;
  font-family: Play;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.image_main_box_mobile {
  display: none;
}

@media (max-width: 767px) {
  .image_main_box img {
    display: none;
  }
  .image_main_box {
    display: none;
  }
  .image_main_box_mobile {
    display: flex;
    justify-content: center;
    margin-top: 42px;
    margin-bottom: 525px;
  }
  .image_main_box_mobile img {
    position: absolute;
  }


  .text_heading_box_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text_heading_box_mobile h4 {
    color: #fff;
    text-align: center;
    font-family: Play;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.2px;
    transform: translate(0px, -28px);
  }
  .monthly_fxdx_data_mobile h3 {
    text-align: center;
    font-family: Play;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 250px;
    margin-left: 184px;
  }
  .line_border {
    width: 201px;
    height: 2px;
    transform: translate(206px, 10px);
  }
  .monthly_fxdx_data_mobile {
    display: flex;
    justify-content: center;
    margin-left: -180px;
  }
  .evnet_data_timer {
    display: flex;
    flex-direction: column;
}
.eventdata {
  margin-top: -14px;
  margin-left: -1px;
}
 .eventdata h5{
  text-align: center;
font-family: Play;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 58px;
 }
 .eventdata p {
  color: #B4B4B4;
text-align: center;
font-family: Play;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
 }
 .live_timer_countdown {
    width: 212px;
    height: 60px;
    margin-left: 29px;
    margin-top: 10px;
 }
 .doc_and_days {
  flex-direction: column;
  font-size: 12px;
 }
 .reward_doc_txt a{
  font-size: 12px;
  color: #b4b4b4;
  font-family: Play;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
 }
 .reward_doc_txt {
  transform: translate(4px, 29px);
}
.duration_30days_txt {
  transform: translate(2px, 47px);
}
.main-container {
  margin-bottom: -383px;
}
}
