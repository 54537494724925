.stake-history-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 271px;
}

.stake-history-table {
  margin-left: 139px;
    margin-right: 143px;
}

.stake-table-header {
  display: flex;
  align-items: center;
  background: var(--app-fxdx-exchange-mine-shaft, #323232);
  height: 46.8px;
  color: var(--app-fxdx-exchange-spun-pearl, #a9a9b0);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.01563rem;
  text-transform: uppercase;
  border-radius: 8px 8px 0px 0px;
  margin-left: -20px;
  width: 103%;
}

.stake-table-data {
  border-bottom: 1px solid var(--app-fxdx-exchange-tundora, #444);
  background: var(--app-fxdx-exchange-baltic-sea, #222124);
  color: var(--app-fxdx-exchange-nero-87, rgba(255, 255, 255, 0.87));
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01563rem;
  border-radius: 0px 0px 8px 8px;
  margin-left: -20px;
  width: 103%;
}

.stake-table-data .table-data:hover { 
  background-color: #575151; /* Adjust the color as needed */ 
  cursor: pointer; /* Optional: changes the mouse cursor to indicate interactivity */ 
  /* Add more styles here if needed */
} 

.StakeHistoryComponent {
  padding: 0 3.565rem;
  padding-top: 3.1rem;
}

.TradeHistory-row {
  font-size: 1.4rem;
  padding: 1.5rem;
  margin-bottom: 0.8rem;
}

.table-data {
  display: flex;
  align-items: center;
  height: 46.8px;
  border-bottom: 1px solid var(--app-fxdx-exchange-tundora, #444);
  background: var(--app-fxdx-exchange-baltic-sea, #222124);
}

.stake-table {
  display: flex;
  flex-direction: column;
  align-items: center;
    width: 1200px;
    margin-left: 19px;
  
}

.stake-id-comp {
  width: 84px;
  text-align: left;
  padding-left: 15px;
}

.stake-account-comp {
  width: 168px;
  text-align: left;
  padding-left: 5px;
}

.stake-amount-comp {
  width: 216px;
  text-align: left;
  padding-left: 5px;
}

.stake-date-comp {
  width: 216px;
  text-align: left;
  padding-left: 5px;
}

.stake-btn-comp {
  width: 84px;
  text-align: left;
  padding-left: 5px;
  padding-right: 15px;
}

.stake-history-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.stake-history-header {
  width: 1200px;
  height: 46.8px;
  display: flex;
  justify-content: space-between;
  margin-left: 22px;
  margin-right: 22px;
}

.stake-history-header-title {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.stake-history-filters {
  display: flex;
  align-items: center;
  
}

.filter-btn {
  width: 10rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: 1px solid #444;
  background: #222124;
  margin: 5px 0px 5px 15px;
}

.filter-highlighted {
  border-color: grey;
  background-color: #3e3c41;
}

tbody > tr:last-child {
  border-radius: 0px 0px 8px 8px;
}

.TradeHistory-row.App-box {
  background-color: #222124;
}
.TradeHistory-row.App-box.no-open-orders {
  border: 0;
  border-radius: 0;
  margin-left: -14px;
  margin-right: -15px;
}

.TradeHistory-time {
  font-size: 1.25rem;
  margin-bottom: 0.155rem;
}

.TradeHistory-item-link {
  display: inline-block;
}

.Exchange.page-layout .token-table.all-trades tbody tr td:nth-child(1),
.Exchange.page-layout .token-table.all-trades th:nth-child(1) {
  text-align: start;
}
.Exchange.page-layout .token-table.all-trades th:last-child,
.Exchange.page-layout .token-table.all-trades th {
  text-align: end;
}
.Exchange.page-layout .token-table.all-trades tbody tr td {
  text-align: end;
}
.Exchange.page-layout .token-table.all-trades tbody tr td:not(:nth-child(1)) .Tooltip-handle {
  justify-content: flex-end;
}
.Exchange-list
  .Exchange-list-item
  .Tooltip-handle
  .Exchange.page-layout
  .TradeHistory
  .App-button-option.App-card-option {
  background: #d9d9d9;
  color: #222124;
}

.Exchange.page-layout .App-card-option.show-more {
  margin: 2rem 0 2rem 1.5rem;
}

.Exchange.page-layout .TradeHistory {
  /* border-right: 1px solid #444444; */
  min-height: 37rem;

  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.unstakebutton {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01563rem;
}
.stake-history-header-title-mobile {
  display: none;
  
}
.stake-history-filters-mobile {
  display: none;
}




@media (max-width: 1202px) {
  .stake-history-header-title {
    margin-left: 20px;
    display: none;
  }
   .stake-history-filters {
    margin-right: 20px;
   }

  .stake-table {
    width: 1192px;
    margin-left: 47px;
    
  
  }
  .stake-history-header-title-mobile{
      display: initial;
      font-size: 24px;
      display: flex;
    align-items: center;
  }
  

  .stake-history-container {
    overflow: auto;
  }
  .stake-history-table {
    width:100%
  }
  .stake-table-header {
    font-size: 15px;
    margin-left: 78px;
  
  }
  .stake-table-data {
    font-size: 13px;
    margin-left: 78px;
  }
  .stake-history-table {
    margin-left: 100px;
    margin-right: 171px;
  }

}

@media (max-width: 425px) {
  
  .stake-history-filters-mobile{
    display: initial;
    margin-bottom: 26px;
  }
  .stake-history-header-title-mobile{
    display: initial;
    font-size: 24px;
    margin-bottom: 10px;
}
.stake-history-filters{
  display: none;
}
.stake-history-header {
  width: 1200px;
  height: 46.8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.stake-table {
  overflow: auto;
}
  
}

@media (min-width: 1203px) and (max-width: 1439px){
  .stake-table-header {
    margin-left: -20px;
  width: 104%;
  }
  .stake-table-data {
    margin-left: -20px;
    width: 104%;
  }
} 

@media (min-width: 1448px) and (max-width: 2971px) {
  .stake-table {
    display: flex;
    flex-direction: column;
    align-items: center;
      width: 1164px;
      margin-left: 19px;
    
  }
}

