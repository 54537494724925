.coin-info-wrapper {
  width: 80vw;
  background-color: #272a2fc2;
  margin: 5rem auto;
  border-radius: 3rem;
  padding: 1rem;
}
.coin-info-wrapper .main-title{
  text-align: center;
  font-size: 3rem;
   font-weight: 700;
}
@media only screen and (max-width: 768px) {
  .coin-info-wrapper {
    width: 95vw;
  }
  .coin-info-wrapper .main-title{
    font-size: 2rem;
    font-weight: 800;
  }
}