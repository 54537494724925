.CompleteAccountTransfer .Modal .App-cta {
  display: block;
  text-align: center;
}

/* .CompleteAccountTransfer .Footer-wrapper{
  position: absolute;
} */

.CompleteAccountTransfer .Page-title{
  display: flex;
  justify-content: center;
}

@media (min-width: 700px) {
  .CompleteAccountTransfer .Page-description{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  .CompleteAccountTransfer .accounts-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media (max-width: 800px){
  .CompleteAccountTransfer .Page-title-section,
  .CompleteAccountTransfer .Page-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .CompleteAccountTransfer .Page-description{
    font-size: 1.25rem;
  }
}
@media (max-width: 500px){
  .CompleteAccountTransfer .accounts-container .account{
    font-size: 1.15rem;
  }

  .CompleteAccountTransfer .Page-title-section{
    padding-left: 1.5rem;
  }

  .CompleteAccountTransfer .Page-content{
    padding-left: 1.5rem;
  }
}

.CompleteAccountTransfer .account{
  background: #363636;
  border: 1px solid #474747;
  border-radius: 10px;
  padding: 1rem;
}

.CompleteAccountTransfer .accounts-container p{
  margin: 0;
  margin-top: 1rem;
}
.CompleteAccountTransfer .warning{
  margin-bottom: 3rem;
}

.CompleteAccountTransfer .Page-content{
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 2rem;
}
.CompleteAccountTransfer .pending-transfer{
  background: #363636;
  border: 1px solid #474747;
  border-radius: 10px;
  padding: 1rem;
}
