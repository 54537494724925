/* .vest-container {
  margin-top: 3rem;
  min-height: 100vh;
}
.vest-container .user-address {
  margin-left: 0;
  text-decoration: none;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.vest-card {
  margin-top: 4rem;
}
.vest-card .label {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}
.vest-card .vest-card-btn-phone {
  display: none;
}
.vest-container .vest-card-btn {
  margin: 1rem 0;
}
.vest-container .vest-card-btn button {
  background-color: #3e3c41;
  padding: 1rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
}
.vest-container .section-title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@media (max-width: 1100px) {
  .vest-card {
    padding: 2rem 3.5%;
  }
  .vest-container .user-address {
    font-size: 1.15rem;
  }
  .vest-card .vest-card-btn-phone {
    display: flex;
    justify-content: flex-end;
  }
  .vest-card .vest-card-btn-phone button {
    background-color: #3e3c41;
    padding: 1rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1.5rem;
  }
  .vest-container .vest-card-btn {
    display: none;
  }
}  */

.vest-container {
  max-width: 1200px;
  height: auto;
  margin: auto;
  padding: 20px;
  margin-top: 10px;
}

.section-title-content {
  margin-bottom: 20px;
}

.page-title {
  margin-bottom: 0.5em;
  color: #FFFFFF;
}

.page-description {
  color: #CCCCCC;
}

.vest-card {
  position: relative;
  background: #222124;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}

.card-title {
  /* margin-bottom: 1.5rem; */
  /* margin-top: auto; */
  color: #FFFFFF;
  font-size: 16px;
  margin-right: 10px;
}

.button-container {
  display: flex;
  justify-content:space-between; 
  border-spacing: 10px;
  padding-bottom: 20px;
  
}

.button-cladep {
  display: flex;
}

.button-container h2 {
  width: 20px;
}

.top-card
{
  display: flex;
  flex-direction: row;
}

.claim-button, .deposit-button {
  background-color: #5A5A5A;
  color: #FFFFFF;
  padding: 10px 1px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #3e3c41;
  width: 100px;
  margin: 5px; 
  font-size: small;

  /* flex: 1; */
}

.claim-button {
  /* margin-right: 15px; */
  margin-left: 860px;
}

.claim-button:hover, .deposit-button:hover {
  background-color: #9c9090;
}

.claim-button-with-gradient {
  --bg-size: 400%;
  --color-one: hsl(15 90% 55%);
  --color-two: hsl(40 95% 55%);
  background-color: #5A5A5A;
  background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 10s infinite linear;
}

.deposit-button {
  /* No additional styles needed here unless you want different than .claim-button */
}

.claim-button:disabled {
  cursor: not-allowed;
}

.esFxdx-vault-heading {
  font-size: 2rem;
}

.motivational-modal-content {
  background: #1a1a1a; 
  color: white; 
  width: 90%; 
  max-width: 500px; 
  margin: 20px auto;
  padding: 20px; 
  border-radius: 10px; 
  position: relative; 
  font-size: 1.3rem;
}

.motivational-modal-header {
  margin-left: 25%;
}

.motivational-modal-body {
  align-items: center;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  color: white;
  cursor: pointer;
  font-size: 2.0rem;
}

.buy-fxdx-button {
  background: #5A5A5A;
  color: white;
  border: none;
  padding: 10px 135px;
  font-size: 2rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  display: block;
  margin: 30px auto 0;
  width: calc(100% - 50px);
}

.buy-fxdx-button:hover {
  background: #9c9090; 
}

/* This will ensure that the modal is always centered in the viewport */
.react-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed; /* Stay in place */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center; /* Align vertically */
  justify-content: center; /* Align horizontally */
  z-index: 1000; /* Sit on top */
}

/* This will style the modal content itself */
.react-modal-content {
  position: relative;
  /* background: #2a2a2a; */
  border-radius: 4px;
  padding: 20px;
  width: auto; /* Adjust width to content */
  max-width: 500px; /* Maximum width for larger screens */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Sit on top of the overlay */
}


.Vest-Text {
  font-size: xx-large;
    font-weight: bold;
    margin-bottom: auto;
    
}

.Connect-wallet-button {
  /* background-color: #3e3c41;
  color: #FFFFFF;
  padding: 10px 1px;
  border-radius: 10px;
  cursor: pointer;
  width: 20px; /* Adjust as needed */

  /* Border styling */ 
  /* margin-left: 900px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-color: #CCCCCC;
  border-radius: 10px;
  flex: auto; */ 
  /* background-color: #3e3c41; */
  background-color: #3e3c41;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
}



/* Adjust modal content for mobile responsiveness */
/* Adjust modal content for mobile responsiveness */
/* Base styles */

/* Your existing base styles go here */

/* Responsive styles for screens with max-width: 768px */
@media (max-width: 768px) {
  .react-modal-content {
    width: 90%; /* Full width on small screens */
    max-width: 90%; /* Full width on small screens */
  }

  .Vest-Text {
    font-size: x-large;  
    
  } 

  .card-title {
    font-size: 14px; /* Adjust font size for smaller screens */
    margin-right: 10px; /* Adjust margin for better spacing */
  }

  


  .button-container {
     
     flex-direction: row; /* Stack buttons vertically on small screens */
    align-items: center; /* Center buttons on small screens */
  }

  
  .deposit-button {
     /* Full width on small screens */ 
    margin-left: 0px;
    
     width: 100px;
     background-color: #3e3c41;
  }

  .claim-button {
    margin-left: 500px; /* Remove right margin on small screens */
    width: 100px;
     
     background-color: #3e3c41;
     
  }

  .header-row {
    flex-direction: row; /* Stack header elements vertically on small screens */
    align-items: center; /* Center header elements on small screens */
    
  }
  .Connect-wallet-button{
    font-size: x-small;
  } 
}

@media (max-width: 425px) {
  .modal {
    width: 80%; /* Adjust the width as needed for smaller screens */
    margin: 0 auto; /* Center the modal horizontally */
  }
  
  .card-title {
    font-size: 14px; /* Adjust font size for smaller screens */
    margin-right: 10px; /* Adjust margin for better spacing */
  }

  

  .button-container {
    flex-direction: row; /* Stack buttons vertically on small screens */
   align-items: center; /* Center buttons on small screens */
 }

 
 .deposit-button {
    /* Full width on small screens */ 
    margin-left: 0px;
    
    width: 100px;
    background-color: #3e3c41;
    font-size: x-small;
 }

 .claim-button {
    margin-left: 0px; /* Remove right margin on small screens */
    width: 100px;
    
    background-color: #3e3c41;  
    margin-left: 150px; 
    font-size: x-small;
 }
 .card-title {
  font-size: 14px; /* Adjust font size for smaller screens */
  width: 20px;
  align-items: center;
}
.Connect-wallet-button{
  font-size: x-small;
  width: 150px;
}
}
/* 
@media (max-width: 1024px) {
  .claim-button, .deposit-button{
    width: 270px;
    
  } 
  .claim-button {
    margin-right: 15px;
    margin-left: 700px;
} */




.VestingContainer {
  background: #222124;
  border: 1px solid #272a2f;
  border-radius: 10px;
  font-size: var(--font-base);
  padding: 1.5rem 1.5rem 1.86rem;
}

.vest-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.Connect-wallet-button-new {
  background: #222124;
  border: 1px solid #272A2F;
  border-radius: 28px;
  padding: 0.5rem 1rem;
  height: 37px;
  align-items: center;
}

.buttonclaim {
  background: #484848;
  border: 1px solid #272A2F;
  border-radius:8px;
  padding: 0.5rem 1rem;
  height: 37px;
}

.buttonclaimdep {
  display: flex;
   gap: 13px;
}

.mainallcont {
  display: flex;
  align-items: center;
  width: auto;
  justify-content: space-between;
}

.App-card-row-main {
  display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 1.5rem;
    padding: unset;
    margin-bottom: 27px;
}

.extitle {
  font-size:20px ;
  font-weight: bold;
}

