.bento-title h1 {
  color: var(--Cards, #fff);
  text-align: center;
  font-family: Play;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 512px;
}

.bento-title {
  margin-top: -262px;
}

.all-bento-aligh {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #121212;
}

.bento-data-box {
  width: 386px;
  height: 228px;
  border-radius: 10px;
  border: 2px solid rgba(76, 76, 76, 0.3);
  background: #1d1d1f;
}

.bento-content {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.bento-data-box img {
  margin-left: 114px;
  margin-top: -18px;
}

.box-heading {
  margin-top: -32px;
}

.box-heading h2 {
  color: var(--Cards, #fff);
  text-align: center;
  font-family: Play;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.box-para p {
  color: #bebebe;
  text-align: center;
  font-family: Play;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bento-data-box-2 {
    width: 386px;
    height: 228px;
    border-radius: 10px;
    border: 2px solid rgba(76, 76, 76, 0.3);
    background: #1d1d1f;
    margin-top: 20px;
}
.bento-data-box-2 img {
    margin-left: 114px;
    margin-top: -18px;

}

.border-bento-stake  {
  background: #121212;
  display: flex;
  flex-direction: column;
}

.border-bento-stake img{
  width: 1180px;
height: 262px;

}

.border-bento-stake h2 {
  color: var(--Cards, #FFF);
text-align: center;
font-family: Play;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: -111px;

}




.stake-image-all{
  display: flex;
  justify-content: center;
  background: #121212;
}

@media (max-width: 768px) {
  .bento-title h1{
    width: 320px;
    font-size: 24px;
  }
  .bento-content {
    flex-direction: column;
  } 
  .bento-data-box {
    width: 328px;
    height: 193.741px;
  }
  .bento-data-box img{
    margin-left: 86px;
  }
  .box-heading h2 {
    font-size: 16px;
  }
  .box-para p {
    font-size: 12px;
  }
  .bento-data-box-2 {
    width: 328px;
    height: 193.741px;
  }
  .bento-data-box-2 img{
    margin-left: 86px;
  }
  .bento-content {
    flex-direction: column;
  }
}

