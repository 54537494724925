.FlpSwap-content {
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.Tab-title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 3.41rem;
  font-weight: 500;
}

.Tab-description {
  letter-spacing: 0px;
  color: #a9a9b0;
  margin-top: 0.8rem;
}

.Tab-description span {
  color: #f89191;
}

.FlpSwap-from-token .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.FlpSwap-from-token .Modal .Modal-content {
  width: 41.85rem;
}

.FlpSwap-from-token .Modal-content {
  position: absolute;
  width: 38.75rem;
  max-height: 100vh;
  overflow: hidden;
  border: none;
  top: 0;
  bottom: 0;
}
.FlpSwap-from-token .Modal-content .Modal-body {
  padding-right: 0.5rem;
  max-height: calc(100vh - 7.75rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
}

.FlpSwap-content .FlpSwap-stats-card,
.FlpSwap-content .FlpSwap-box {
  width: 100%;
}

.FlpSwap-content .FlpSwap-stats-card {
  max-width: 71.2rem;
  /* margin-right: 1.5rem; */
  justify-content: space-around;
}

.FlpSwap-stats-card .App-card-row .label {
  color: #a9a9b0;
  opacity: 1;
  margin-bottom: 0.75rem;
}

.FlpSwap-content .FlpSwap-box {
  max-width: 47.95rem;
  border-radius: 0.4rem;
  padding: 2rem 1.5rem;
  /* padding-top: 0; */
  background: #222124;
  /* border: 1.38404px solid #444444; */
  border-radius: 0;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.FlpSwap-box .Exchange-swap-option-tabs {
  margin: 1.5rem 0;
}

.App-card-title-mark-icon {
  position: relative;
  margin-right: 0.8rem;
  display: flex;
}

.App-card-title-mark-icon .selected-network-symbol {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #1e1f37;
  border-radius: 50%;
}

.App-card-title-mark {
  display: flex;
}

.App-card-title-mark .App-card-title-mark-title {
  font-size: var(--font-md);
  line-height: 2.1rem;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-title-mark .App-card-title-mark-subtitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0px;
  color: #a9a9b0;
}

.FlpSwap-token-list {
  border: 1px solid #1e2136;
  border-radius: 4px;
  background: #16182e;
  padding: 1.5rem;
  padding-top: 0;
  text-align: left;
}

.FlpSwap-token-list-content {
  display: grid;
  padding: 1.5rem;
  padding-top: 0;
  text-align: left;
}

.FlpSwap-box .App-card-title {
  margin-bottom: 0.8rem;
}

.FlpSwap-box .Exchange-info-row {
  height: 1.5rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FlpSwap-box .Exchange-info-row .Exchange-info-label {
  margin-right: 0.8rem;
  color: #a9a9b0;
  opacity: 1;
}

.FlpSwap-cta {
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
  margin-top: 1.5rem;
}

.FlpSwap-cta .Exchange-swap-button {
  margin: 0 auto;
  justify-content: center;
  min-height: 4.8rem;
  font-size: 1.5rem;
  font-weight: 500;
  background: #E2E2E3;
  border-radius: 1rem;
  color: #222124;
}

.token-table {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  width: calc(100% + 3.1rem);
  border-spacing: 0;
  white-space: nowrap;
  font-size: var(--font-base);
}

.token-grid {
  display: none;
}

.App-card.totalSupply  .App-card-row .label{
  color: #a9a9b0;
  opacity: 1;
}

.token-table td,
.token-table th {
  padding: 1.1625rem;
}

.token-table td:nth-child(2),
.token-table th:nth-child(2) {
  width: 11rem;
}

.token-table td:nth-child(3),
.token-table th:nth-child(3) {
  width: 32rem;
}

.token-table td:nth-child(4),
.token-table th:nth-child(4) {
  width: 20rem;
}

.token-table td:nth-child(5),
.token-table th:nth-child(5) {
  width: 13rem;
}

.token-table td {
  letter-spacing: 0.25px;
  color: #ffffffde;
}

.token-table th:first-child,
.token-table td:first-child {
  padding-left: 1.5rem;
  width: 26.35rem;
}

.token-table th:last-child,
.token-table td:last-child {
  padding-right: 1.5rem;
  text-align: right;
  width: 17rem;
}

.token-table th {
  font-weight: normal;
  letter-spacing: 0.25px;
  color: #a9a9b0;
  text-transform: uppercase;
}

.token-table .action-btn {
  width: 100%;
  justify-content: center;
}
.cap-reached {
  margin-left: 0.8rem;
}

.AppOrder-ball-container {
  position: relative;
  z-index: 2;
}

.AppOrder-ball-icon {
  text-align: center;
  display: block;
  font-size: var(--font-lg);
  opacity: 0.87;
}

.AppOrder-ball {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 3.565rem;
  height: 3.565rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -0.5rem;
  border-radius: 3.1rem;
  user-select: none;
  /* background: linear-gradient(90deg, rgba(45, 66, 252, 1) 0%, rgba(46, 61, 205, 1) 100%); */
  background: #636363;
  cursor: pointer;
  opacity: 1;
}
.AppOrder-ball:hover {
  opacity: 0.85;
}

.FlpSwap-stats-card .App-card-row{
    display: flex;
    flex-direction: column;
}
@media (max-width: 900px) {
  .FlpSwap-content {
    flex-wrap: wrap;
  }

  .FlpSwap-content .FlpSwap-stats-card {
    margin: 0;
    margin-bottom: 3.1rem;
    max-width: 100%;
  }

  .FlpSwap-content .FlpSwap-box {
    margin: 0;
    max-width: 100%;
  }

  .FlpSwap .FlpSwap-stats-card .App-card-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

}

.FlpSwap-token-list .App-card-row{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 0.5rem;
    padding: unset;
    padding-bottom: 1rem;
}

  .FlpSwap-from-token .Modal {
    align-items: center;
    justify-content: center;
  }
  .FlpSwap-from-token .Modal .Modal-content {
    width: 41.85rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }
  .FlpSwap-from-token .Modal .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(80vh - 8.525rem);
    padding-right: 5px;
    margin: 1.5rem calc(1rem - 0.5rem) 1.5rem 1.5rem;
  }

  .FlpSwap-token-list {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .FlpSwap-token-list-content {
    padding: 0;
  }

  .FlpSwap .token-table {
    display: none;
  }

  .token-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    margin-top: 0.8rem;
  }

.FlpSwap-content .Exchange-swap-option-tabs{
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 0;
  border-top-right-radius: 1rem ;
  border-top-left-radius: 1rem ;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #363636;
  opacity: 1;
  padding: 0;
}
.FlpSwap-content .Exchange-swap-option-tabs .Tab-option{
  flex-basis: 50%;
}
.FlpSwap-content .Exchange-swap-option-tabs  .Tab-option.active{
  background: #E2E2E3;
  color: #222124;
  font-weight: 500;
}
.FlpSwap-content button.App-cta:hover:enabled{
  background: #ffffff;
  opacity: 0.9;
}
.buy-input.Exchange-swap-section{
  /* background: #363636; */
  margin-bottom: 1.5rem;
  border-radius: 1rem;
}

.FlpSwap-box.App-box .buy-input.Exchange-swap-section{
  min-height: auto;
}

.FlpSwap-box.App-box .buy-input .Exchange-swap-section-top{
  margin-bottom: 0.5rem;
}

.Exchange-swap-max {
  outline: none;
  background: #636363;
}

.Exchange-swap-max:hover{
  background: #636363;
  opacity: 0.8;
}

.buy-input .TokenSelector-box:hover{
  color: #ffffff;
  opacity: 0.75;
}

.FlpSwap-token-list .App-button-option{
  color: #E2E2E3;
  background: #363636;
  border-radius: 10px;
}

.FlpSwap-token-list button.App-button-option:hover:enabled{
  background: #363636;
  opacity: 0.75;
}

.token-grid .App-card-options {
  display: flex;
  justify-content: center;
}
.token-grid .App-card-options button{
  flex-basis: 48%;
  justify-content: center;
  font-size: 1.25rem;
}

.recharts-tooltip-label{
  color: #A5A5A5;
}




@media (min-width: 1200px) {

  .token-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin-top: 0.8rem;
  }

 }


@media (max-width: 710px) {
  .token-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    margin-top: 0.8rem;
  }

  .FlpSwap-token-list .Tooltip-row-values li:not(:first-child){
    text-align: left;
  }
}

@media (min-width: 900px){
  .FlpSwap-content {
    display: block;
  }

  .FlpSwap-content .FlpSwap-stats-card {
    display: flex;
    max-width: 100%;
    /* justify-content: space-between; */
    /* flex-wrap: wrap; */
  }

  .FlpSwap-stats-card .App-card-divider{
    display: none;
  }

  .FlpSwap-stats-card .App-card-content{
    display: flex;
    flex-direction: row;
    /* flex-basis: 47.5%; */
  }
  .FlpSwap-stats-card .App-card-title{
    flex-basis: 5%;
  }

  .FlpSwap-stats-card .App-card-content .App-card-row{
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    align-items: center;
  }
  .FlpSwap-stats-card .App-card-content .App-card-row .label{
    margin-bottom: 0.7rem;
  }

  .FlpSwap-stats-card .App-card-content:nth-child(1){
    flex-basis: 57%;
  }
  .FlpSwap-stats-card .App-card-content:nth-child(2){
    flex-basis: 38%;
  }

  .FlpSwap-stats-card .App-card-content:nth-child(1) .App-card-row:nth-child(1){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .FlpSwap .totalSupply .App-card-row{
    display: flex;
    justify-content: space-between;
  }


  .Flp-box-container {
    border-radius: 1.2rem;
    max-width: 47.95rem;
    /* margin-right: 1.75rem; */
  }

  .FlpSwap-content {
    display: flex;
    flex-wrap: wrap;
  }
  .FlpSwap-content .FlpSwap-stats-card {
    flex-basis: 100%;
  }
  .FlpSwap-content .plainChart {
    flex-basis: 50%;
    margin-top: 3rem;
    /* padding: 2rem; */
    width: 40%;
  }
  .FlpSwap-content .Flp-box-container {
    flex-basis: 50%;
    margin-top: 3rem;
    flex-basis: 48%;
  }

  .chart-cell {
    display: flex;
    flex-direction: column;
    padding-right: 5rem;
    padding-top: 2rem;
  }

  .recharts-legend-wrapper,
  .chart-header{
    display: none;
  }


  /* .chart-header h3{
    margin-top: 0;
    margin-bottom: 3rem;
    margin-left: 5rem;
  } */

  .recharts-default-tooltip{
    background: #222124
  }

  .recharts-surface{
    /* background: #222124; */
    /* padding: 2rem; */
    /* padding-right: 5rem;
    padding-top: 2rem; */
    border-radius: 1rem;
  }

  .chart-grid{
    background: #0f0f10;
    border-radius: 1rem;

  }
  .chart-description{
    display: flex;
    justify-content: center;
  }
  .chart-description p{
    padding: 0;
    margin-top: 0;
    color: #a9a9b0;
  }

  .recharts-legend-item{
    padding-right: 5rem;
  }

  .FlpSwap .section-title-block {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width:900px) {

  .FlpSwap .totalSupply,
  .FlpSwap .plainChart {
     display: none;
  }

  .Flp-box-container {
    width: 100%;
  }
}
@media (min-width:1080px) {
  .FlpSwap .totalSupply {
    width: 50%;
    margin-bottom: 1rem;
    align-self: flex-end;
  }
}
@media (max-width:1080px) {

  .FlpSwap .section-title-block{
    display: flex;
    flex-direction: column;
  }
  .FlpSwap .totalSupply {
    width: 50%;
    /* margin-bottom: 1rem; */
    margin-top: 1.5rem;
    /* align-self: flex-end; */
  }
}

.plainChart .Home {
  padding:0;
  min-height: 3rem;
}

.FlpSwap .Tab-title-section {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.FlpSwap-token-list .App-card-divider {
  margin-bottom: 2rem;
}

.FlpSwap-box.App-box .align-right.clickable {
  align-items: center;
}

.FlpSwap-content .Exchange-swap-option-tabs.Tab.block .Tab-option{
  padding: 1.05rem;
}

.FlpSwap-content .App-card-title{
  margin-bottom: 0;
}

.FlpSwap-content .App-card.FlpSwap-stats-card .App-card-row .value .Tooltip-handle,
.FlpSwap .App-card-row > div:last-child,
.FlpSwap-content .App-card.FlpSwap-stats-card .App-card-row .value{
  font-family: sans-serif;
}


.Flp-box-container .Exchange-swap-section .TokenSelector-tokens{
  min-width: 240px;
}
