.launch-card-container {
  margin: 2rem auto;
  border-radius: 1.5rem;
  border: 1.5px solid #4c4c4c;
  background-color: hsl(260, 4%, 14%);
}
.launch-card-container .title {
  width: 100%;
  background: #4e4d50;
  font-size: 1.6rem;
  padding: 1.5rem 1.5rem;
  border-top-left-radius: 1.3rem;
  border-top-right-radius: 1.3rem;
  color: #acacac;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.launch-card-container .title .expand-icon {
  color: #ffffff;
}
.launch-card-container .launch-card-overview {
  padding: 2.5rem 1.5rem;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem;
  line-height: 2.2rem;
}
.launch-card-container .launch-card-overview .overview-left {
  flex-basis: 70%;
}
.launch-card-container .launch-card-overview .overview-left p,
a {
  padding: 0.8rem 0;
  margin: 0;
  color: #acacac;
  font-size: 1.4rem;
  font-weight: 700;
}

.launch-card-container .launch-card-overview .overview-left a {
  margin-top: 1rem;
}
.launch-card-container .launch-card-overview .overview-right {
  margin: 0.1rem;
  padding: 1rem;
  background: rgba(79, 78, 78, 1);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 1.5rem;
  flex-basis: 22%;
}
.launch-card-container .launch-card-overview .overview-right .overview-right-content {
  font-size: 1.3rem;
}
.launch-card-container .launch-card-overview .overview-right .overview-right-subheading {
  font-size: 90%;
  font-weight: 700;
}
.launch-card-container .expanded-view-container {
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 2.5rem;
  border: 1.5px solid #4c4c4c;
  margin: 2rem;
  border-radius: 1.5rem;
  color: #ffffff;
  line-height: 2.2rem;
}
.launch-card-container .expanded-view-container .expanded-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 1rem 1rem 1rem;
}
.launch-card-container .expanded-view-container .expanded-desc {
  font-size: 1.4rem;
  margin: 1.5rem 1rem;
}
.launch-card-container .expanded-view-container .action-card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2%;
  margin: 4rem 1rem 1rem 1rem;
}

.expanded-bg {
  background-color: #fffff2 !important;
  color: #141414 !important;
}
.expanded-border {
  border: 1.5px solid #fffff2 !important;
}

.clickable {
  cursor: pointer;
}
.info-red {
  font-size: 1rem;
  margin: 0 1rem;
  color: #f93333;
}
.blur-it {
  filter: blur(3px);
}

@media (max-width: 1000px) {
  .launch-card-container .launch-card-overview .overview-right {
    display: none;
  }
  .launch-card-container .launch-card-overview .overview-left {
    flex-basis: 100%;
  }
  .launch-card-container .launch-card-overview .overview-left .overview-left-desc2 {
    display: none;
  }
  .launch-card-container .expanded-view-container .action-card-container {
    display: flex;
    flex-direction: column;
    margin: 4rem 1rem 1rem 1rem;
  }
}
