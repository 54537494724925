.order-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}
.order-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--dark-blue-hover);
  border-radius: var(--border-radius-sm);
  margin-bottom: 0.5rem;
  padding: 1rem;
}
.order-list li p {
  margin: 0;
}
.order-list li button {
  font-size: 1.4rem;
  background: none;
  border: none;
  color: white;
  opacity: 0.8;
}
.order-list li button:hover {
  opacity: 1;
}

.view-orders {
  text-decoration: underline;
  cursor: pointer;
}

.Exchange-swap-box .Confirmation-box-main-icon:after{
  content: none;
}

.Exchange .create-order-info{
  display: flex;
  justify-content: space-between;
  flex-wrap:  wrap;
}
.Exchange .create-order-info .Confirmation-box-main{
  flex-basis: 100%;
}
.Exchange .create-order-info .Exchange-info-row{
  flex-basis: 45%;
}
.Exchange .Confirmation-box .divider{
  /* display: none; */
}

.Exchange .Orders-toa .Modal-title-bar,
.Exchange-list-modal.order-editor .Modal-title-bar,
.Exchange .Confirmation-box  .Modal-title-bar{
  flex-wrap: wrap;
}

.Exchange .Orders-toa .Modal-close-button,
.Exchange-list-modal.order-editor  .Modal-close-button,
.Exchange .Confirmation-box .Modal-close-button{
  flex-basis: 10%;
}

.Exchange .Orders-toa .Modal-title,
.Exchange-list-modal.order-editor .Modal-title,
.Exchange .Confirmation-box .Modal-title{
  flex-basis: 90%;
  text-align: center;
  padding-left: 10%;
}

.Exchange-list-modal.order-editor .Modal-body,
.Exchange .Confirmation-box .Modal-body{
  margin-top: 0;
}

.Exchange-list-modal.order-editor .Modal-content .Confirmation-box-row,
.Exchange .Confirmation-box .Modal-content .Confirmation-box-row{
  margin-top: 0;
}