.launchcard-mbl-container {
  display: flex;
  flex-direction: column;
  background-color: #4e4d50;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 1.5rem;
}
.launchcard-mbl-container .launchcard-mbl-title {
  font-size: 1.9rem;
  font-weight: 900;
  margin-bottom: 0;
}
.launchcard-mbl-container .launchcard-mbl-desc {
  font-size: 1.3rem;
  padding: 0 2rem;
  color: #d7d7d7;
  text-align: left;
}
