.Footer-wrapper {
  text-align: center;
  padding-top: 4rem;
  background: #222124;
  width: 100vw;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  height: 35rem;
  bottom: 0;
  border-top: 1px solid #272a2f;
  display: flex;
  justify-content: space-around;
  font-family: "Inter";
  margin-top: 3rem;
  flex-wrap: wrap;
  padding-right: 15%;
  padding-left: 5%;
}

.CompleteAccountTransfer .Footer-wrapper .Footer-logo {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer-logo img {
  height: 4rem;
  width: 9rem;
}

.Footer-social-link-block {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
}
.Footer-link {
  color: #a0a3c4;
  font-size: 1.5rem;
  line-height: 1.85rem;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
}
.Footer-link:hover {
  color: white;
}
.Footer-social-link-block .App-social-link {
  margin: 0 1.2rem;
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  align-items: center;
  justify-content: center;
}

.Footer-social-link-block .App-social-link:hover img {
  filter: brightness(0) invert(1);
}

.Footer-copyright {
  padding: 1.6rem;
}

.Footer-copyright__text {
  font-family: Circular Std;
  font-size: 1.3rem;
  line-height: 1.3rem;
  letter-spacing: -0.41px;
  color: #a0a3c4;
  display: block;
}
.Footer-links {
  padding-bottom: 4rem;
  display: flex;
  justify-content: center;
}
.Footer-links > a:not(:last-child),
.Footer-links > .a:not(:last-child) {
  padding-right: 2rem;
}

@media (max-width: 900px) {
  .Footer-social-link-block .App-social-link {
    margin: 0 1.6rem;
  }
}

@media (max-width: 580px) {
  .Footer-links {
    flex-direction: column;
  }
  .Footer-links > a {
    padding-right: 0;
    margin-bottom: 0.5rem;
  }
  .Footer-social-link-block {
    margin-bottom: -8rem;
  }
  .Footer-wrapper {
    padding-top: 2.5rem;
    height: 100rem;
  }
  .home {
    height: 23rem;
  }
  .Footer-links > a:not(:last-child),
  .Footer-links > .a:not(:last-child) {
    padding-right: 0;
  }
  .Footer-social-link-block .App-social-link {
    margin: 0 0.8rem;
  }

  .footer-links-container {
    /* flex-wrap: wrap; */
    flex-direction: column;
  }

  .footer-nav {
    /* padding: 0rem 5rem; */
  }

  .footer-nav h3 {
    margin-top: 0;
  }
}

.footer-nav ul {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-nav h3 {
  display: flex;
  justify-content: flex-start;
  font-family: "Inter";
}

.footer-nav ul li {
  margin: 0.75rem 0;
  list-style-type: none;
  padding-bottom: 0.5rem;
  text-align: start;
}

.footer-nav ul li a {
  color: rgba(255, 255, 255, 0.4);
  font-size: 1.35rem;
  text-decoration: none;
}

.footer-links-container {
  display: flex;
  justify-content: space-around;
  flex-basis: 70%;
}

.footer-logo-container {
  flex-basis: 30%;
}

.Footer-logo {
  margin-bottom: 2rem;
}

.Exchange.page-layout .Footer-wrapper {
  background: none;
  border: 0;
}

@media (min-width: 1100px) {
  .Footer-wrapper .footer-links-container {
    display: flex;
    justify-content: space-between;
    flex-basis: 70%;
    /* max-width: 180rem; */
    /* padding-right: 40rem; */
    padding: 0 5rem;
  }
}
