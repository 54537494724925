.subscription-email-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 5rem;
  padding: 0.7rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  max-width: 32rem;
}

.subscription-email-wrapper.outlined {
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.subscription-email-wrapper input {
  flex: 1;
  line-height: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  padding-right: 0.7rem;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.subscription-email-wrapper input::placeholder {
  font-size: 1.2rem;
}

.subscription-email-wrapper .submit-btn {
  color: black;
  height: 3rem;
  margin-top: 0.2rem;
  background-color: #e2e2e3;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  border: none;
}

@media (max-width: 900px) {
  .subscription-email-wrapper .submit-btn {
    color: black;
    border-radius: 10px;
    padding: 0.5rem 1.5rem;
    font-weight: 600;
    border: none;
  }
}

@media (max-width: 580px) {
  .subscription-email-wrapper {
    margin-top: 10rem;
  }
  .subscription-email-wrapper .submit-btn {
    color: black;
    padding: 0rem 0.5rem;
    font-weight: 500;
  }
}
