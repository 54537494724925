.leaderboard-contains {
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: black;
}

.leaderboard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.leaderboard h1 {
  color: white;
  font-size: xx-large;
  font-weight: 700 !important;
  margin-bottom: 1rem;
}

.leaderboard p {
  color: grey;
  font-size: medium;
  font-weight: 100 !important;
  margin-top: 0;
}

.mobilesearch {
  margin-bottom: 1.2rem;
  border: 0.125rem solid;
  border-color: grey;
  border-radius: 0.5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  padding: 0.2rem 0.6rem;
}

.searchicon {
  padding-left: 0.8rem;
  size: 4rem;
  padding-top: 0.4rem;
}
.searchicon ::placeholder {
  padding-top: 0.4rem;
}
.mobilesearch input {
  outline: none;
  min-height: 2rem;
  font-size: 1.6rem;
  line-height: 1.23rem;
  width: 100%;
  background-color: transparent;
  color: #faf9f5;
  border: none;
  padding: 0.8rem 0.5rem;
}

.mob-container {
  position: relative;
  width: 100%;
  align-items: center;
  column-gap: 0.75rem;
}
.mob-box {
  border-radius: 0.5rem;
  margin-bottom: 1.25rem;
  display: flex;
  gap: 5rem !important;
  justify-content: flex-end;
}

.mobile-box {
  width: 100%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  font-size: 0.75rem;
}

.box-header {
  display: flex;
  flex-direction: row;
  padding: 0.2rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  box-sizing: border-box;
  background-color: inherit;
  border-radius: 0.5rem !important;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  padding-right: 0;
  padding: 0.5rem 0;
}

.box-head {
  text-align: left;
  width: 100%;
}
.rank {
  /* line-height: 1rem; */
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
}
.address {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: right;
  text-align: end;
  font-size: 1.4rem;
}
.address * {
  padding: 0;
  margin: 0;
}
.address p {
  font-size: medium;
  color: rgb(130, 132, 134);
  font-weight: bold;
}
.black-text {
  color: black !important;
}

.address button {
  margin: 0 0.5rem;
  margin-top: 0.3rem;
  margin-left: 0.3rem;
}
.mob-data {
  min-height: 8rem;
  overflow: hidden;
  padding-left: 1.4rem;
}
.line {
  background-color: rgb(118, 118, 128);
  height: 0.075rem;
  border: 0;
  color: inherit;
  margin-top: 0;
}
.box {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
}
.box-data {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.data {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.2rem;
}
.data-c {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.m-heads {
  display: flex;
  width: 50%;
  color: grey;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dyn-data {
  align-items: end !important;
  width: 50%;
  color: rgb(250, 246, 246);
  text-align: right;
}
.top-rank-header {
  /* color: linear-gradient(to left, #FF9A02, #FED404) */
  background: -webkit-linear-gradient(#ff9a02, #fed404);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.black-button {
  background-color: inherit;
  color: white;
  padding-left: 0;
}
.address .white-button {
  background-color: inherit;
}
.address .white-button img {
  width: 1rem;
}
.red-text {
  color: red;
}
.green-text {
  color: rgb(15, 231, 15);
}

.nav-page {
  display: flex;
}
.nav-page img {
  vertical-align: middle;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.nav-page button {
  padding: 0.125rem 0.25rem;
  background-color: inherit;
  color: white;
  border: none;
  cursor: pointer;
}

.nav-page button[disabled] {
  background-color: inherit;
  cursor: not-allowed;
}
.previous {
  size: medium;
  color: rgb(241, 226, 226);
  padding-left: 0.3rem !important;
}

.next {
  color: white;
}
.previousbutton {
  margin-right: 1rem;
}
.rank img {
  margin-left: 0.5rem;
  vertical-align: middle;
  padding-bottom: 0.2rem;
}

.rank * {
  padding: 0;
  margin: 0;
}
.pnl-address {
  margin: 0;
}

@media (max-width: 768px) {
  .mobilesearch input {
    font-size: 1.3rem;
  }
  .address p {
    font-size: 1rem;
    color: white !important;
    font-weight: 500 !important;
  }
  .box-header {
    font-size: 2rem !important;
  }
  .leaderboard-contains {
    padding: 0;
    margin: 0;
  }
  .m-heads {
    padding-left: 0.3rem;
  }
  .dyn-data {
    padding-right: 0.3rem;
  }
}
