.Referrals {
  padding-top: 4.65rem;
}

.mt-large {
  margin-top: 3.1rem;
}
.mt-medium {
  margin-top: 1.5rem;
}
.referral-tab-container {
  display: flex;
  justify-content: flex-start;
}
.referral-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.referral-card .title {
  font-weight: 500;
  font-size: var(--font-lg);
  line-height: 3.1rem;
  color: #ffffff;
  padding: 0.775rem 0;
  margin: 0;
}
.referral-card .sub-title {
  font-size: 1.5rem;
  line-height: 2.5rem;
  letter-spacing: 0px;
  color: #929292;
  margin-top: 1.1625rem;
}
.referral-card .card-action {
  margin-top: 1.1rem;
}

.referral-card .card-action input {
  background: #363636;
  border-radius: 13.8404px;
  border: none;
  font-size: var(--font-base);
  width: 100%;
  box-sizing: border-box;
  margin-right: 1.5rem;
}
.Referrals button:disabled,
.Referrals a.App-cta,
.Referrals button.App-cta .Referrals button.App-cta:disabled {
  background: #e2e2e3;
  border-radius: 13.8404px;
  border: none;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 15px;

  color: #121212;
}
.referral-body-container .card-list {
  margin-top: 3rem;
}
.referral-body-container .card-list .App-card {
  margin-bottom: 1rem;
}
.reward-history {
  margin-top: 2rem;
}
.active-referral-code .edit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.active-referral-code .edit span {
  margin-right: 1.5rem;
}
.active-referral-code .edit svg {
  cursor: pointer;
}
.active-referral-code .edit svg:hover {
  opacity: 0.75;
}
.active-referral-code .tier {
  margin-top: 0.775rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.active-referral-code .tier span {
  font-size: var(--font-sm);
  color: #a9a9b0;
  margin-right: 0.775rem;
}
.active-referral-code .tier a {
  text-decoration: none;
  font-size: 0;
}
.mb-sm {
  margin-bottom: 1.5rem;
}
.edit-referral-modal {
  width: 31rem;
}
.referral-code-edit span {
  margin-right: 0.775rem;
}
.referral-code-edit svg {
  opacity: 0.75;
  cursor: pointer;
}

.referral-code-edit svg:hover {
  opacity: 1;
}
.create-referrar-code {
  text-align: center;
  max-width: 50rem;
  margin: 3.1rem auto;
}
.transparent-btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  background: transparent;
  padding: 0.75rem 1.25rem;
  color: white;
  font-size: var(--font-sm);
  border: 1px solid var(--dark-blue-border);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}

.transparent-btn:hover {
  background: var(--dark-blue-hover);
}

.transparent-btn:active {
  background: var(--dark-blue-active);
}

.referral-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.referral-table-header .title {
  margin: 0;
  padding-left: 0.625rem;
}
.referral-table-header .sub-title {
  color: #a9a9b0;
  font-size: var(--font-sm);
  margin-left: 0.775rem;
}

.referral-table-header .ml-small {
  margin-left: 0.775rem;
}
.create-referrar-code form {
  display: flex;
  align-items: inherit;
  justify-content: center;
  margin: 3.1rem 0;
}
.create-referrar-code form input {
  border: 1px solid #1e2136;
  border-radius: 0.4rem;
  padding: 1.1625rem;
  font-size: var(--font-base);
  width: 40rem;
}
.create-referrar-code form button {
  margin-left: 1.5rem;
}

.referral-stats {
  display: flex;
  justify-content: space-around;
  background: #222124;
  border: 1.38404px solid #444444;
  border-radius: 1rem;
  margin-top: 2rem;
}

.text-center {
  text-align: center;
}

.info-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-card .label {
  font-size: var(--font-md);
  line-height: 1.5rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-align: center;
}
.info-card .data {
  font-size: var(--font-lg);
  font-weight: normal;
  text-align: center;
  padding: 0;
  margin: 1.5rem 0;
}
.info-card .info-card-question-icon {
  vertical-align: middle;
  font-size: var(--font-sm);
  opacity: 0.8;
}

.table-referral-code {
  align-items: center;
  display: flex;
}

.table-referral-code .referral-text {
  margin-right: 0.775rem;
  font-family: monospace;
}

.table-referral-code .referral-code-icon {
  margin-right: 1rem;
  cursor: pointer;
  display: inline-flex;
}

.table-referral-code .referral-code-icon:hover {
  opacity: 0.75;
}

.table-referral-code .info {
  margin-left: 0.775rem;
}

.referral-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  font-size: var(--font-base);
}
.referral-table th {
  font-weight: normal;
  letter-spacing: 0.25px;
  color: #a9a9b0;
  text-transform: uppercase;
}
.referral-table th,
.referral-table td {
  padding: 0.925em;
  text-align: left;
}

.table-referral-code p {
  margin: 0;
  margin-left: 0.775rem;
  padding: 0;
}
.error {
  margin: 0;
  padding: 0.775rem 0 1.5rem;
  color: #fa3c58;
  font-size: var(--font-sm);
}

.table-wrapper {
  display: block;
  width: 100%;
  font-size: var(--font-base);
}

.empty-message {
  margin: 4.65rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background: #222124;
  border: 1.38404px solid #444444;
  border-radius: 1rem;
  font-size: var(--font-base);
}

.rebate-container .empty-message {
  margin: 2rem 0;
}
.confirm-checkbox .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 1.5rem;
}

.referral-tab-container .Tab.block {
  background: none;
}
.referral-tab-container .Tab.block .Tab-option.active {
  background: none;
  border-bottom: 3px solid white;
}
.referral-tab-container .Tab.block .Tab-option {
  margin-right: 2rem;
  font-size: 2rem;
}
.referral-tab-container .Tab.block .Tab-option:nth-child(1) {
  padding-left: 0;
}

.referral-tab-container .Tab.block .Tab-option:hover {
  background: none;
  opacity: 0.8;
}

.referral-card .headerTitle {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  justify-content: center;
}
.headerTitle-gs {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  justify-content: center;
  margin-bottom: 2rem;
}
.headerTitle-gs .title-gs {
  font-weight: 500;
  font-size: var(--font-lg);
  line-height: 3.1rem;
  color: #ffffff;
  padding: 0.775rem 0;
  margin: 1rem 0;
}
.headerTitle-gs p {
  margin: 0.5rem 0;
}
.referral-form-container {
  padding: 2rem;
  background: #222124;
  border: 1.38404px solid #444444;
  border-radius: 1.5rem;
  width: 40%;
  margin-right: 0%;
}

.header-container {
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
}

.referral-info-container .tiers-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 2rem;
}
.referral-info-container .tier {
  background: #222124;
  border: 1.38404px solid #444444;
  border-radius: 1.5rem;
  width: 30%;
  padding: 0 1.5rem;
}

.referral-info-container .contact-info,
.referral-info-container .tier-info {
  background: #222124;
  border: 1.38404px solid #444444;
  border-radius: 1.5rem;
  margin-top: 2rem;
  padding: 1rem 1.5rem;
}
.referral-info-container .Tooltip-handle p {
  margin: 0;
}

.Referrals .Page-title {
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 0;
}

.rebate-container {
  margin-top: 2rem;
}

.referral-form-container .btn.App-cta.Exchange-swap-button {
  display: flex;
  justify-content: center;
}

.referral-form-container .title {
  margin-top: 0.5rem;
}

.tiers-container .tier p {
  display: flex;
}

.Referrals .empty-message {
  padding: 0 1rem;
  margin-top: 2rem;
}

.Referrals .info-card .label,
.Referrals .info-card .data {
  text-align: left;
}

.referral-table thead {
  background: #222124;
}
.referral-table span.Tooltip-handle {
  width: 7rem;
}

.referral-table .Tooltip-handle {
  justify-content: flex-start;
}

.Referrals .info-card .card-details {
  width: 20rem;
}

.Referrals .referral-stats .card-details .Tooltip-handle {
  justify-content: flex-start;
}

.table-wrapper.small {
  display: none;
}

.Referrals .section-link-container {
  display: none;
}
.Referrals .section-link-container a:last-child {
  margin: 0;
  width: 12rem;
}
.Referrals .section-link-container a:first-child {
  border-bottom: 3px solid white;
}
.section-link-container a {
  padding-bottom: 0.5rem;
  display: block;
  color: #e2e2e3;
  text-decoration: none;
  margin-right: 2rem;
  font-size: 1.75rem;
}

.App-card-divider.section-link {
  display: none;
}

.referral-list-container .header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background: #222124;
  border: 1.38404px solid #444444;
  border-radius: 13.8404px;
}

.referral-list-container .body .table-head,
.referral-list-container .header .table-head {
  width: 15rem;
  padding: 0.05rem 0.25rem;
}

.referral-list-container .body {
  background: #222124;
  border: 1.38404px solid #444444;
  border-radius: 13.8404px;
  margin-top: 1.5rem;
}
.referral-list-container .body .row {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

.referral-list-container .Tooltip-handle {
  justify-content: start;
}

.referral-list-container.large .body .row p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.referral-list-container.large .body .row p:nth-child(2) {
  margin-bottom: 0rem;
}

.Referrals .Tooltip .Tooltip-row {
  font-size: 1.4rem;
  /* margin-bottom: 0rem !important;
  padding-bottom: 0rem !important; */
}

.Referrals .Tooltip-row span.label {
  font-size: 1.4rem;
  text-align: left;
  margin-bottom: 0.2rem;
}

.Referrals .Page-subtitle {
  font-size: 2rem;
  margin-bottom: 0;
  margin-top: 4rem;
}
@media (max-width: 600px) {
  .referral-form-container {
    max-width: 100% !important;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 800px) {
  .referral-table {
    width: auto;
    margin-bottom: 1.5rem;
  }
  .table-wrapper {
    overflow-x: auto;
  }
  .table-head {
    white-space: nowrap;
  }

  .header-container {
    display: flex;
    flex-direction: column;
  }

  .Referrals .referral-form-container {
    margin-left: 0;
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-right: 10%; */
    max-width: 60%;
    margin: 2rem auto;
  }

  .Referrals a.App-cta,
  .Referrals button.App-cta {
    width: 25rem;
  }

  .Referrals .width-auto {
    width: 100% !important;
  }

  .headerTitle-gs {
    margin: 0 5%;
  }
  .referral-stats {
    display: flex;
    flex-direction: column;
    border: none;
    background: none;
  }

  .referral-stats .info-card {
    background: #222124;
    border: 1.38404px solid #444444;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
  }

  .referral-info-container .tiers-container {
    display: flex;
    flex-direction: column;
  }

  .referral-info-container .tier {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .referral-card {
    flex-direction: column;
  }

  .Referrals .referral-stats .info-card .card-details .Tooltip-handle {
    justify-content: center;
  }
  .Referrals .info-card .card-details .label,
  .Referrals .info-card .card-details .data {
    text-align: center;
  }

  .card-details .active-referral-code .tier,
  .card-details .active-referral-code .edit {
    justify-content: center;
  }

  .referral-list-container.large {
    display: none;
  }

  .Referrals .table-wrapper.small {
    display: block;
  }
  .table-wrapper.small .referral-table {
    width: 100%;
  }
  .referral-body-container .table-wrapper.small .App-card-row {
    display: flex;
    justify-content: space-between;
  }
  .referral-body-container .table-wrapper.small .App-card-row .Tooltip-handle {
    justify-content: flex-end;
  }

  .Referrals.page-layout .App-card-divider.section-link,
  .Referrals.page-layout .section-link-container {
    display: block;
  }

  .Referrals.page-layout .section-link-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .referral-table-header .sub-title {
    display: block;
    margin-left: 0;
    margin-top: 2px;
  }

  .Referrals .referral-stats .info-card {
    border: 0;
    border-radius: 0;
    background: none;
  }

  .Referrals .referral-stats {
    background: #222124;
    border: 1.38404px solid #444444;
    padding: 1rem 1.5rem;
  }

  .Referrals .info-card {
    justify-content: flex-start;
  }

  .Referrals .card-details .active-referral-code .tier,
  .Referrals .card-details .active-referral-code .edit,
  .Referrals.page-layout .referral-stats .info-card .card-details .Tooltip-handle {
    justify-content: flex-start;
  }

  .Referrals .referral-stats .info-card .card-details .label,
  .Referrals .referral-stats .info-card .card-details .data {
    text-align: left;
  }

  .Referrals .referral-stats .info-card .card-details .data {
    margin-bottom: 0;
  }
}
