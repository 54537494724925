.main-timer-cnt {
  text-align: center;
}
.main-timer-cnt p {
  padding: 0;
  margin: 0;
}
.main-timer {
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.main-timer p {
  margin: 0 0.3rem;
}
