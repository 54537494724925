.desc-points-cnt {
  margin: 2rem 1%;
}
.desc-points-cnt li {
  padding: 0.1rem;
}
.expanded-stat-cnt {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 7%;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
}
.expanded-stat-item-cnt {
  border: 1px solid #4c4c4c;
  padding: 0.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stat-item-title {
  font-size: 1.2rem;
  margin: 0.3rem;
}
.stat-item-value {
  font-size: 2rem;
  margin: 0.3rem;
}
.expaned-main-cnt {
  display: grid;
  grid-template-columns: 60% 40%;
}
.progress-stats {
  margin: 1rem 0;
  padding: 1.2rem;
  border: 1px solid #4c4c4c;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.tge-timer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem 0.2rem;
}
.time-left-cnt {
  display: flex;
  font-size: 2rem;
}
.time-left-cnt p {
  margin: 0.3rem;
}
.time-left-title {
  margin: 0.3rem;
}
.buy-fxdx {
  border: 1px solid #4c4c4c;
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1.2rem;
  margin-left: 1rem;
}
.buy-fxdx p,
.buy-fxdx h4 {
  margin: 0.2rem 0;
}
.buy-fxdx-title {
  font-size: 1.9rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.fxdx-input-cnt {
  width: 100%;
  background-color: #363636;
  margin: 0.5rem 0;
  border-radius: 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fxdx-input-cnt input {
  width: 100%;
}
.fxdx-subtitle {
  color: #c9c9cd;
}
.fxdx-wallet-btn {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  font-size: 1.5rem;
  border: 1px solid #4c4c4c;
  padding: 1rem;
  border-radius: 1rem;
}
.fxdx-wallet-btn:disabled {
  background-color: #ccc;
  color: #333;
}

.fxdx-token-label {
  padding: 1rem;
  font-size: 1.3rem;
  border-left: 1px solid #4c4c4c;
  width: 30%;
}
.label-buy {
  padding: 0rem;
  background-color: #363636 !important;
}
.label-buy {
  background-color: #363636 !important;
}
.drop-down {
  color: white !important;
}
.drop-down * {
  background-color: #363636 !important;
  border: none !important;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.disabled {
  cursor: no-drop;
  pointer-events: none;
}
.stats-title {
  font-size: 1.7rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 2rem;
}
.progress-value {
  justify-content: space-between;
}
.progress-value p {
  margin: 0;
}
.progress-cnt {
  margin: 1rem 0;
}
.stats-cnt {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 4rem;
}
.stats-cnt-right p {
  font-size: 1.5rem;
}
.margin-min {
  margin: 0.5rem 0 !important;
}
.font-bold {
  font-weight: 800;
}
.css-26l3qy-menu {
  background-color: #363636 !important;
}
.btn-disabled {
  background-color: #b4afaf !important;
  cursor: no-drop;
}
.checkbox-cnt {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.checkbox-cnt input {
  margin: 0rem 1rem;
  width: 1.5rem;
}
.text-small {
  font-size: 1.7rem;
  color: white;
}
@media (max-width: 1000px) {
  .expaned-main-cnt {
    display: flex;
    flex-direction: column;
  }
  .fxdx-token-label {
    width: 14rem;
  }
  .expanded-stat-cnt {
    display: flex;
    flex-direction: column;
  }
  .expanded-stat-item-cnt {
    width: 100%;
    margin: 0.5rem;
  }
  .buy-fxdx {
    margin-left: 0rem;
  }
}
