.actioncard-container {
  border: 1.5px solid #4c4c4c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 1.5rem;
  text-align: center;
}
.actioncard-container .actioncard-icon {
  width: 20%;
  height: 20%;
  margin: 1rem 0;
}
.actioncard-container .actioncard-title {
  font-size: 1.5rem;
  margin: 1rem 0;
}
.actioncard-container .actioncard-desc {
  flex-basis: 20%;
  font-size: 1.1rem;
  margin: 1rem 0;
}
.actioncard-container .actioncard-btn {
  background-color: #ffffff;
  width: 65%;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: black;
}
.actioncard-container .actioncard-btn-wrapper {
  width: 100%;
  height: max-content;
}
@media (max-width: 1000px) {
  .actioncard-container {
    margin: 1rem 0;
  }
  .actioncard-container .actioncard-icon {
    width: 20%;
    height: 20%;
    margin: 1rem 0;
    max-width: 5rem;
    max-height: 5rem;
  }
}
