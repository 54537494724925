.share-btn {
  border-radius: 0.5rem;
  border: none;
  background-color: #ffffff;
}
.share-btn .share-btn-cnt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0.2rem;
}
.share-btn .share-btn-cnt * {
  margin: 0.1rem;
}
.App-card-title .share-btn {
  border-radius: 0.5rem !important;
  border: none !important;
  background-color: #ffffff !important;
  color: #121212 !important;
}
