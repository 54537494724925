@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');

.Exchange {
  max-width: 100%;
  margin: auto;
  justify-content: space-between;
}

.Exchange-trigger-order-info,
.Exchange-trigger-order-info a {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid #23263b;
}

.Exchange-list-tab-container {
  display: grid;
  grid-template-columns: auto auto;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1.05rem;
}

.Exchange-should-show-position-lines {
  font-size: var(--font-sm);
}
.Exchange-should-show-position-lines .active {
  opacity: 1;
}

.Exchange-empty-positions-list-note {
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-size: 1.25rem;
}

.position-loading-icon {
  display: inline-block;
  font-size: 1.25rem;
  margin-left: 0.8rem;
  color: #3749e6;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-settings-row {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
}

.toggle-leverage{
  margin-left: 2rem;
}
.Exchange-should-show-position-lines .toggle-leverage {
  margin-left: 0;
}

.Exchange-settings .App-cta {
  margin-top: 1.5rem;
}

.Exchange-content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0;
  padding: 0;
  padding-top: 0rem;
  margin-top: -1.5rem;
  /* display: flex;
  flex-direction: row; */
  border-top: 1px solid #444444;
  border-bottom: 1px solid #444444;
  background: #222124;
}

.Exchange-position-list-orders {
  white-space: nowrap;
}

.Exchange-lists.large {
  display: block;
}

.Exchange-lists.small {
  display: none;
}

.Exchange-right {
  display: grid;
  grid-template-rows: auto 1fr;
  background: #222124;
}

.Exchange-leverage-slider-settings {
  font-size: var(--font-sm);
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
}

.Exchange-leverage-slider-settings .Checkbox {
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
}

.Exchange-wallet-tokens {
  position: relative;
  min-height: 15.5rem;
  display: none;
}

.Exchange-wallet-tokens-content {
  position: absolute;
  top: 1.5rem;
  bottom: 0;
  left: 0;
  right: 0;
}

.Exchange-bottom-header-item {
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 0.155rem;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-bottom-header-item.active {
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-connect-wallet {
  text-align: center;
  cursor: pointer;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 3px;
}

.Exchange-swap-connect-wallet:hover {
  background: #272e33;
  border-radius: 0.6rem;
}

.Exchange-swap-settings.Exchange-swap-txns-status {
  text-align: right;
  margin-right: 1.5rem;
}

.Exchange-swap-account .Exchange-swap-address:hover,
.Exchange-swap-account .Exchange-swap-txns-status:hover {
  opacity: 1;
}

.Exchange-leverage-slider {
  margin-top: 1.5rem;
  margin-bottom: 3.4rem;
  padding: 0 1.6rem 0 0;
}

.Exchange-leverage-slider .muted {
  color: #E2E2E3;
  opacity: 0.5;
  font-size: 1.25rem;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.Exchange-leverage-slider-settings{
  align-items: flex-start;
  padding-bottom: 1rem;
}
.Exchange-leverage-slider-settings span{
  display: flex;
  align-items: flex-start;
  font-size: 1.25rem;
  /* justify-content: space-between; */
  /* flex-basis: 55%; */
}
.Exchange-leverage-slider-settings span .muted{
  margin-left: 2.5rem;
  padding: 0.5rem 0.75rem;
  border: 2px solid #a7f6b3;
  border-radius: 1rem;
  box-shadow: 0 0 0.4rem 0.6rem #91e49d3e;
  color: #E2E2E3;
  /* width: 34%; */
  opacity: 1;
  font-size: 1.15rem;
}
.Exchange-swap-section-top.price .muted{
  font-size: 1.25rem;
}
.Exchange-swap-leverage-options {
  text-align: right;
}

.Exchange-swap-leverage-option {
  display: inline-block;
  margin-left: 0.8rem;
  padding: 0 0.8rem;
  border-radius: 3px;
  background: #5b2ab0;
  color: white;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-swap-leverage-option.active {
  background: #842ec9;
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-leverage-option:hover {
  opacity: 1;
}

.Exchange-swap-leverage-unlock-icon {
  font-size: 0.8rem;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-swap-placeholder {
  height: 11.175rem;
  position: relative;
}

.Exchange-wave {
  z-index: 1;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 1.5rem;
  bottom: 1.5rem;
  mask-image: url(img/wave-01.png);
  mask-size: 90% 90%;
  mask-repeat: no-repeat;
  mask-position: center;
  filter: blur(5rem);
  background: linear-gradient(
    40deg,
    rgba(96, 4, 189, 1) 0%,
    rgba(65, 105, 224, 1) 22%,
    rgba(99, 8, 191, 1) 23%,
    rgba(51, 9, 115, 1) 37%,
    rgba(255, 36, 145, 1) 38%,
    rgba(201, 16, 161, 1) 50%,
    rgba(171, 10, 163, 1) 59%,
    rgba(117, 4, 128, 1) 67%,
    rgba(96, 29, 196, 1) 68%,
    rgba(92, 55, 204, 1) 84%,
    rgba(84, 88, 214, 1) 100%
  );
  pointer-events: none;
}

.PositionEditor-info-box {
  margin-bottom: 1.05rem;
}

.PositionEditor-keep-leverage-settings .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.PositionEditor-accept-profit-warning .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.PositionEditor-allow-higher-slippage .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.ExchangeChart-range-option {
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-sm);
  cursor: pointer;
  opacity: 0.6;
}

.ExchangeChart-range-option:hover {
  opacity: 0.8;
}

.ExchangeChart-range-option.active {
  opacity: 1;
}

.ExchangeChart {
  position: relative;
  height: 52.2rem;
  display: flex;
  flex-direction: column;
}

.ExchangeChart-main-price {
  font-size: 1.75rem;
}

.ExchangeChart-title {
  font-size: 2.15rem;
  font-weight: bold;
}

.ExchangeChart-info-label {
  font-size: 1rem;
  opacity: 0.6;
}

.ExchangeChart-top-inner {
  /* display: grid;
  grid-template-columns: auto auto auto auto auto 1fr;
  grid-column-gap: 4.65rem;
  align-items: center;
  padding-left: 0.3rem; */
  display: flex;
  /* justify-content: space-between; */
  grid-column-gap: 0;
  flex-wrap: wrap;
  max-width: 110rem;

}

.ExchangeChart-dollar-sign {
  display: inline-block;
  margin-right: 0.465rem;
  opacity: 0.7;
}

.ExchangeChart-main-price-text {
  display: inline-block;
}

.ExchangeChart.tv .ExchangeChart-top {
  position: relative;
  padding: 1.5rem 2rem;
  z-index: 2;
  border-radius: 0;
  border-bottom: 1px solid #444444;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.ExchangeChart.tv .ExchangeChart-bottom {
  position: relative;
  flex: 1;
  width: 100%;
  z-index: 1;
  border-radius: 0;
  border: 0;
}

.ExchangeChart-bottom-controls {
  display: inline-block;
}
.ExchangeChart-bottom-controls .Tab .Tab-option {
  width: 4.65rem;
  padding: 0.8rem 0;
}
.ExchangeChart.tv .ExchangeChart-bottom-header {
  position: absolute;
  display: flex;
  top: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 5;
}
.ExchangeChart.tv .ExchangeChart-bottom-stats {
  font-size: var(--font-sm);
  padding: 0.8rem 0;
  margin-left: 3.1rem;
  white-space: nowrap;
  z-index: 2;
}
.ExchangeChart-bottom-stats-label {
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}
.ExchangeChart-bottom-stats-value {
  display: inline-block;
  margin-left: 0.31rem;
  margin-right: 0.8rem;
}
.length-5 .ExchangeChart-bottom-stats-value {
  width: 6.2rem;
}
.length-4 .ExchangeChart-bottom-stats-value {
  width: 5.25rem;
}
.length-3 .ExchangeChart-bottom-stats-value {
  width: 4.35rem;
}
.length-2 .ExchangeChart-bottom-stats-value {
  width: 3.41rem;
}
.length-1 .ExchangeChart-bottom-stats-value {
  width: 2.48rem;
}

.ExchangeChart.tv .ExchangeChart-bottom-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.Exchange-price-tooltip {
  font-size: var(--font-sm);
  background: linear-gradient(90deg, rgba(57, 28, 147, 0.9) 0%, rgba(66, 24, 140, 0.9) 100%);
  padding: 0.9rem;
  border: 1px solid rgba(30, 9, 94, 0.5);
  line-height: 2rem;
}

.Exchange-price-time {
  font-size: 1.25rem;
  font-weight: normal;
}

.Error-modal .Modal-content {
  width: 31rem;
}

.PositionEditor .Modal-content {
  width: 38rem;
  position: absolute;
  max-height: 80vh;
  overflow: hidden;
  border: none;
  top: auto;
  bottom: auto;
}

.PositionEditor .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 8.525rem);
  overflow-y: auto;
  padding-right: 0.5rem;
  margin: 1.5rem;
  margin-right: 1rem;
}

.PositionEditor .Tab {
  margin-bottom: 2rem;
  box-shadow: none;
  border-radius: 0;
  display: flex;
  justify-content: center;
}
.PositionEditor .Tab .Tab-option{
  padding-left: 3rem;
  padding-right: 3rem;
}

.PositionEditor-token-symbol {
  font-size: 1.5rem;
  text-align: right;
  display: flex;
  align-items: center;
}

.ExchangeChart-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Exchange-list-modal .Modal-content {
  width: 36rem;
}

.Exchange-list-container {
  max-width: calc(100vw - 33.5rem);
}

.Exchange-list {
  width: 100%;
}

.Position-list-order {
  white-space: nowrap;
  margin-top: 8px;
}

.active-order-tooltip {
  background: var(--dark-blue-hover);
  padding: 0.75rem;
  border-radius: var(--border-radius-sm);
  white-space: normal;
}
.active-order-tooltip > .active-oredr-error {
  margin-top: 0.5rem;
}

.order-error span {
  text-decoration-color: var(--error-red);
  color: var(--error-red);
}

.order-error .Tooltip-popup {
  white-space: pre-line;
}

.Exchange-list .App-card {
  margin-bottom: 1.5rem;
}

.Exchange-list-item-error {
  color: #fa3c58;
  margin-top: 0.465rem;
  font-size: var(--font-sm);
}

.Exchange-list.small {
  display: none;
}

button.Exchange-list-action {
  font-size: var(--font-base);
  padding: 0;
  border-radius: 3px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0.3rem;
}

button.Exchange-list-action:hover {
  color: rgba(255, 255, 255, 1);
}

table.Exchange-list {
  border-collapse: collapse;
}

table.Exchange-list th,
table.Exchange-list td {
  font-weight: normal;
  text-align: left;
  padding: 0.1rem;
  padding-left: 1.5rem;
}
table.Exchange-list.Orders th,
table.Exchange-list.Orders td {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

table.Exchange-list tr {
  border-bottom: 1px solid #444444;
}

table.Exchange-list tr:last-child {
  border-bottom: none;
}

table.Exchange-list tr:hover {
  /* background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(38, 43, 71, 1) 100%); */
  box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01);
  background: #36363680;
}

table.Exchange-list tr.Exchange-list-header {
  background: none;
  font-size: var(--font-md);
}

table.Exchange-list tr.Exchange-list-header th {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

table.Exchange-list th {
  opacity: 0.7;
}

.Exchange-leverage-box {
  margin:  2rem  0.5rem  0.25rem;
  height: 10rem;
}

.Exchange-swap-box-info {
  margin-bottom: 1.05rem;
}

.Exchange-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1.25rem;
  margin-bottom: 0.465rem;
}

.Exchange-info-row.top-line {
  border-top: 1px solid #474747;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.Exchange-info-label {
  opacity: 0.7;
  margin-right: 0.8rem;
}
.Exchange-info-value-warning {
  color: #fa3c58;
}

.Confirmation-box-info,
.Confirmation-box-info a,
.Confirmation-box-warning,
.Confirmation-box-warning a {
  font-size: var(--font-sm);
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Confirmation-box-info,
.Confirmation-box-info a {
  opacity: 0.9;
  font-weight: bolder;
  color: white;
}
.create-order-info  .Confirmation-box-info {
  margin-bottom: 0;
}
.Confirmation-box-warning,
.Confirmation-box-warning a {
  color: #fa3c58;
  text-align: center;
  padding: 0.5rem 0 0 0;
}

.Exchange-swap-usd {
  display: inline-block;
  font-size: 1.25rem;
}

.Exchange-swap-box {
  /* width: 41.85rem; */
  display: grid;
  position: relative;
  border-left: 0;
}

.Exchange-right.large .Exchange-swap-box {
  width: 32rem;
}

.Exchange-swap-market-box-title {
  font-size: 1.35rem;
  margin-bottom: 1.25rem;
}

.Exchange-swap-box-inner {
  padding: 1.5rem;
}

.Exchange-swap-market-box {
  padding: 1.5rem;
  margin-top: 1.25rem;
  padding-bottom: 1.5rem;
}

.Exchange-swap-market-box .App-card-divider {
  margin: 0 -1.5rem 1.5rem;
}

.Exchange-swap-box .Radio {
  margin-bottom: 1.05rem;
}

.Exchange-swap-box .Overlay-content-outer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15.5rem;
}

.Exchange-swap-box .Overlay-content {
  padding: 1.05rem;
}

.Exchange-swap-box .TokenSelector .Modal,
.Selector .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.Exchange-swap-box .TokenSelector .Modal-content,
.Selector .Modal-content {
  width: 41.85rem;
  position: absolute;
  max-height: 100vh;
  top: 0;
  bottom: 0;
  overflow: hidden;
  border: none;
}
.Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
  scroll-margin-right: 38.75rem;
  overscroll-behavior: smooth;
  scrollbar-width: thin;
  padding-right: 0.5rem;
  max-height: calc(100vh - 7.75rem);
  overflow-y: auto;
}

.Exchange-swap-box .Tab {
  margin-bottom: 1.05rem;
  margin-top:0;
  /* padding-top:0.5rem; */
}

.Exchange-swap-section {
  /* padding: 1.5rem;
  margin-bottom: 1.05rem;
  border-radius: 0.3rem;
  background: #363636;
  border-radius: 1rem; */
  margin-bottom: 1.75rem;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option {
  padding: 1.05rem;
}

.Exchange-swap-section.price{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.Exchange-swap-section.price .Exchange-swap-section-top{
  flex-basis: 30%;
  font-size: 1.75rem;
  padding-bottom: 0;
}
.Exchange-swap-section.price .Exchange-swap-section-bottom{
  flex-basis: 70%;
  padding: 1rem 2rem;
  border-radius: 0;
  height: 4rem;
}
.Exchange-swap-option-tabs.Tab.block .Tab-option.active {
  background: #E2E2E3;
  font-weight: 700;
  font-size: 1.35rem;
  line-height: 19px;

  color: #363636;
  border-bottom: 0;
}

.Exchange-swap-order-type-tabs {
  margin-top: 1.5rem;
  padding:   1rem 1.5rem 0;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option-icon {
  margin-top: -0.155rem;
  transform: scale(0.75);
  vertical-align: middle;
  margin-right: 0.8rem;
}

.Exchange-swap-section-top {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1.25rem;
  padding-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
}
.Exchange-swap-section .usd-value{
  opacity: 0.7;
  font-size: 1.15rem;
}
.Exchange-swap-section-bottom {
  /* display: grid;
  grid-template-columns: 1fr auto; */
  /* padding-bottom: 0.31rem; */

  border-radius: 0;

  background: #363636;
  /* border-radius: 1rem; */
  padding: 1rem 1.25rem 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Exchange-swap-message {
  font-size: var(--font-sm);
  opacity: 0.7;
  margin: 0.5rem 0;
}

.Exchange-swap-input-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

}
.Exchange-swap-section.price .Exchange-swap-input-container input{
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 1.5rem;
}

.Exchange-swap-section.price .PositionEditor-token-symbol{
  font-size: 1.5rem;
}
.Exchange-swap-input-container  input{
  margin-bottom: 1rem;
}

.Modal-content .Exchange-swap-input-container  input{
  margin-bottom: 0rem;
}
.Exchange-swap-max {
  position: absolute;
  right: 1.25rem;
  top: 0;
  padding: 0.465rem 0.8rem;
  color: #fff;
  border-radius: 3px;
  z-index: 1;
  cursor: pointer;
  font-size: var(--font-sm);
  position: unset;
  margin-right: 2rem;


}

.Exchange-swap-max:hover {
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(90deg, rgba(58, 78, 252, 1) 0%, rgba(51, 68, 222, 1) 100%);
}

.Exchange-swap-section-top .muted.align-right.clickable{
  display: flex;
  align-items: center;
  justify-content: center;
}

.Exchange-swap-section-bottom .TokenSelector-box {
  font-size: 1.5rem;
}

.Exchange-leverage-box .TokenSelector-caret {
  font-size: var(--font-base);
  margin-left: 0.31rem;
}

input.Exchange-swap-input {
  padding: 0;
  max-width: 100%;
  font-size: 1.5rem;
  width: 100%;
  padding-right: 2rem;
}
input.Exchange-swap-input.small {
  max-width: 14.725rem;
}

.Exchange-swap-button-container {
  padding-top: 0.5rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.Exchange-swap-button-container.swap {
  /* padding-top: 0.31rem; */
  padding: 0.31rem 1.5rem 1rem;
}
.Exchange-swap-button-container .App-cta.Exchange-swap-button{
  font-size: 1.5rem;
  padding: 1rem;
  font-weight: 600;
  /* background: #363636;
  color: #E2E2E3; */
}
.Exchange-swap-button {
  display: block;
  width: 100%;
}

.Exchange-swap-ball-container {
  position: relative;
  z-index: 1;
}

.Exchange-swap-ball-icon {
  text-align: center;
  display: block;
  transform: rotate(90deg);
  font-size: 2rem;
  opacity: 0.7;
}

.Exchange-swap-ball {
  position: absolute;
  width: 3.565rem;
  height: 3.565rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -1.9375rem;
  border-radius: 3.1rem;
  cursor: pointer;
  user-select: none;
  background: #636363;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

.Exchange-swap-ball:hover {
  opacity: 0.85;
}

.Exchange-swap-ball:hover .Exchange-swap-ball-icon {
  opacity: 1;
}

table.Exchange-list-small {
  width: 100%;
  background: linear-gradient(45deg, rgba(11, 5, 55, 0.6) 0%, rgba(21, 3, 48, 0.6) 100%);
}

table.Exchange-list-small th,
table.Exchange-list-small td {
  font-weight: normal;
  text-align: left;
  padding: 1.05rem;
}

.Exchange-list-card-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.8rem;
}

.Exchange-list-title {
  width: 3.1rem;
  display: inline-block;
  white-space: nowrap;
}

.Exchange-list-info-label {
  font-size: var(--font-sm);
  margin-top: 0.31rem;
}

.Exchange-list-muted {
  font-size: var(--font-sm);
}

.Exchange-list-card .Exchange-list-side {
  text-align: right;
}

.Exchange-list-card .Exchange-list-size-info {
  margin-bottom: 0.8rem;
}

.Confirmation-box {
  font-size: 2rem;
}

.Confirmation-box-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 2.325rem;
}
.Confirmation-box-main-icon {
  margin: 0.5rem 0;
}
.Confirmation-box-main-icon:after {
  content: "↓";
}
.Confirmation-box-main-icon.dot:after {
  content: "•";
}

.Confirmation-box .Modal-content {
  width: 36rem;
}

.Confirmation-box-row {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.Confirmation-box-button {
  margin-top: 1rem;
  width: 100%;
}

.Exchange-footer {
  text-align: center;
  padding-top: 3.1rem;
  height: 7.75rem;
}
.cancel-order-btn {
  margin-right: 1.5rem;
  background: none;
  border: none;
  color: white;
  padding: 0;
  padding: 1rem;
  background: #363636;
  border-radius: 1rem;
}
.cancel-order-btn:hover {
  opacity: 0.8;
}
.cancel-order-btn:disabled {
  opacity: 0.6;
}

.chart-positions:hover {
  opacity: 0.8;
}
.chart-positions.span:hover {
  opacity: 1;
}

.al-swap .Tooltip-popup {
  min-width: 25rem;
}

.position-list-collateral {
  display: flex;
  align-items: flex-end;
}

.position-list-collateral .edit-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
}
.position-list-collateral .edit-icon:hover {
  opacity: 1;
}

.Useful-links-exchange {
  display: none;
}

.ExchangeChart-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Position-leverage {
  margin-right: 0.25rem;
  color: rgb(255, 242, 0);
  opacity: 1;
  font-size: 1.25rem;
  margin-left: 1rem;
}

@media (max-width: 350px) {
  .Exchange.page-layout .ExchangeChart-title-price {
    width: 16rem;
    padding-left: 0.5rem;
  }

  .Exchange.page-layout .ExchangeChart-title-price .ExchangeChart-title{
    width: 14rem;
  }

  .Exchange.page-layout .TokenSelector-box-symbol.label{
    margin: 0;
  }

  .Exchange.page-layout .ExchangeChart-title .App-cta.chart-token-selector{
    width: 13.5rem;
  }

  .Exchange.page-layout .ExchangeChart-title-price .ExchangeChart-main-container{
    width: 14rem;
  }

  .Exchange.page-layout .ExchangeChart.tv .ExchangeChart-additional-info.small{
    margin-right: 11rem;
  }
}
@media (max-width: 1700px) {
  .ExchangeChart-additional-info.min-price-large{
    display: none;
  }
}
@media (max-width: 1500px) {
  .Exchange .ExchangeChart-min-price.min-price-small{
    display: block;
  }

  .Exchange.page-layout .Exchange-left .token-table th:nth-child(4){
    width: 10rem;
  }
}

@media (max-width: 1300px) {
  .Exchange-list.large {
    display: none;
  }

  .Exchange-list.small {
    display: table;
  }
}

@media (max-width: 1100px) {
  .Exchange-swap-box {
    width: auto;
  }

  .Exchange-right {
    border: 0;
  }

  .Exchange .Exchange-right.large {
    display: none;
  }

  .Exchange .Exchange-right.small {
    display: block;
    margin-top: 2rem;
  }

  .Exchange-list-container {
    max-width: 100vw;
  }

  .ExchangeChart.tv .ExchangeChart-additional-info.large{
    display: none;
  }
  .Exchange.page-layout .ExchangeChart.tv .ExchangeChart-additional-info.small{
    display: flex;
    margin-right: auto;
    margin-top: 2rem;
    /* margin-right: 13.75rem; */
    flex-direction: column;
  }
  .ExchangeChart.tv .ExchangeChart-bottom-header {
    display: block;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-stats {
    margin-left: 0;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    border-bottom: 1px solid #444;
  }

  .Exchange-list-tabs {
    margin-top: 0;
  }

  .Exchange-wallet-tokens {
    display: none;
  }

  .Exchange-swap-placeholder {
    display: none;
  }

  .ExchangeChart {
    height: 48.75rem;
    border-bottom: 1px solid #444444;
  }

  .Exchange .ExchangeChart-title-price .ExchangeChart-main-container{
    margin-right: auto;
    width: 180px;
    margin-top: 1.5rem;
  }
  .Exchange .ExchangeChart-title-price{
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-evenly;
    flex-basis: 30%;
    padding-left: 1rem;
  }
  .Exchange .ExchangeChart-title{
    margin: 0;
    display: flex;
    justify-content: flex-start;
  }
/* 17 7.475  38.75 */
  .Exchange.page-layout .instrument-stats{
    flex-wrap: wrap;
    flex-basis: 60%;
    justify-content: space-between;
    font-size: 1.25rem;
    align-items: end;
  }
  .Exchange.page-layout .ExchangeChart.tv .ExchangeChart-top{
    padding: 0;
  }

  .instrument-stats .ExchangeChart-min-price{
    margin-top: 0.90rem;
  }
  .Exchange.page-layout .instrument-stats .ExchangeChart-additional-info{
    flex-basis: 45%;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .Exchange.page-layout .instrument-stats .ExchangeChart-additional-info:nth-child(2),
  .Exchange.page-layout .instrument-stats .ExchangeChart-additional-info:nth-child(3) {
    margin-bottom: 2rem;
  }
  .Exchange-left .chart-token-selector{
    width: 15rem;
  }
  .Exchange .ExchangeChart-top-inner{
    display: flex;
    justify-content: space-between;
    height: 17rem;
    flex-wrap: nowrap;
    margin: 1rem;
  }
  .Exchange .align-right.Exchange-should-show-position-lines {
    display: none;
  }
  .Exchange-content {
    grid-template-columns: 1fr;
  }

  .Exchange-lists.large {
    display: none;
  }

  .Exchange-lists.small {
    display: block;
  }

  .Exchange-swap-box .TokenSelector .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .order-error-message {
    white-space: pre-line;
    display: block;
    margin-bottom: 10px;
  }

  .Exchange-swap-box .TokenSelector .Modal-content,
  .Selector .Modal-content {
    width: 41.85rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }

  .Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(70vh - 8.525rem);
    scroll-margin-right: 38.75rem;
    overscroll-behavior: smooth;
    scrollbar-width: thin;
    padding-right: 0.5rem;
    margin: 1.5rem 0.8rem 1.5rem 1.5rem;
  }

  .Exchange-swap-box .TokenSelector .Modal,
  .Selector .Modal {
    align-items: center;
    justify-content: center;
  }

  input.Exchange-swap-input {
    max-width: 15.5rem;
  }

  .ExchangeChart-bottom-controls .Tab .Tab-option {
    width: 3.875rem;
    padding: 0.465rem 0;
    font-size: 1.25rem;
  }
  .Useful-links-swapbox {
    display: none;
  }
  .Useful-links-exchange {
    display: block;
  }
}

@media (max-width: 700px) {
  .PositionEditor .Modal-content {
    width: 90vw;
  }

  .ExchangeChart-top-inner {
    /* grid-template-columns: auto auto auto; */
    /* grid-column-gap: 0.8rem; */
    height: 100%;
  }

  .Exchange-content .actionsBox {
    display: none;
  }

  .ExchangeChart-title {
    font-size: 1.85rem;
  }

  .ExchangeChart-additional-info {
    display: block;
  }

  .Exchange-swap-box .TokenSelector .Modal {
    margin: 0 1.5rem;
  }
  .Exchange {
    padding-top: 1.5rem !important;
  }
}

@media (max-width: 450px) {
  .chart-positions {
    display: none;
  }

  .Exchange.page-layout .instrument-stats{
    /* flex-wrap: nowrap; */

    margin-left: 0;
    padding-right: 1rem;
  }

  .Exchange.page-layout .chart-token-button img {
    display: none;
  }
}

@media (min-width: 1100px) {
  .Exchange.page-layout .token-table {
    width: 100%;
  }

  .instrument-stats .ExchangeChart-additional-info {
    margin: auto 1rem;
  }
}

@media (min-width: 1600px) {
  .Exchange.page-layout .Exchange-right {
    flex-basis: 12.5%;
    min-width: 28rem;
  }

  .Exchange.page-layout .Exchange-left {
    flex-basis: 87.5%;
  }

  .TradeHistory .Exchange-list.Orders .Exchange-list-header th.timestamp {
    width: 35rem;
  }
}

.Exchange-content .App-box{
  background: #222124;
  border: 1px solid #444444;
  border-right: 0;
  border-left: 0;
}

.Exchange-swap-option-tabs .Tab-option:nth-child(n + 0){
  background :#1B2937 ;
}
.Exchange-swap-option-tabs .Tab-option:nth-child(2){
  background :#1B2937 ;
}

.Exchange-swap-option-tabs .Tab-option{
  border: 0;
}

.Exchange-swap-option-tabs .Tab-option:nth-child(1){
  border: 0;
}
.Exchange-swap-box-inner .Tab.block .Tab-option.active {
  border-bottom: 0;
  border: 0;
  box-shadow: 0;
}
.Exchange-swap-box-inner .Tab.block {
  border-bottom: 0;
}
.orderContainer{
  margin-bottom: 2rem;
  box-shadow: 0;
}

.orderContainer .Tab.block {
  border: 0;
  box-shadow: 0;
  background: #26313D;
  border-radius: 0.5rem;
}

.orderContainer .Tab.block .Tab-option.active {
  background: #0369D2;
  border-radius: 0.5rem;
  border: 0;
}

.orderContainer .Tab-option:nth-child(1) {
  border: 0;
}

.ExchangeChart-bottom-stats span:nth-child(8) {
  margin-right: 0;
}

.ExchangeChart-bottom-controls .Tab-option:nth-child(1) {
  border: 0;
}

.ExchangeChart-bottom-controls .Tab.block {
  box-shadow: none;
  border-bottom: 0;
}

.Exchange-lists .Exchange-list-tab-container {
  padding-top: 0;
  padding-left: 1rem;
  padding-bottom: 0;
  padding-right: 2rem;
}

.Exchange-lists .Exchange-list-tab-container .Tab.block {
  border-bottom: 0;
  box-shadow: none;
  /* width: 80%; */

}
.Exchange-lists .Exchange-list-tab-container .Tab.block .Tab-option{
  font-size: 1.5rem;
  padding: 1.5rem;

}
.Exchange-lists .Exchange-list-tab-container .Tab-option:nth-child(1){
  border-right: 0;
}

.notActiveSpan {
  background: #0369D2;
  padding: 0.1rem 0.4rem 0.2rem;
  border-radius: 0.5rem;
}

@media (max-width: 587px) {
  .ExchangeChart-bottom-controls {
    flex-direction: column;
  }

  .Exchange-lists .Exchange-list-tab-container .Tab.block{
    border-bottom: 0;
    box-shadow: none;
    padding: 1rem;
    /* width: 80%; */

  }
  .Exchange-lists .Exchange-list-tab-container .Tab.block .Tab-option {
    font-size: 1rem;
    padding: 0.5rem;
    /* width: 50%; */
  }
}

.Exchange-content .actionsBox .Book .TradeHistory-time a.plain {
  font-size: 1rem;
}

.Exchange-content .actionsBox .Book .TradeHistory-row {
  padding: 0;
  border: none;
  border-radius: 0;
}

.Exchange-content .actionsBox .Book .TradeHistory-time {
  font-size: 1rem;
}

 .tv-lightweight-charts {
  width: 25rem;

}

.ExchangeChart-top-inner .greenPrice {
  color: #0ecc83;
  max-height: 3.2rem;
  width: 10rem;
}

.ExchangeChart-top-inner .redPrice {
  color: #fa3c58;
  width: 10rem;
}

.OrderBook-header {
  padding: 0 2rem;
  height : 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  /* border-right: 1px solid #444444; */
  font-size: 1.35rem;
}
.OrderBook-header p {
  margin: 0;
  padding: 1rem 0;
}

.Exchange-chart-and-book .ExchangeChart.tv {
  flex: 1;
  border-bottom: 1px solid #272A2F;
}
.Exchange-chart-and-book .actionsBox {
  width: 18rem;
  height: 52.3rem;
  border-left: 1px solid #444444;
}

.TradeHistory.Book .allMessages {
  max-height: 45.5rem;
  overflow-y: auto;
}

.TradeHistory.Book .allMessages::-webkit-scrollbar {
  width: 5px;
}

.Exchange-right .App-box-highlight {
  background-color: #222124;

}

.Exchange-right .Exchange-swap-option-tabs {
  height : 3.75rem;
  border-radius: 0;
}

.Exchange-swap-order-type-tabs.Tab.inline .Tab-option {
  padding-bottom: 1rem;
  margin-right: 3rem;
  font-size: 1.35rem;
}
.Exchange-swap-order-type-tabs.Tab.inline .Tab-option.active {
  border-bottom: 3.5px solid #E2E2E3;
  color: #E2E2E3;
}

.Exchange-right .App-card.Exchange-Info-Card {
  background: #363636;
  border: 1px solid #474747;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
}

.Exchange-swap-market-box.App-box.App-card.Exchange-Info-Card {
  margin: 1rem 1.5rem 2rem;
}

/* .toggle-leverage {
  width: ;
} */

._ju7HT {
  height: 1.6rem;
  width: 1.6rem;
  top: 0.1rem;
  bottom: 0;
  right: 0rem;
  left: -0.15rem;
}

.add-sub-leverage{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
}
.add-sub-leverage .add{
  background: #91E49E;
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-sub-leverage .sub{
  background: #F56868;
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-sub-leverage p{
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  opacity: 0.7;
  font-size: 1.25rem;
}

.add-sub-leverage .input{
  width: 10rem;
  margin: 0 2rem;
}
.add-sub-leverage input{
  /* border-bottom: 0.3rem solid #E2E2E3;  */
  background: #363636;
  border-radius: 1rem;
  width: 10rem;
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
}

.App-slider .rc-slider{
  padding-bottom: 1rem;
}

.App-slider .rc-slider .rc-slider-track{
  background: #91E49E;
}

.App-slider .rc-slider .rc-slider-rail,
.App-slider .rc-slider-step .rc-slider-dot{
  background: #91E49E;
  opacity: 0.15;

}
.App-slider .rc-slider-step .rc-slider-dot-active{
  opacity: 1;
}

.App-slider .rc-slider .rc-slider-dot{
  width: 3px;
}

.App-slider .rc-slider .rc-slider-handle{
  background: #91E49E;
  border: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin-top: -8px;
}

.App-slider .rc-slider .rc-slider-mark{
  top: 20px;
}

.App-slider .rc-slider .rc-slider-handle:active{
  box-shadow: 0 0 0.4rem 0.6rem #91e49d3e
}
.App-slider .rc-slider .rc-slider-tooltip{
  background: #363636;
}
.Exchange-swap-box-inner.App-box-highlight {
  border: 0;
  padding-top: 0;
  margin-top: -1.15rem;
}

.Exchange-swap-section.price .Exchange-swap-section-bottom.Mark {
  padding: 0.75rem;
  width: 50%;
  background: #363636;
  font-size: 1.5rem;
  padding-left: 1.5rem;
}

.ExchangeChart-title .chart-token-selector--current{
  font-size: 1.75rem;
}
.ExchangeChart-title {
  flex-basis: 30%;
}

.ExchangeChart-additional-info div:nth-child(2){
  font-size: 1.25rem;
  margin-top: 0.5rem;
  font-family: sans-serif;
}

.Exchange-lists {
  background: #222124;
}

.Exchange-list-tab-container .Tab.inline .Tab-option{
  margin-right: 2.5rem;
}

.Exchange-list .clickable.title.positive{
  border-left: 7px solid #0ecc83;
  padding: 1.5rem;
  padding-right: 0.1rem;
}
.Exchange-list .clickable.title.negative{
  border-left: 7px solid #fa3c58;
}
.Exchange-list .clickable.title .Postion-lev-tag{
  display: flex;
  flex-direction: column;
  width: 3.5rem;
}
.Exchange-list .clickable.title {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}
/* color: #fa3c58;
#0ecc83; */
.Exchange-list .pnl-row{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.Exchange-list-title{
  color: #E2E2E3;
  opacity: 1;
}
.Exchange-list-info-label .perpetual-tag {
  color: #E2E2E3;
  opacity: 0.7;
  font-size: 1.25rem;
}

._2-ZbH {
  display: inline-block;
  height: 18px;
  position: relative;
  width: 35px;
  margin-bottom: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

._3QxTi{
  height: 18px;
}

.Exchange-should-show-position-lines span {
  margin-right: 1rem;
}

.Exchange-chart-and-book{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #444444;
}

.Exchange  .Tab.inline.Exchange-list-tabs{
  padding: 1rem;
  background: #363636;
  border-radius: 1rem;
}

.Exchange .show-more-container{
  display: flex;
  justify-content: center;
}
.Exchange .Exchange-list-tab-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Exchange-should-show-position-lines {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.Exchange-swap-box .Confirmation-box-main div:nth-child(3),
.Exchange-swap-box .Confirmation-box-main span{
  background: #363636;
  border: 1.38404px solid #474747;
  border-radius: 1.5rem;
  padding:  1rem;
  font-size: 1.25rem;
}

.Exchange-swap-box .Confirmation-box-main-icon{
  margin-bottom: 0;
}

.Exchange.page-layout .token-table{
  padding: 1rem 3rem;
  /* font-size: 1.25rem; */
}
.Exchange.page-layout .token-table .App-card-info-title{
  font-size: 1.25rem;
}
/* .Exchange.page-layout .token-table .App-card-title-info-icon img{
  width: 32px;
  height: 32px;
} */

/* .Exchange.page-layout .token-table tr{
  border: 1px solid #ffffff;
} */

.PositionsList .App-close-order{
  background: #363636;
  width: 10rem;
  height: 2rem;
  border-radius: 1rem;
  margin: 0.25rem;
}
.PositionsList .edit-icon{
  cursor: pointer;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
}
.PositionsList .Exchange-list-info-label.pnl-row span,
.PositionsList .clickable,
.PositionsList .Tooltip-handle.plain {
  font-family: sans-serif;
}
.PositionsList .muted.Position-leverage {
  width: 4rem;
  font-family: sans-serif;
}
.PositionsList .order-container.mark {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.PositionsList .order-container .mark {
  background: #363636;
  width: 10rem;
  height: 2.5rem;
  border-radius: 1rem;
  text-align: start;
  padding-left: 1rem;
  padding-top: 0.45rem;
  margin: 0.25rem;
  font-size: 1.4rem;
}
.PositionsList .order-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.PositionsList .order-container input {
  font-size: 1.35rem;
}

.Exchange.page-layout .Exchange-list-item {
  border-top: 1px solid #444444;
  font-size: 1.25rem;
}

.Exchange.page-layout .token-table {
  margin-left: 0;
  margin-right: 0;

}
.Exchange.page-layout .Exchange-list-header {
  font-size: 1.35rem;
}
.PositionEditor-allow-higher-slippage .Exchange-info-row {
  display: flex;
  justify-content: space-between;
}
 .instrument-stats{
  display: flex;
  /* justify-content: space-evenly;
  flex-basis: 70%; */
  margin-left: 2rem;
}

/* .Exchange.page-layout .token-table {
  display: table;
  overflow-x: scroll;
  white-space: nowrap;
} */

.ExchangeChart-title-price{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ExchangeChart-title-price .ExchangeChart-title{
  flex-basis: 0%;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
.ExchangeChart-title-price .ExchangeChart-title button{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: none;
  border: 0;
  padding-left: 0;
}
.Exchange.page-layout .ExchangeChart-title-price .TokenSelector-box-symbol.label{
  margin-right: 0.5rem;
}
.PositionsList .App-card,
.Exchange-list.Orders.small .App-card{
  border: 1px solid #444;
}

.Exchange-list.small .App-button-option.App-card-option{
  color: #E2E2E3;
  background: #363636
}
.Exchange-list.small .App-button-option.App-card-option:hover,
.Exchange-list.Orders.small .App-button-option.App-card-option:hover{
  background: #36363646;
}

/* .Exchange-list.small{
  display: none;
} */

.ExchangeChart-additional-info.small{
  display: none;
}

.Exchange-list  .App-card .App-card-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Exchange-list  .App-card .App-card-content .App-card-row{
  display: flex;
  flex-basis: 45%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.Exchange-list  .App-card .App-card-content .App-card-row:nth-child(2n){
  align-items: flex-end;

}
.Exchange-list .App-card .App-card-content .App-card-row .label{
  margin-bottom: 0.5rem;
}

.Exchange-list .App-card .App-card-options{
  flex-basis: 100%;
}

.Exchange-list .App-card-options.position-close-small .label{
  margin-bottom: 0.5rem;
}
.Exchange-list .App-card-options.position-close-small .mark .order-container{
  padding-right: 0;
}
.Exchange-list .App-card-options.position-close-small .order-container{
  padding-right: 1.5rem;
  padding-left: 1rem;
  justify-content: space-between;
}
.Exchange-right.small {
  display: none;
}
.Exchange-right.large {
  border-left: 1px solid #444;
}

.Exchange.page-layout .PositionEditor-info-box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .Exchange.page-layout .Exchange-info-row.top-line{
  flex-basis: 100%;
} */
.Exchange.page-layout .Exchange-info-row .Exchange-info-label{
  margin-bottom: 0.5rem;
  margin-right: 0;
}
.Exchange.page-layout .Exchange-info-row{
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  align-items: flex-start;
  border:  none;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

.Exchange.page-layout .Exchange-info-row:nth-child(2n+1){
  align-items: flex-end;
}
.Exchange.page-layout .PositionEditor-keep-leverage-settings{
  margin-bottom: 1rem;
}
.Exchange.page-layout .create-order-info.short .Exchange-info-row:nth-child(2n+1){
  align-items: flex-end;
}
.Exchange.page-layout .create-order-info.short .Exchange-info-row:nth-child(2n){
  align-items: flex-start;
}

.Exchange.page-layout .Exchange-list-modal.order-editor .Exchange-info-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  align-items: center;
}
.Exchange.page-layout .Exchange-list-modal.order-editor .Exchange-swap-button-container{
  margin-top: 1rem;
}

.Exchange-right .App-card.Exchange-Info-Card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Exchange-right .Exchange-swap-market-box-title {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
.Exchange .Exchange-right .Exchange-Info-Card .Exchange-info-row {
  flex-basis: 45%;
  margin-bottom: 0.25rem;
}

.Exchange-swap-box-inner .App-card.Exchange-Info-Card .align-right {
  font-size: 1.1rem;
}

.ExchangeChart-main-price{
  font-size: 2rem;
  font-family: sans-serif;
}
.instrument-stats .ExchangeChart-min-price{
  font-size: 1.35rem;
  font-family: sans-serif;
}

.ExchangeChart-min-price.min-price-small{
  display: none;
  font-family: sans-serif;
}

.ExchangeChart-title .App-cta{
  padding-left: 0;
  margin-left: 0;
}

.ExchangeChart-title .App-cta.chart-token-selector{
  padding-left: 0.3rem;
}

.Exchange .PositionsList table.Exchange-list tr.Exchange-list-header th:nth-child(n + 8){
  text-align: center;
}

.Exchange-list-info-label.pnl-row,
.Postion-lev-tag .Exchange-list-info-label,
.Exchange-list-item .Exchange-position-list-orders {
  font-size: 1.25rem;
}

.Exchange .Exchange-Info-Card .transition-arrow{
  margin-top: 0;
}

.Exchange  .align-right{
  align-items: center;
}
.Exchange.page-layout .Exchange-Info-Card .Exchange-info-row {
  margin-bottom: 0.5rem;
}
.Exchange.page-layout .Exchange-Info-Card .Exchange-info-row .Exchange-info-label{
  margin-bottom: 0;
}

.Exchange.page-layout .instrument-stats .ExchangeChart-additional-info.min-price-large{
  margin-bottom: 0.75rem;
}

.Exchange.page-layout .FlpSwap-token-list.TradeHistory .Exchange-list-item:nth-child(2),
.Exchange.page-layout .FlpSwap-token-list.TradeHistory .Exchange-list-item:nth-child(3) {
  font-family: sans-serif;
}

.ExchangeChart-title .chart-token-button{
  display : flex;
}

.ExchangeChart-additional-info.small .positive,
.ExchangeChart-additional-info.small .negative{
  font-family: sans-serif;
}

.Exchange.page-layout{
  padding-top: 1.5rem;
}

.TVChartContainer .layout__area--top{
  border-bottom: 1px solid #e2e2e3;
}

.Exchange.page-layout .Exchange-left .token-table th:nth-child(3){
  min-width: 15rem;
}

.order-container .action {
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
}

.order-container .action:hover {
  background: #E2E2E3;
  color: #121212;
  font-weight: 600;
}

.order-container .action .action-handle {
  color: #E2E2E3;
  font-weight: 400;
}
