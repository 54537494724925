.nav-item-wrapper {
  position: relative;
  cursor: pointer;
  color: #848484;
  margin: auto 0.8rem;
}
.expanded-nav {
  position: absolute;
  background-color: #1a1a1a;
  width: 25rem;
  margin: 0.5rem 0;
  padding: 0 1rem;
}
.expanded-nav a {
  padding: 0.3rem !important;
}
.expanded-nav a p {
  margin: 0.3rem;
}
.nav-item-title {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
}
.nav-item-wrapper :hover {
  color: #fff;
}
.highligted-text {
  color: white !important;
}
.nav-item-wrapper {
  position: relative;
  cursor: pointer;
  color: #848484;
  margin: auto 0.8rem;
}
.nav-expanded-cnt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.2rem 0;
}
.nav-expanded-title {
  color: white;
}
.border-top {
  border-top: 1px solid #282828;
  padding-top: 0.5rem;
}
.nav-expanded-link {
  background-color: #1a1a1a;
}
.nav-expanded-desc {
  font-size: 1.2rem;
}

/* 
.stake-wrapper {
  --bg-size: 400%;
  --color-one: hsl(15 90% 55%);
  --color-two: hsl(40 95% 55%);
  background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 4s infinite linear; 
}  */

.unknown-event-title {
  --bg-size: 400%;
  --color-one: hsl(303, 90%, 73%); /* Updated color */
  --color-two: hsl(266, 76%, 66%); /* Updated color */
  background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 4s infinite linear;
  font-weight: bold;
  margin-left: 10px; 
}