.bridge-card-wrapper {
  width: 100%;
  height: 100%;
  background-color: #272a2fc2;
  border-radius: 2rem;
  padding: 2rem;
}
.bridge-card-wrapper:hover,
.bridge-card-wrapper:focus {
  border: 1px solid white;
}
.bridge-card-container {
  text-decoration: none;
}
.bridge-card-wrapper .bridge-card-logo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.bridge-card-wrapper .bridge-card-logo .chain-logo {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.bridge-card-wrapper .bridge-card-logo .chain-logo img {
  width: 20%;
  height: 20%;
  margin: 0 0.3rem;
}
.bridge-card-wrapper .bridge-card-logo img {
  width: 24%;
  height: 24%;
  border-radius: 1rem;
  margin: 0.3rem;
}
.bridge-card-wrapper .bridge-card-title {
  font-size: 2.3rem;
  font-weight: 500;
}
.bridge-card-wrapper .bridge-card-description {
  font-size: 1.5rem;
  color: #848484;
}

@media only screen and (max-width: 767px) {
  .bridge-card-wrapper .bridge-card-logo img {
    width: 18%;
    height: 18%;
  }
}
