.StakeV2 .Page-title-section {
  position: relative;
  z-index: 2;
}

.VesterDepositModal-info-rows {
  margin-bottom: 0.8rem;
}

.CompoundModal-menu {
  margin-bottom: 0.8rem;
}

.CompoundModal-menu .Checkbox {
  margin-bottom: 0.465rem;
}

.StakeModal .CompoundModal-menu div {
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
}

.TokenSelector  .Modal-content{
  display: block;
}

.Modal-content .StakeV2-claim-fees-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.Modal-content .StakeV2-claim-fees-row .TokenSelector-tokens{
  flex-basis: 100%;
}
.StakeV2-address-input {
  padding: 1.5rem 3.41rem;
  padding-bottom: 0;
}

.StakeV2-buy-fxdx-modal .Modal-content {
  max-width: 46.5rem;
}

.StakeV2-address-input input {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.7rem;
}

.StakeV2-boost-bar {
  overflow: hidden;
  vertical-align: middle;
  margin-left: 0.31rem;
  border-radius: 2px;
  width: 1.5rem;
  height: 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  position: relative;
}

.StakeV2-boost-icon {
  font-size: 1.085rem;
  z-index: 2;
}

.StakeV2-boost-bar-fill {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
}

.StakeV2-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}

.earn-page .Footer-wrapper {
  margin-top: 8rem;
}

@media (min-width: 1100px) {
  .earn-page .tokens-card  .App-card-row:nth-child(2){
    align-items: flex-start;
    padding-left: 60px;
  }
}
@media (max-width: 900px) {
  .StakeV2-cards {
    grid-template-columns: 1fr;
  }

  .StakeV2-content {
    min-height: 100vh;
  }

  .StakeV2-total-rewards-card {
    grid-row: 4;
  }
}

.section-title-block.account{
  display: flex;
  justify-content: space-between;
}

.account-reward-stats .total{
  display: flex;
  justify-content: space-evenly;
}

.account-reward-stats .total .totaldiv{
  display: flex;
  /* margin-left: 3rem; */
  border: 1px solid #272A2F;
  background: rgba(33, 34, 35, 0.7843137255);
  border-radius: 0.75rem;
  font-weight: normal;
  padding: 1rem;
  margin-right: 3rem;

}

.account-reward-stats .total .totaldiv:nth-child(1){
  margin-right: 1.25rem;
}

.section-title-block.account .user-address{
  margin-left: 0;
  text-decoration: none;
  font-size: 1.5rem;
}

.account-reward-stats{
  display: flex;
  align-items: flex-end;
}

.earn-page .App-card {
  display: flex;
  justify-content: space-between;
  padding: 2rem 3rem;
}
.earn-page .App-card .App-card-content{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.earn-page .App-card .App-card-row{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.earn-page .App-card .App-card-row .label{
  margin-bottom: 0.75rem;
}

.earn-page .App-card .App-card-title{
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.earn-page .App-card .App-card-title:nth-child(1){
  min-width: 60px;
}
/* .earn-page .transfer-account{
  width: 50%;
} */
.earn-page .transfer-account .App-card{
  padding: 1rem 1.25rem;
  font-size: 1.25rem;
  /* width: 20rem; */
}

.earn-page .transfer-account .App-card-row.total{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.earn-page .transfer-account .total  .label{
  margin: 0;
}
.earn-page .section-title-content.each{
  margin-top: 4rem;
  margin-bottom: 1.5rem;
}
.earn-page .StakeV2-fxdx-card{
  margin-bottom: 1rem;
}
/* .earn-page .vest .App-card-title {
  width: 10%;
}
 */


.earn-page  .tokens-card .App-card-content .App-card-row{
  flex-basis: 18%;
}

/* .earn-page  .tokens-card .App-card-content .App-card-title{

  flex-basis: 5%;
} */
.earn-page  .tokens-card .App-card-content  .drop-down{
  flex-basis: 5%;
  display: flex;
  justify-content: flex-end;
}

.earn-page .App-card.primary{
  display: flex;
  flex-direction: column;
  /* padding-top: 2rem; */
}
.earn-page .App-card-divider{
  margin-top: 2rem;
  background-color: #A5A5A5;
  width: 99.8%;
  align-self: center;
  margin-bottom: 0;
}
.dropdown-container{
  display: flex;
  justify-content: flex-end;
}
.flp-card .dropdown-container,
.vest.dropdown-container,
.tokens-card .dropdown-container{
  display: flex;
  justify-content: space-between;
}
.earn-page .App-button-option{
  background: #3E3C41;
  /* border-radius: 10px; */
  margin-left: 1.5rem;
  margin-right: 0;
}

.dropdown-primary {
  padding-top: 2rem ;
}

.token-total-dropdown{
  display: flex;
}
.earn-page .token-total-dropdown .App-card-row:nth-child(2){
  margin-left: 10rem;
  display: flex;
  padding-left: 0;
  align-items: flex-start;
}
.earn-page .flp-card .token-total-dropdown .App-card-row:nth-child(2){
  margin-left: 4rem;
  display: flex;

  align-items: flex-start;

}
.earn-page .flp-card  .App-card-content .App-card-title:nth-child(2){
  display: flex;
  justify-content: flex-end;
}

.transfer-account{
  display: flex;
  align-items: flex-end;
}

.earn-page .App-button-option{
  margin-bottom: 0;
  margin-top: 0;
}

 .App-card-divider{
  margin-bottom: 0;
}
.earn-page .App-card-options{
  margin: 0;
}

.earn-page .StakeV2-total-rewards-card{
  margin-top: 2rem;

}

.earn-page .active.buttons{
  display: flex;
  align-items: flex-end;
}
.earn-page .active.buttons{
  display: flex;
  align-items: flex-end;
}
/* .earn-page .no-active.buttons{
  flex-basis: 100%;
  justify-content: center;

} */
/* .earn-page .no-active.buttons .App-button-option{
  flex-basis: 100%;
  width: 100%;
} */

.earn-page .App-card-title .label{
  display: flex;
  align-items: center;
}

@media (max-width : 1100px) {

  .earn-page .App-card.primary,
  .earn-page .StakeV2-total-rewards-card {
    padding: 2rem 3.5%;
  }

  .dropdown-primary {
    padding-top: 0;
  }


  .StakeV2-total-rewards-card .dropdown-container{
    display: flex;
    justify-content: center;
    flex-basis: 49%;
    margin-top: 2rem;
  }
  .StakeV2-total-rewards-card .dropdown-container .buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;

  }
  .StakeV2-total-rewards-card .dropdown-container button{
    width: 100%;
    justify-content: center;
    margin: 0;
    flex-basis: 49%;
  }

  .earn-page .App-card.StakeV2-total-rewards-card .rows .App-card-row{
    display: none;
  }
  .earn-page .StakeV2-total-rewards-card .no-active.buttons {
    flex-basis: 20%;
  }
  .earn-page .App-card.StakeV2-total-rewards-card .no-active.buttons .App-card-option{
    width: none;
  }

  .earn-page .App-card.StakeV2-total-rewards-card .rows .App-card-row:nth-child(6){
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .earn-page .App-card.StakeV2-total-rewards-card .rows .App-card-row:nth-child(1){
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .earn-page .App-card.StakeV2-total-rewards-card .rows{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .earn-page .App-card.StakeV2-total-rewards-card .dropdown-rows .App-card-row:nth-child(1),
  .earn-page .App-card.StakeV2-total-rewards-card .dropdown-rows .App-card-row:nth-child(6){
    display: none;
  }

  .earn-page .App-card.StakeV2-total-rewards-card .rows .App-card-row,
  .earn-page .App-card.StakeV2-total-rewards-card .dropdown-rows .App-card-row{
      flex-basis: 49%;
  }
  .earn-page .App-card.StakeV2-total-rewards-card .dropdown-rows{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }
  .earn-page .App-card.StakeV2-total-rewards-card .dropdown-rows .App-card-row:nth-child(3),
  .earn-page .App-card.StakeV2-total-rewards-card .dropdown-rows .App-card-row:nth-child(5){
    display: flex;
    align-items: flex-end;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .earn-page .section-title-block {
    flex-wrap: wrap;
  }

  .earn-page  .section-title-block .section-title-content{

    flex-basis: 100%;
  }

  .earn-page .transfer-account,
  .earn-page  .account-reward-stats{
    /* flex-basis: 49%; */
    margin-top: 1rem;
  }

  .earn-page .transfer-account .App-card-options,
  .earn-page .transfer-account a{
    /* width: 100%; */
    justify-content: center;
  }

  .earn-page .transfer-account a{
    margin-left: 0rem;
    font-size: 1.25rem;
  }

  .earn-page .StakeV2-fxdx-card.tokens-card .rows{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .rows .App-card-row{
    display: none;
    margin-top: 1.5rem;
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .rows .App-card-row:nth-child(4),
  .earn-page .StakeV2-fxdx-card.tokens-card .rows .App-card-row:nth-child(5){
    display: flex;
    flex-basis: 49%;
  }
  .earn-page .vest .rows .App-card-row:nth-child(4),
  .earn-page .StakeV2-fxdx-card.tokens-card .rows .App-card-row:nth-child(5){
     align-items: flex-end;
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-rows {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* margin-top: 2rem; */

  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-rows .App-card-row{
    display: none;
    margin-top: 2rem;
  }
  .earn-page .vest .rows .App-card-row:nth-child(3),
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-rows .App-card-row:nth-child(1),
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-rows .App-card-row:nth-child(2),
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-rows .App-card-row:nth-child(3){
    display: flex;
    flex-basis: 49%;
  }

  .earn-page .StakeV2-fxdx-card.tokens-card.vest .dropdown-rows .App-card-row:nth-child(3){
    display: none;
  }

  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-rows .App-card-row:nth-child(2){
    align-items: flex-end;
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .App-card-row:nth-child(3),
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .App-card-row:nth-child(4){

    flex-basis: 100%;
  }

  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container {
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 1.5rem; */
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container {
    display: flex;
    flex-wrap: wrap;
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .App-card-row{
    margin-top: 2rem;

  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .buttons{
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    width: 100%;
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .buttons a{
    flex-basis: 100%;
    justify-content: center;
    margin-left: 0;
    margin-bottom: 1.5rem;
  }

  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .buttons a{
    flex-basis: 49%;
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .buttons button{
    /* width: 100%; */
    justify-content: center;
    margin: 0;
    /* flex-basis: 40%; */
    /* justify-self: start; */

  }
  /* .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .buttons .App-button-option:nth-child(2){
    margin-right: 3rem;
  } */
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .buttons-options {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .buttons-options .App-button-option{
    flex-basis: 49%;

  }
  .earn-page .StakeV2-fxdx-card.tokens-card .token-total-dropdown{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .earn-page .flp-card .token-total-dropdown .App-card-row:nth-child(2),
  .earn-page .token-total-dropdown .App-card-row:nth-child(2){
    margin-left: 0;
  }

  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .active.buttons{
    display: flex;
    justify-content: space-between;
  }
  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .active.buttons button{
    flex-basis: 49%;
  }

  .earn-page .flp-card .buttons{
    display: flex;
    justify-content: space-between;
  }

  .earn-page .buy-fxdx{
    flex-basis: 100%;
  }

  .earn-page .StakeV2-fxdx-card.tokens-card .dropdown-container .active.buttons  button.connect-wallet,
  .earn-page .StakeV2-total-rewards-card .dropdown-container button.connect-wallet{
    flex-basis: 100%;

  }
}

.tokens-card.vest .App-card-title{
  width: 85px;
}

.flp-card.tokens-card .App-card-title{
  width: 55px;
}


.App-card.account-reward-stats.balance{
  margin-right: 2rem;
}
