.stake-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.stake-header {
  width: 1200px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
}

.stake-heading {
  color: #e2e2e3;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
}

.claimbtn {
  width: 15rem;
  height: 40px;
  border-radius: 0.625rem;
  background: #3e3c41;
  font-size: 15px;
  font-weight: 600;
  margin: 8px 0px 8px 8px;
}

.stake-header-info-container {
  display: flex;
  align-items: center;
}

.stake-header-info-box {
  width: 32rem;
  height: 40px;
  border-radius: 0.625rem;
  border: 1.097px solid #444;
  background: #222124;
  margin: 0px 8px 0px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px 5px 8px;
}

.stake-header-info-label {
  color: #929292;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.stake-header-info-data {
  color: #f2f2f2;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
}

.stake-card {
  height: 28rem;
  width: 1200px;
  margin: 20px;
  display: flex;
  border-radius: 0.68544rem;
  background: #222124;
  padding: 20px;
}

.stake-info-card {
  width: 55%;
}

.stake-info-card-title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.stake-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 20rem
}

.stake-card-partition {
  background: #4d4d4d;
  height: 100%;
  width: 0.0625rem;
  margin-left: 20px;
  margin-right: 20px;
}

.stake-card-container {
  display: flex;
  justify-content: center;
}

.staketitle h1 {
  margin-left: 35px;
}

.dis_para {
  color: #b7b7bd;
  font-size: var(--font-base);
  line-height: 2.15rem;
  margin-left: 76px;
}

.staketitle {
  display: flex;
  justify-content: space-between;
  margin-right: 155px;
  margin-top: 35px;
}

.staketitle button {
  background-color: #3e3c41;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
  width: 63px;
  height: 39px;
  margin-top: 16px;
}

.inputAmountLabel {
  font-size: 15px;
  font-weight: 500;
}

.stakeContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.stakeInfo {
  border: 2px solid grey;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: 20px;
}

.stakeInfoCard {
  width: 270px;
  margin: 1rem;
  margin-top: 72px;
}

.stakeDataCard {
  width: 275px;
  margin: 1rem;
}

.stake-amount-label {
  display: flex;
  justify-content: space-between;
}

.user-balance {
  color: #929292;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.max-balance {
  margin-left: 5px;
  font-family: Inter;
  font-size: 12.5px;
  font-weight: 400;
}

.user-balance:hover {
  cursor: pointer;
  color: #e2e2e3;
}

.inputAmountData {
  border-radius: 0.5rem;
  background: #363636;
  width: 100%;
  height: 3.5rem;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 10px 10px 0px;
}

.durationComp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.durationCard {
  width: 22rem;
  height: 3.5rem;
  border-radius: 0.5rem;
  border: 1px solid #444;
  background: #222124;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin : 8px 7px 8px -17px;
  transform: translate(12px, -2px);
}

.duration {
  color: #929292;
  font-family: Inter;
  font-size: 12.5px;
  font-weight: 400;
}

.interestRate {
  font-family: Inter;
  font-size: 12.5px;
  font-weight: 500;
}

.stakebtn {
  padding: 5px;
  width: 100%;
  border-radius: 0.3125rem;
  background: #fffff2;
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  height: 35px;
}

.stakebtnBox {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}
.stake-data-card {
  width: 45%;
}

.info {
  display: flex;
  justify-content: space-between;
}

.infoLabel {
  color: #757575;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
}

.infoData {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
}

.highlighted {
  border-color: grey; /* or any other style to highlight */
  background-color: #3e3c41;
}

.testFooter {
  width: 100vw;
  height: 200px;
}

.stake-header-info-container-mobile {
  display: none;
}

.stake-header-info-box-mobile {
  width: 25rem;
  height: 40px;
  border-radius: 0.625rem;
  border: 1.097px solid #444;
  background: #222124;
  margin: 0px 8px 0px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px 5px 8px;
}

.stake-header-info-label-mobile {
  color: #929292;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.stake-header-info-data-mobile {
  color: #f2f2f2;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
}
.claimbtn-mobile {
  width: 15rem;
  height: 40px;
  border-radius: 0.625rem;
  background: #3e3c41;
  font-size: 15px;
  font-weight: 600;
  margin: 8px;
}

.inputContainer {
  position: relative;
}

.currencyLabel {
  position: absolute;
  top: 0;
  right: 10px; /* Adjust the right distance as needed */
  font-weight: bold; /* You can style the label as needed */
  margin-top: 18px;
  
}

.stakedglow {
  --bg-size: 400%;
  --color-one: hsl(15 90% 55%);
  --color-two: hsl(40 95% 55%);
  background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 10s infinite linear;
}

.totalstakeddata{
  margin-top: 22px;
}


@media (min-width: 140px) and (max-width: 425px) {
  .stakeInfo {
    flex-direction: column;
    margin: 25px;
  }
  .staketitle {
    margin-right: 58px;
  }
  .staketitle h1 {
    font-size: 20px;
  }
  .staketitle button {
    margin-top: 0px;
  }

  .historytitle {
    margin-top: -41px;
  }
  .stakeInfoCard {
    margin-top: 34px;
    margin-bottom: 31px;
  }
}

@media (min-width: 426px) and (max-width: 567px) {
  .stakeInfo {
    flex-direction: column;
    margin: 60px;
  }
  .stakeInfoCard {
    margin-top: 34px;
    margin-bottom: 31px;
  }
}
@media (min-width: 691px) and (max-width: 1232px) {
  .stake-card {
    height: 28rem;
    
  }
}

@media (max-width: 690px) {
  .stake-card {
    flex-direction: column;
    height: 57rem;
    width: 332px;
  }
  .stake-card-partition {
    width: 260px;
    height: 1px;
    margin-bottom: 3px;
    margin-top: 10px;

    transform: translate(-5px, 38px);
  }
  .stake-info-card {
    width: 100%;
    justify-content: space-between;
    height: 40%;
  }

  .stake-data-card {
    width: 100%;
    margin-top: 70px;
  }
}

@media (max-width: 746px) {
  .stake-header {
    display: block;
  }
  .stake-header-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .stake-heading {
    margin-bottom: 24px;
  }
}

@media (max-width: 638px) {
  .stake-header-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: none;
  }
  .stake-header-info-box-mobile {
    margin: 14px 8px 0px 8px;
    width: 338px;
  }

  .stake-header-info-container-mobile {
    display: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
  }
}

@media (max-width: 330px) {
  .stake-card {
    height: 57rem;

  }
  .stake-data-card {
    width: 107%;
    margin-left: -10px;
  }
  
}

@media (min-width: 1082px) and (max-width: 1163px) {
  
    .durationCard {
      width: 20rem;
    }
}

/* @media (max-width: 1081px) {
  .durationCard {
    width: 13rem;
  }
} */

@media (min-width: 768px) and (max-width: 807px) {
  .durationCard {
    width: 13rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .durationCard {
    width : 13rem;
  }
}

@media (min-width: 691px) and (max-width : 741px) {
  .durationCard {
    width : 11rem;
  }
}


@media (min-width: 808px) and (max-width: 1081px) 
{
   .durationCard {
    width: 15rem;
   }  
}

@media (max-width: 424px) {
  .durationCard {
    width: 13rem;
  }
}

@media (min-width:  639px) and (max-width: 915px){
  .stake-header-info-container{
    display: none;
  }
  .stake-header-info-container-mobile {
    display: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
   
  }
  .stake-header-info-box-mobile{
    margin: 0px 8px 11px 8px;
    width: 39rem;
    
  }
}