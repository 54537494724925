.PositionEditor .PositionSeller-modal .Modal-content .Modal-body {
  padding-right: 0.7rem;
  margin-right: 0.8rem;
}

.PositionSeller-token-selector .Modal .Modal-content {
  width: 38rem;
}

.PositionSeller-token-selector.warning .TokenSelector-box {
  color: rgb(247, 147, 26);
}

.PositionSeller-token-selector .TokenSelector-box {
  display: inline-flex;
}

.PositionSelector-selected-receive-token {
  white-space: normal;
  display: inline-flex;
  flex: 1;
}

.PositionSeller-token-selector .TokenSelector-box .TokenSelector-caret {
  margin-left: 0;
  margin-right: -2px;
  margin-bottom: -2px;
}

.PositionSeller-fee-item {
  margin-bottom: 4px;
}

.PositionSeller-fees-tooltip .Tooltip-popup {
  min-width: 30rem;
}

@media (max-width: 700px) {
  .PositionSeller-token-selector .Modal .Modal-content {
    width: 90vw;
  }

  .PositionSeller-fees-tooltip .Tooltip-popup {
    min-width: 30rem;
  }
}

@media (max-width: 350px) {
  .PositionSeller-receive-row.Exchange-info-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Exchange-info-row.PositionSeller-receive-row .TokenSelector-box {
    margin-top: 8px;
    text-align: left;
  }

  .PositionSelector-selected-receive-token.align-right {
    /* margin-top: 8px; */
    text-align: left;
  }

  .Exchange .PositionEditor .PositionSeller-receive-row.StakeV2-claim-fees-row .TokenSelector-token-row .Token-info img{
    width: 0;
  }
}

.PositionEditor .Exchange-swap-max,
.PositionSeller-modal .Exchange-swap-max {
  position: absolute;
  right: 1.25rem;
  top: 0;
  padding: 0.165rem 0.6rem;
  height: 2.25rem;
  color: #fff;
  border-radius: 1rem;
  z-index: 1;
  cursor: pointer;
  font-size: 1rem;
  position: unset;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  background: none;
  
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.PositionEditor .Exchange-swap-max.half,
.PositionEditor .Exchange-swap-max.max,
.PositionSeller-modal .Exchange-swap-max.half,
.PositionSeller-modal .Exchange-swap-max.max{
  background: #D9D9D9;
  color: #363636;
  font-weight: bold;
}

.PositionSeller-receive-row,
.PositionEditor-accept-profit-warning,
.PositionEditor-keep-leverage-settings,
.PositionEditor-allow-higher-slippage{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  flex-basis: 100%;
}

.PositionSeller-receive-row.top-line{
  /* margin-top: 1.5rem; */
  margin-bottom: 1.5rem;
}
.PositionEditor-info-box.close .PositionSeller-receive-row.top-line{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

/* .Exchange.page-layout .PositionEditor-info-box.close .Exchange-info-row:nth-child(2n+1){
  align-items: flex-end;
}
.Exchange.page-layout .PositionEditor-info-box.close .Exchange-info-row:nth-child(2n){
  align-items: flex-start;
} */
.Exchange.page-layout .PositionEditor-info-box .Exchange-info-row:nth-child(2n){
  align-items: flex-end;
}
.Exchange.page-layout .PositionEditor-info-box .Exchange-info-row:nth-child(2n+1){
  align-items: flex-start;
}



.Exchange .PositionEditor .divider{
  /* display: none; */
}
.Exchange .PositionEditor  .Modal-title-bar{
  flex-wrap: wrap;
}
.Exchange .PositionEditor .Modal-close-button{
  flex-basis: 10%;
}
.Exchange .PositionEditor .Modal-title{
  flex-basis: 90%;
  text-align: center;
  padding-left: 10%;
}

/* .Exchange .PositionEditor .Modal-body{
  margin-top: 0;
}

.Exchange .PositionEditor .Modal-content .PositionEditor-row{
  margin-top: 0;
} */

.TokenSelector.PositionSeller-token-selector{
  position: relative;
  display: block;
}

.PositionEditor .TokenSelector-tokens{
  right: 0;
}

@media (max-width: 400px) {
  .PositionsList .TokenSelector-box .tokenImage{
    display: none; 
  }
}

.PositionsList .Modal-body .align-right{
  text-align: left;
}


.PositionEditor .PositionSeller-receive-row.StakeV2-claim-fees-row .TokenSelector-token-row .Token-info .Token-symbol{
  margin-top: 0;
}
.PositionEditor .PositionSeller-receive-row.StakeV2-claim-fees-row .TokenSelector-token-row .Token-info img{
  width: 12px;
  margin-right: 0;
}
.PositionEditor .PositionSeller-receive-row.StakeV2-claim-fees-row .TokenSelector-tokens .TokenSelector-token-row .Token-info{
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-basis: 100%;
}
.PositionEditor .PositionSeller-receive-row.StakeV2-claim-fees-row .TokenSelector-tokens .TokenSelector-token-row{
  height: 25px;
  padding: 0;
  justify-content: space-between;

}
.PositionEditor .PositionSeller-receive-row.StakeV2-claim-fees-row .TokenSelector-tokens{
  grid-template-columns: repeat(5, 1fr);
  padding: 0;
}
.PositionEditor .PositionSeller-receive-row.StakeV2-claim-fees-row .TokenSelector-token-row .Token-text{
  font-size: 1rem;
}
.PositionEditor .PositionSeller-receive-row.StakeV2-claim-fees-row .TokenSelector-tokens .TokenSelector-token-row.active{
  border: 1px solid #e2e2e3;
}
.PositionEditor .PositionSeller-receive-row.StakeV2-claim-fees-row .TokenSelector.no-modal {
  flex-basis: 100%;
}