.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-header.large {
  height: 6.2rem;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  background: transparent 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #ffffff10;
  margin: auto;
  /* max-width: 126.4rem; */
}

.App-header.large::after {
  height: 1.3rem;
  width: 100%;
  content: " ";
  background: transparent linear-gradient(180deg, #00000030 0%, #00000000 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: -1.3rem;
  left: 0;
}

.App-header.large .App-header-links {
  display: flex;
}

.App-header.small {
  display: none;
}

.App-header-link-home {
  display: none;
}

.App-header-drawer {
  background: #17182c88 0% 0% no-repeat padding-box;
  box-shadow: 0.8rem 0.3rem 0.6rem #00000029;
  opacity: 1;
  backdrop-filter: blur(2.7rem);
  position: fixed;
  z-index: 801;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 30.4rem;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.App-header-drawer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.App-header-drawer .App-header-link-container .a,
.App-header-drawer .App-header-link-container a {
  font-size: var(--font-sm);
  line-height: 1.8rem;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #a0a3c4;
  padding: 1.5rem 1.6rem;
  text-decoration: none;
  display: block;
}

.App-header-drawer .App-header-link-container .a:hover,
.App-header-drawer .App-header-link-container .a:focus,
.App-header-drawer .App-header-link-container .a:active,
.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: #303fd024;
  color: white;
}

.App-header-links-header {
  height: 6.2rem;
  display: flex;
  align-items: center;
  z-index: 3;
}

.App-header-container-left {
  display: flex;
  align-items: center;
}

.App-header-top {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* z-index: 2; */
}

.App-header-menu-icon-block {
  margin-right: 1.2rem;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.App-header-menu-icon {
  color: white;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 0.9rem 1rem;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

.App-header a.App-header-link-main,
.App-header .App-header-link-main {
  font-size: 1.7rem;
  padding: 1.5rem;
  margin-left: -1.5rem;
  display: flex;
  margin-right: 1.2rem;
}

.App-header-link-main img {
  display: inline-block;
  height: 3rem;
  width: 9rem;
  vertical-align: middle;
}

.App-header-link-main img.small {
  display: none;
}

.App-header .a,
.App-header a {
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}

.App-header .a.active,
.App-header a.active {
  pointer-events: none;
  opacity: 1;
  color: #eee;
}

.App-header .a:hover,
.App-header a:hover {
  opacity: 1;
  color: #eee;
}

.App-header-container-left .a,
.App-header-container-left a {
  padding: 1.8rem 1.7rem;
  display: block;
  color: #a0a3c4;
  font-size: var(--font-base);
  line-height: 1.8rem;
  font-weight: normal;
}

.App-header-container-left .a.active,
.App-header-container-left a.active {
  color: white;
  /* border-bottom: 0.25rem solid #ffffff; */
}

.App-header-container-right {
  display: flex;
  align-items: center;
}

.App-header-container-right .feedback-btn {
  margin: 0 2rem;
}

.App-header-user {
  display: flex;
  align-items: center;
}

.App-header-user-settings {
  display: inline-flex;
  cursor: pointer;
  border: none;
  border-radius: 1.5rem;
  font-size: var(--font-base);
  background: none;
  color: white;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  align-items: center;
  border-radius: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.App-header-user-netowork {
  margin-right: 2.4rem;
}

.App-header-user-link {
  margin-right: 2.4rem;
}

.App-header-user-address {
  border: 1px solid #ffffff29;
  height: 3.6rem;
  border-radius: 0.4rem;
  display: inline-flex;
  align-items: center;
  color: white;
  position: relative;
}




.priceFeed {
  border-radius: 20px;
  border: 1px solid #414141;
  margin-right: 2.4rem;
  padding-left: 3px;
  padding-right: 3px;
}

.priceComponent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fxdxLogo {
  margin-left: 8px;
  margin-right: 5px;
  height: 25px;
  width: 25px;
}

.priceData {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
}

.buyBtn {
  margin-left: 5px;
  margin-right: 8px;
  font-size: 12px;
  border-radius: 5px;
  height: 25px;
  width: 40px;
  background: linear-gradient(91deg, #fa75f8 -7.61%, #9e6ee6 116.05%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.buy {
  background: var(--Posters, linear-gradient(96deg, #000000 -2.14%, #000000 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.load {
  margin-left: 4px;
  margin-right: 5px;
  border-radius: 20px;
  width: 102px;
  height: 25px;
  background: linear-gradient(150deg, #605f5f 15%, #3a3a41ca 50%, #605f5f 75%);
  background-size: 200% 100%;
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}








@media (max-width: 1033px) {
  .App-header-user-address {
    padding: 0 0px;
  }

  .App-header-user-link {
    margin-right: 1.6rem;
  }

  .App-header-user-netowork {
    margin-right: 1.6rem;
  }

  .App-header-link-home {
    display: block;
  }

  .App-header-user {
    padding-bottom: 0;
  }

  .App-header-container-right {
    display: flex;
    align-items: center;
  }

  .App-header-link-desktop {
    display: none;
  }

  .App-header {
    grid-template-columns: 1fr auto auto auto auto;
  }

  .App-header-link-main img {
    height: 2.1rem;
    width: 3rem;
  }

  .App-header.large {
    display: none;
  }

  .App-header.small {
    height: 6.2rem;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    background: transparent 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #ffffff10;
  }

  .App-header.small::after {
    height: 1.3rem;
    width: 100%;
    content: " ";
    background: transparent linear-gradient(180deg, #00000030 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    bottom: -1.3rem;
    left: 0;
  }

  a.App-header-user-account {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .App-header-link-main img.big {
    display: none;
  }

  .App-header-link-main img.small {
    display: inline-block;
  }
}

@media (max-width: 450px) {
  .App-header-user-link {
    margin-right: 0.6rem;
  }

  .App-header-user-netowork {
    margin-right: 0.6rem;
  }

  .App-header-menu-icon-block {
    margin-left: 0;
    margin-right: 0.4rem;
  }

  .App-header-menu-icon {
    margin: 0.4rem 0.5rem;
  }

  .App-header.small {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .App-header .App-header-link-main {
    padding: 0.155rem !important;
    margin-left: 0rem !important;
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .App-header-drawer {
    background: #0e0f1f;
  }
}

.default-btn {
  border: 1px solid #272a2f;
  background: #222124;
  box-sizing: border-box;
  border-radius: 28px;
}

.App-header-trade-link .trade-icon {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 20%;
  border: 1px black solid;
  margin-right: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-header-user .App-header-trade-link .default-btn {
  background: #e2e2e2;
  color: #121212;
  font-weight: 600;
}

.saga-header {
  position: relative;
  display: block;
  text-decoration: none;
  width: 12rem;
  height: 4rem;
  overflow: hidden;
  border-radius: 40px;
  margin-top: .5rem;
}

.saga-header span {
  position: relative;
  color: #fff;
  font-size: 1.5rem;
  z-index: 1;
  padding-left: 1rem;
  top:-.6rem ;
}

.saga-header .liquid {
  position: absolute;
  top: -80px;
  left: 0;
  width: 200px;
  height: 200px;
  background: #b7b7bd;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, .5);
  transition: .5s;
}

.saga-header .liquid::after,
.saga-header .liquid::before {
  content: '';
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
  background: #000;
}

.saga-header .liquid::before {

  border-radius: 45%;
  background: rgba(20, 20, 20, 1);
  animation: animate 5s linear infinite;
}

.saga-header .liquid::after {

  border-radius: 40%;
  background: rgba(20, 20, 20, .5);
  animation: animate 10s linear infinite;
}

.saga-header:hover .liquid{
  top: -120px;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

.hr-header{
  height: 2rem;
  margin-top: 1.7rem;
  margin-left: 2rem;
  border-color: #848484;

}

@media (max-width: 992px) {
  .saga-header-wrapper{
    margin-top: 1rem;
    padding-left: 1rem;
  }
  .hr-header{
    padding: 1;
    margin: 1;
    width: 85%;
    height: auto;
  }
}

.spl-text {
  position: relative;
  cursor: pointer;
  color: #848484;
  margin: auto -0.2rem;
  
}

.spl-text:hover {
  color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}
@media (max-width: 1110px) {
  .App-header-container-right .feedback-btn {
    display: none;
  }
}


@media (max-width: 425px) {
  .priceFeed {
    display: none;
  }
}