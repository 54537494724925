.table-container {
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: auto;
  font-weight: 600;
}
.leaderboard-contains {
  display: none;
}
.leaderboard-container {
  margin: 0 auto;
  padding: 0.5rem;
  background-color: #010101;
}
.leaderboard-heading {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.6125rem;
  color: #f0eded;
  padding-left: 0.5rem;
}
.leaderboard-subtitle {
  font-size: 1.3rem;
  color: #9a9797;
  margin-bottom: 1.25rem;
  padding-left: 0.5rem;
}
.routes a:hover {
  color: #007bff;
}
.table-cell-rank img {
  height: 2rem;
  width: 2rem;
}
.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.25rem;
  border-radius: 2rem;
}
.ulttable {
  border: 0.125rem solid rgb(103, 103, 103);
  border-radius: 1rem;
  padding-bottom: 0.5rem;
}
.table-header {
  background-color: #262626;
  font-weight: bold;
  color: #615d5d;
  width: 100%;
}
.link-main {
  text-decoration: none;
  color: #ffffff;
}
.user-row {
  display: grid;
  grid-template-columns: 9% 26% 21% 16% 14% 14%;
  padding: 0.125rem 0.375rem;
  text-align: left;
  font-size: 1.7rem;
  align-items: center;
  color: #ffffff;
  padding: 0.5rem 2rem;
}
.border-bottom {
  border-bottom: 1px solid #615d5d;
}
.searchicon input {
  color: white;
  background-color: wheat;
  margin-left: 0.125rem;
}
.textwrapper {
  color: white;
  background-color: white;
}
.table-cell-address a {
  font-size: 1.7rem !important;
}
.table-row {
  display: grid;
  grid-template-columns: 9% 26% 21% 16% 14% 14%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding: 1rem 0.8rem;
  background-color: #222124;
  color: #acacac;
  border-top-right-radius: 0.9rem;
  border-top-left-radius: 0.9rem;
  font-size: 1.3rem;
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 400;
}
.table-cell-wl span {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.table-header-pnl {
  cursor: pointer;
}
.table-cell {
  display: flex;
  flex: 1;
  padding: 0.6125rem;
  color: #bab5b5;
  font-size: 0.75rem;
  width: 100%;
  align-items: center;
}
.table-cell * {
  text-align: center;
}

.table-cell-address svg {
  margin-right: 0.5rem;
}

.table-cell-rank {
  margin-left: 0.6125rem;
}

.table-cell-address {
  color: #ffffff !important;
}

.table-cell-tradingvolume {
  padding: 0.6125rem;
}

.table-cell-wl {
  color: #ede8e8;
  font-weight: 600;
  padding: 0.6125rem;
}

.table-cell-biggest-win {
  color: #ede8e8;
}

.table-cell-pnl {
  color: #14eb38;
  font-weight: 600;
}

.pagination {
  justify-content: space-between;
  display: flex;
  margin-top: 1.25rem;
  align-items: center;
  margin-bottom: 1.25rem;
}

.pagination .page {
  color: #f0eded;
  font-size: 1.4rem;
}

.pagination .button {
  align-items: end;
  color: grey;
}

button {
  padding: 0.275rem 0.6125rem;
  margin: 0 0;
  cursor: pointer;
  background-color: inherit;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.hr-end {
  width: 100%;
}
.loading-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15vw;
}

.loading-text {
  font-size: 2rem;
}

.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #fff;
  left: -100px;
  box-sizing: border-box;
  animation: shadowRolling 2s linear infinite;
}
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.css-26l3qy-menu {
  background-color: #010101 !important;
  margin: 0;
}
@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
  }
  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
  }
  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
  }
  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      130px 0 white;
  }
  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0);
  }
}

@media (max-width: 768px) {
  .leaderboard-container {
    display: none;
  }
  .leaderboard-contains {
    display: block;
  }

  .leaderboard-heading {
    font-size: 2.7rem;
    font-weight: bold;
    margin-bottom: 0.6125rem;
    color: #f0eded;
    padding: 0;
  }

  .leaderboard-subtitle {
    font-size: 1rem;
    color: #9a9797;
    margin-bottom: 1.25rem;
    padding: 0;
  }
  .box-head {
    margin: auto 0.5rem;
  }
  .data {
    padding: auto 0.5rem;
  }
}
.sort-icon {
  font-size: 12px;
  margin-left: 4px;
}
@media (min-width: 1768px) {
  .leaderboard-contains {
    display: none;
  }
}
